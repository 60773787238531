import { NotificationSlice, batchNotifications } from 'store/Notification/slice';
import { StateCreator } from 'zustand';
import { responseHandler } from '../utils';
import { JobDetail, JobPreview, JobStore, jobStore } from './store';

export interface JobSlice {
  jobs: JobStore;
  getJobs: () => void;
}

export const createJobsSlice: StateCreator<JobSlice & NotificationSlice, [], [], JobSlice> = (
  set,
  get
) => ({
  jobs: jobStore,
  getJobs: async () => {
    const jobs = get().jobs;

    if (jobs.list.length) {
      return;
    }

    try {
      const results = await responseHandler<{ jobs: JobPreview[] }>(
        fetch('https://boards-api.greenhouse.io/v1/boards/merqube/jobs'),
        err => `Couldn't load jobs. (${err})`
      );

      if (results.content.jobs) {
        const promises = results.content.jobs.map((job: JobPreview) =>
          responseHandler<JobDetail>(
            fetch(`https://boards-api.greenhouse.io/v1/boards/merqube/jobs/${job.id}`),
            err => `Couldn't load jobs. (${err})`
          )
        );

        const responses = await Promise.all(promises);

        const details = responses.map(detail => detail.content);

        set({
          ...(results.errors.length ? batchNotifications(get)(results.errors) : {}),
          jobs: {
            list: results.content.jobs,
            details,
          },
        });
      }
    } catch (e) {
      return {
        // ...(errors.length ? NotificationActions.batch(state)(errors) : {}),
        // stats,
      };
    }
  },
});

export default createJobsSlice;
