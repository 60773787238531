import { StateCreator } from 'zustand';
import { IndexListItem } from '../Index/store';
import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { INDEX_FIELDS } from '../Index/store';
import { indicesStore, IndicesStore } from './store';
import { NotificationSlice, batchNotifications } from 'store/Notification/slice';
import queryString from 'query-string';
import { LandingPage } from 'store/IndexPages/store';
const API_URL = getAPIUrl();

type Stage = 'all' | 'dev' | 'prod';

export interface IndicesSlice {
  indices: IndicesStore;
  landingIndices: IndicesStore;
  getIndices: (queryParams?: string, force?: boolean) => void;
  getLandingIndices: (page?: LandingPage, force?: boolean) => Promise<IndexListItem[]>;
  getIndexFields: (
    fieldNames: string[],
    queryParams?: string,
    force?: boolean
  ) => Promise<IndexListItem[]>;
}
const createIndicesSlice: StateCreator<IndicesSlice & NotificationSlice, [], [], IndicesSlice> = (
  set,
  get
) => ({
  indices: indicesStore,
  landingIndices: indicesStore,

  getIndices: async (queryParams = '', force?: boolean) => {
    const indices = get().indices;
    const params = queryString.parse(queryParams);
    const stage = getStage(params.stage, indices.stage);

    if (
      indices.status === 'loading' ||
      (!!indices.items.length && !force && stage === indices.stage)
    ) {
      return { indices };
    }

    set({ indices: { ...indices, status: 'loading', stage } });
    params.fields = INDEX_FIELDS;
    queryParams = queryString.stringify(params, { arrayFormat: 'comma' });
    const headers = await getHeaders('GET');
    const { content, errors, code, status } = await responseHandler<{ results: IndexListItem[] }>(
      fetch(`${API_URL}/index?${queryParams}`, headers),
      err => `Couldn't load Indices list. (${err})`,
      ['intraday', 'documents']
    );

    const results = (content && content.results) || [];
    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      indices: {
        status,
        code,
        stage,
        items: results.filter(i => isVisible(i)) || [],
        fields: INDEX_FIELDS,
      },
    });
  },

  getIndexFields: async (fieldNames: string[], qs = '', force = false) => {
    const indices = get().indices;
    const params = queryString.parse(qs);
    params.fields = INDEX_FIELDS;
    const stage = getStage(params.stage, indices.stage);
    const queryParams = queryString.stringify(params, { arrayFormat: 'comma' });

    const filteredFields = fieldNames.filter(f => !indices.fields.includes(f));

    if (
      !force &&
      (indices.status === 'loading' ||
        (!filteredFields.length && !!indices.items.length && stage === indices.stage))
    ) {
      return indices.items;
    }

    set({ indices: { ...indices, status: 'loading' } });
    const headers = await getHeaders('GET');

    const mainPromise =
      !force && indices.items.length > 0
        ? Promise.resolve({ content: { results: indices.items }, errors: [] })
        : responseHandler<{ results: IndexListItem[] }>(
            fetch(`${API_URL}/index?${queryParams}`, headers),
            err => `Couldn't load Indices list. (${err})`,
            ['intraday', 'documents']
          );

    const extraFields = responseHandler<{ results: IndexListItem[] }>(
      fetch(`${API_URL}/index?fields=id,${filteredFields.join(',')}`, headers),
      err => `Couldn't load Indices list. (${err})`,
      ['intraday', 'documents']
    );

    const responses = await Promise.all([mainPromise, extraFields]);
    const mainItems = (responses[0].content && responses[0].content.results) || [];

    const errors = [...(responses[0].errors || []), ...(responses[1].errors || [])];

    const { content, code, status } = await responses[1];

    const results = ((content && content.results) || [])
      .filter(i => filteredFields.some(f => i[f as keyof IndexListItem] !== undefined))
      .reduce<FieldMap>((acc, i) => {
        return {
          ...acc,
          [i.id]: {
            ...i,
          },
        };
      }, {});

    const items = mainItems.map(i => {
      const newItem = { ...i, ...results[i.id] };
      return newItem;
    });

    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      indices: {
        status,
        code,
        stage,
        items,
        fields: [...indices.fields, ...filteredFields],
      },
    });
    return indices.items;
  },

  getLandingIndices: async (page?: LandingPage, force = false) => {
    const landingIndices = get().landingIndices;
    const indices = get().indices;

    if (!page) {
      return [];
    }

    if (!!indices.items.length && !force) {
      const items = filterIndicesByPage(indices.items, page);
      set({
        landingIndices: {
          status: 'success',
          code: 200,
          stage: 'prod',
          items,
          fields: indices.fields,
        },
      });
      return items;
    }

    set({ landingIndices: { ...landingIndices, status: 'loading' } });
    const headers = await getHeaders('GET');

    const params = queryString.parse('');
    params.fields = INDEX_FIELDS;
    const queryParams = queryString.stringify(params, { arrayFormat: 'comma' });

    const { content, errors, code, status } = await responseHandler<{ results: IndexListItem[] }>(
      fetch(`${API_URL}/index?${queryParams}`, headers),
      err => `Couldn't load Indices list. (${err})`,
      ['intraday', 'documents']
    );
    const results = (content && content.results) || [];
    const items = filterIndicesByPage(results, page);

    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      landingIndices: {
        status,
        code,
        stage: 'prod',
        items,
        fields: indices.fields,
      },
    });
    return items;
  },
});

export { createIndicesSlice };
export default createIndicesSlice;

const getStage = (params: string | string[] | null, defaultStage: Stage): Stage => {
  if (!params) return defaultStage;
  if (Array.isArray(params)) return params[0] as Stage;
  return params as Stage;
};

const isVisible = (index: IndexListItem) => {
  return !index.webpage || index.webpage.visible === undefined || index.webpage.visible;
};

const matchQuery = (index: IndexListItem, query: { value: string; exclude: string[] }) => {
  const qs = query.value.toUpperCase();
  return (
    !!qs &&
    (index.name.toUpperCase().includes(qs) ||
      (index.title.toUpperCase().includes(qs) && !query.exclude.includes(index.name.toUpperCase())))
  );
};

const filterIndicesByPage = (indices: IndexListItem[], page: LandingPage) => {
  return indices.filter(
    i =>
      isVisible(i) &&
      (page.queries.some(q => matchQuery(i, q)) || page.extraIndices.includes(i.name.toUpperCase()))
  );
};

interface FieldMap {
  [id: string]: Partial<IndexListItem>;
}
