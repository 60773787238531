import { Index, IStore, UUID } from '../types';
import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { IndexItem, DEFAULT_METRIC } from '../Index/store';
import { SelectorOption } from 'hooks/chartSelector';
import dayjs from 'dayjs';
import { StateCreator } from 'zustand';
import { batchNotifications, NotificationSlice } from 'store/Notification/slice';
import { RealTimeHistory, RealTimePoint, realTimeStore } from './store';

const API_URL = getAPIUrl();

interface Manifest {
  [key: string]: UUID;
}
const combineHistory = (
  state: { realTime: RealTimeHistory },
  results: RealTimePoint[],
  options: SelectorOption[],
  initialState?: RealTimeHistory,
  selectedIndex: Index = {} as Index
): RealTimeHistory => {
  const initial =
    selectedIndex.name && selectedIndex.id ? { [selectedIndex.name]: selectedIndex.id } : {};

  const manifest: Manifest = options.reduce(
    (mf, opt) => ({
      ...mf,
      [opt.label]: opt.value as UUID,
    }),
    initial
  );

  return results.reduce<RealTimeHistory>((partial, item) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { eff_ts, id, name, ...metrics } = item;

    const uuid = manifest[item.name];

    const data = Object.keys(metrics).reduce(
      (acc, metric) => {
        const current = (partial[uuid] || { [metric]: [] })[metric] || [];

        return {
          ...acc,
          [metric]: [
            ...current,
            {
              ...item,
              eff_ts: item.eff_ts + 'Z',
            },
          ],
        };
      },

      partial[uuid] || {}
    );

    return {
      ...partial,
      [uuid]: data,
    };
  }, initialState || state.realTime);
};

const getList = async (options: SelectorOption[]) => {
  const START_DATE = dayjs().utc().startOf('day').format();
  const names = options.map(o => o.label).join(',');
  const metrics = options.map(o => o.metric).join(',');

  const url = `${API_URL}/security/intraday_index?name=${names}&metrics=${metrics}&start_date=${START_DATE}&format=records`;
  const headers = await getHeaders('GET');
  return responseHandler<IntraDayPayload>(
    fetch(url, headers),
    err => `Couldn't load Index. (${err})`,
    true
  );
};

const updateList = (state: { realTime: RealTimeHistory }) => async (options: SelectorOption[]) => {
  const names = options.map(o => o.label).join(',');
  const metrics = options.map(o => o.metric).join(',');
  const data = state.realTime[options[0].value as UUID][options[0].metric];
  const START_DATE = dayjs(data[data.length - 1].eff_ts)
    .add(1, 'second')
    .utc()
    .format();

  const url = `${API_URL}/security/intraday_index?name=${names}&metrics=${metrics}&start_date=${START_DATE}&format=records`;
  const headers = await getHeaders('GET');
  return responseHandler<IntraDayPayload>(
    fetch(url, headers),
    err => `Couldn't load Index. (${err})`,
    true
  );
};

export interface RealTimeSlice {
  realTime: RealTimeHistory;
  refreshRealTime: (options: SelectorOption[]) => void;
  getRealTime: (index: IndexItem) => void;
}

export const createRealTimeSlice: StateCreator<
  RealTimeSlice & NotificationSlice,
  [],
  [],
  RealTimeSlice
> = (set, get) => ({
  realTime: realTimeStore,
  getRealTime: async (index: IndexItem) => {
    const realTime = get().realTime;
    // TODO: ask for the right timezone
    const START_DATE = dayjs().utc().startOf('day').format();
    const intradayMetric =
      index.intraday &&
      index.intraday.publish_config &&
      Object.keys(index.intraday.publish_config)[0];
    const METRIC = intradayMetric || index.plotMetric || DEFAULT_METRIC;
    const url = `${API_URL}/security/intraday_index?name=${index.name}&metrics=${METRIC}&start_date=${START_DATE}&format=records`;
    const headers = await getHeaders('GET');
    const { content, errors } = await responseHandler<IntraDayPayload>(
      fetch(url, headers),
      err => `Couldn't load Index. (${err})`,
      true
    );

    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      realTime: combineHistory({ realTime }, content.results, [], {}, index),
    });
  },
  refreshRealTime: async (options: SelectorOption[]) => {
    const realTime = get().realTime;

    const getOptions = options.filter(
      o => !realTime[o.value as UUID] || !realTime[o.value as UUID][o.metric]
    );
    const updateOptions = options.filter(
      o => !!realTime[o.value as UUID] && !!realTime[o.value as UUID][o.metric]
    );

    const getPromise: Promise<ApiResponse> = getOptions.length
      ? getList(getOptions)
      : Promise.resolve({ content: { results: [] }, errors: [] });
    const updatePromise: Promise<ApiResponse> = updateOptions.length
      ? updateList({ realTime })(updateOptions)
      : Promise.resolve({ content: { results: [] }, errors: [] });

    const [getResult, updateResult] = await Promise.all<ApiResponse>([getPromise, updatePromise]);
    const updateHistory = combineHistory(
      { realTime },
      updateResult.content.results || [],
      updateOptions
    );

    const history = combineHistory(
      { realTime: updateHistory } as IStore,
      getResult.content.results || [],
      getOptions
    );

    const errors = [...getResult.errors, ...updateResult.errors];

    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      realTime: {
        ...realTime,
        ...history,
      },
    });
  },
});

export default createRealTimeSlice;

interface IntraDayPayload {
  results: RealTimePoint[];
}

interface ApiResponse {
  content: IntraDayPayload;
  errors: any;
}
