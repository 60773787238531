export interface JobPreview {
  absoluteUrl: string;
  id: number;
  internalJobId: number;
  location: { name: string };
  title: string;
  updatedAt: string;
}

export interface JobDetail {
  absoluteUrl: string;
  id: number;
  internalJobId: number;
  location: { name: string };
  title: string;
  updatedAt: string;
  content: string;
}

export interface JobStore {
  list: JobPreview[];
  details: JobDetail[];
}

const jobStore: JobStore = { list: [], details: [] };

export { jobStore };
