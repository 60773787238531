import { getAPIUrl, getHeaders } from '../utils';
import { CustomHistory, customHistoryStore } from './store';
import dayjs from 'dayjs';
import { StateCreator } from 'zustand';
import {
  NotificationSlice,
  NotificationItemType,
  batchNotifications,
} from 'store/Notification/slice';

const API_URL = getAPIUrl();

interface QueryItem {
  url: string;
  displayName: string;
  arrayField: string;
  values: Array<{ key: string; path: string }>;
  keyField: string;
}

export interface CustomHistorySlice {
  customHistory: CustomHistory;
  getCustomHistory: (query: QueryItem[]) => void;
}

export const createCustomHistorySlice: StateCreator<
  CustomHistorySlice & NotificationSlice,
  [],
  [],
  CustomHistorySlice
> = (set, get) => ({
  customHistory: customHistoryStore,
  getCustomHistory: async (query: QueryItem[]) => {
    const customHistory = get().customHistory;
    const newQuery = query.filter(q => {
      const id = btoa(q.url);
      return !customHistory[id];
    });
    if (!newQuery.length) {
      return { customHistory };
    }
    const headers = await getHeaders('GET');
    const promises = newQuery.map(q => {
      return fetch(API_URL + q.url, headers);
    });
    const errors: NotificationItemType[] = [];
    let contents: any[] = [];
    try {
      const responses: any = await Promise.all(promises);
      contents = await Promise.all(
        responses
          .map(async (response: any, i: number) => {
            const id = btoa(newQuery[i].url);
            if (!response.ok) {
              if (response.status >= 400) {
                errors.push({
                  type: 'error',
                  message: `Couldn't load ${id} price history. (${response.statusText})`,
                });
                return { results: [] };
              }
            } else {
              const res = await response.json();
              return res;
            }
          })
          .filter((c: any) => !!c)
      );
    } catch (err: any) {
      console.log('err', err.message, err.name);
    }

    const history = newQuery.reduce<CustomHistory>((hs, item, i) => {
      return parseResult(hs, contents[i], item);
    }, customHistory);

    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      customHistory: {
        ...(customHistory || {}),
        ...history,
      },
    });
  },
});

export default createCustomHistorySlice;

//////// Helpers

const parseResult = (init: CustomHistory, content: any, item: QueryItem): CustomHistory => {
  if (!content.results || !content.results.length) {
    return init;
  }
  // if (!isSecAPIResponse(content)) {
  //   return parseDataApiResponse(init, content.results as any, item as string);
  // }

  return parseSecApiResponse(init, content, item);
};

const parseSecApiResponse = (
  init: CustomHistory,
  content: any,
  query: QueryItem
): CustomHistory => {
  const results: any[] = getValueFromNotation(content, query.arrayField) || [];
  return results.reduce<CustomHistory>((res, item) => {
    const xValue = getValueFromNotation(item, query.keyField) || 0;
    const customId = btoa(query.url);
    const fields = query.values.map(q => q.path);
    const ts = xValue && dayjs(xValue).valueOf();

    const data = fields.reduce<{
      [fieldName: string]: number[][];
    }>(
      (acc, fieldName) => {
        const current = acc[fieldName] || [];
        const newValue = item[fieldName] === null ? [] : [[dayjs(ts).valueOf(), item[fieldName]]];
        return {
          ...acc,
          [fieldName]: [...current, ...newValue],
        };
      },

      res[customId] || {}
    );
    return {
      ...res,
      [customId]: {
        ...(res[customId] || {}),
        ...data,
      },
    };
  }, init);
};

// const parseDataApiResponse = (
//   init: PriceHistoryMetric,
//   content: DataApiResponse[],
//   itemId: string
// ): PriceHistoryMetric => {
//   return {
//     ...init,
//     [itemId]: content.reduce<PriceHistoryItem>((acc, row) => {
//       return {
//         ...acc,
//         ...row.metrics.reduce<PriceHistoryItem>((tmp, metric) => {
//           const res = tmp[metric.id] || [];
//           const element = [...res, [dayjs(row.ts).valueOf(), metric.value]];
//           return {
//             ...tmp,
//             [metric.id]: element,
//           };
//         }, acc),
//       };
//     }, init[itemId] || {}),
//   };
//   /*
// [
//   {id: "1993-01-29T00:00:00"
//   metrics: [
//     {id: 'close', value: 43.9375}
//   ]
//   ts: "1993-01-29T00:00:00"}
//   ...
// ]
// */
//   // [secid][metricname][number][number]
// };

// interface DataApiResponse {
//   id: string;
//   metrics: Array<{ id: string; value: number }>;
//   ts: string;
// }

const getValueFromNotation = (obj: any, is: string | string[], value: any = undefined): any => {
  if (typeof is == 'string') {
    return getValueFromNotation(obj, is.split('.'), value);
  } else if (is.length === 1 && value !== undefined) {
    return (obj[is[0]] = value);
  } else if (is.length === 0) {
    return obj;
  } else {
    return getValueFromNotation(obj[is[0]], is.slice(1), value);
  }
};
