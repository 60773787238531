import humps from 'humps';
import { Index, SetterType, GetterType, MerqResponse } from '../types';
import { getAPIUrl, getHeaders, responseHandler, MethodType } from '../utils';
import * as Sentry from '@sentry/browser';
import { auth } from 'settings/firebase';
import {
  IndexStore,
  UUID,
  indexStore,
  IndexItem,
  IndexDocument,
  ClientMultiEBPortUpdate,
} from './store';
import { StateCreator } from 'zustand';
import {
  NotificationItemType,
  NotificationSlice,
  batchNotifications,
} from 'store/Notification/slice';
import { IndicesSlice } from 'store/Indices/slice';
const API_URL = getAPIUrl();

export type TemplateType =
  | 'multi_eb'
  | 'sstr'
  | 'decrement'
  | 'buffer_simple'
  | 'single_option'
  | 'option_strategies'
  | 'static_basket'
  | 'defined_outcome';

export interface IndexSlice {
  index: IndexStore;
  getIndexByUUID: (uuid: UUID, autoSelect?: boolean, force?: boolean) => Promise<Index | null>;
  getIndexByName: (name: string, force?: boolean) => Promise<Index | null>;
  getIndexList: (ids: UUID[]) => Promise<Index[]>;
  updateDocs: (
    uuid: UUID,
    documents: { [key: string]: IndexDocument | string },
    status: any
  ) => Promise<Index | null>;
  updateIndex: (uuid: UUID, data: Index, requestMethod?: MethodType) => Promise<Index | null>;
  getIndexTemplate: (data: any, type: TemplateType) => Promise<IndexTemplateResponse>;
  getPortfolioTemplate: (data: ClientMultiEBPortUpdate) => Promise<IndexTemplateResponse>;
  validateIndex: (template: IndexTemplate) => Promise<IndexCreateResponse>;
  createIndex: (template: IndexTemplate, indexType?: string) => Promise<IndexCreateResponse>;
  updatePortfolio: (uuid: UUID, data: TargetPortfolio[]) => Promise<MerqResponse<{ ok: true }>[]>;
  deleteIndex: (uuid: string) => Promise<IndexDeleteResponse>;
}

export const getByName =
  (get: GetterType<IndexSlice & NotificationSlice>) => async (name: string, force?: boolean) => {
    const { index } = get();
    if (!force && !!index.selected && index.selected.name === name) {
      return { index };
    }
    const headers = await getHeaders('GET');
    const { content, errors } = await responseHandler<IndexPayload>(
      fetch(`${API_URL}/index?name=${name}`, headers),
      err => `Couldn't load Index. (${err})`,
      ['intraday', 'documents']
    );

    const item = content && content.results && content.results.length ? content.results[0] : null;
    return {
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      index: {
        ...index,
        ...(item ? { [item.id]: item } : {}),
        selected: item,
        code: item ? 200 : 404,
      },
    };
  };
const getByUUID =
  (
    set: SetterType<IndexSlice & NotificationSlice>,
    get: GetterType<IndexSlice & NotificationSlice>
  ) =>
  async (uuid: UUID, autoSelect = true, force?: boolean) => {
    const { index } = get();

    if (!force && !!index.selected && index.selected.id === uuid) {
      return index.selected;
    }
    const headers = await getHeaders('GET');
    const { content, errors } = await responseHandler<{ results: Index[] }>(
      fetch(`${API_URL}/index/${uuid}`, headers),
      err => `Couldn't load Index. (${err})`,
      ['intraday', 'documents']
    );

    const item = content && content.results && content.results.length ? content.results[0] : null;

    if (item) {
      set({
        ...(errors.length ? batchNotifications(get)(errors) : {}),
        index: {
          ...index,
          ...(typeof item === 'object' ? { [item.id]: item } : {}),
          ...(autoSelect ? { selected: item } : {}),
          code: item ? 200 : 404,
        },
      });
    }
    return item;
  };

const getList =
  (
    set: SetterType<IndexSlice & NotificationSlice>,
    get: GetterType<IndexSlice & NotificationSlice>
  ) =>
  async (ids: UUID[]) => {
    const { index } = get();

    const query = ids.filter(id => !index[id]);

    if (!query.length) {
      return ids.map(id => index[id]);
    }
    const headers = await getHeaders('GET');
    const promises = query.map(id =>
      responseHandler<{ results: Index[] }>(
        fetch(`${API_URL}/index/${id}`, headers),
        err => `Couldn't load Index. (${err})`,
        ['intraday', 'documents']
      )
    );

    const responses = await Promise.all(promises);

    const items = responses
      .map(response => {
        const item =
          response.content && response.content.results && response.content.results.length
            ? response.content.results[0]
            : null;

        return item;
      })
      .filter(Boolean) as Index[];

    if (items.length) {
      set({
        index: {
          ...index,
          ...items.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
        },
      });
    }

    return items;
  };

const updateDocs =
  (
    set: SetterType<IndexSlice & NotificationSlice>,
    get: GetterType<IndexSlice & NotificationSlice>
  ) =>
  async (uuid: UUID, rawDocuments: { [key: string]: IndexDocument | string }, status: any) => {
    // update the format if required
    const documents = Object.entries(rawDocuments).reduce<{ [key: string]: IndexDocument }>(
      (acc, [key, doc]) => {
        const document = typeof doc === 'string' ? { filename: doc, shared: true } : doc;

        return {
          ...acc,
          [key]: document,
        };
      },
      {}
    );
    const headers = await getHeaders('PATCH');
    const { errors } = await responseHandler<IndexPayload>(
      fetch(`${API_URL}/index/${uuid}`, {
        ...headers,
        body: JSON.stringify({ documents, status: humps.decamelizeKeys(status) }),
      }),
      err => `Couldn't save Index documents. (${err})`
    );

    if (errors.length) {
      set({
        ...batchNotifications(get)(errors),
      });
      return null;
    }
    return getByUUID(set, get)(uuid, true);
  };

const updateIndex =
  (
    set: SetterType<IndexSlice & NotificationSlice>,
    get: GetterType<IndexSlice & NotificationSlice>
  ) =>
  async (uuid: UUID, data: Partial<Index>, requestMethod: MethodType = 'PATCH') => {
    const { index } = get();
    const status = index[uuid] ? index[uuid].status : {};
    const { id, ...patchData } = data;
    const method = requestMethod || (id ? 'PATCH' : 'POST');
    const headers = await getHeaders(method);
    const { errors } = await responseHandler<Index>(
      fetch(`${API_URL}/index${uuid ? `/${uuid}` : ''}`, {
        ...headers,
        body: JSON.stringify(
          humps.decamelizeKeys(method === 'PATCH' ? { status, ...patchData } : data)
        ),
      }),
      err => `Couldn't load Index. (${err})`
    );

    if (errors.length) {
      set({
        ...batchNotifications(get)(errors),
      });
      return null;
    }

    return getByUUID(set, get)(uuid, true);
  };

const getIndexTemplate = async (data: any, type: TemplateType) => {
  const body = humps.decamelizeKeys(data);
  const customErrorHandler = (err: any) => {
    if (Array.isArray(err)) {
      return err as any as IndexTemplateError[];
    } else if (typeof err === 'string') {
      return `Couldn't generate Index template. (${err})`;
    } else {
      return [err] as any as IndexTemplateError[];
    }
  };
  const headers = await getHeaders('POST');
  const response = await responseHandler<TemplateResponse>(
    fetch(`${API_URL}/helper/index-template/${type}`, {
      ...headers,
      body: JSON.stringify(body),
    }),
    customErrorHandler as any
  );

  if (response.errors?.length) {
    const user = auth?.currentUser?.email;
    sentryLog(`${user} got an error creating new ${type} Index template`, {
      errors: response.errors,
      xRequestId: headers.headers['X-Request-ID'],
      body,
    });
  }

  return response;
};

const getPortfolioTemplate = async (body: ClientMultiEBPortUpdate) => {
  const headers = await getHeaders('POST');

  const response = await responseHandler<TemplateResponse>(
    fetch(`${API_URL}/helper/index-template/multi_eb_portfolios`, {
      ...headers,
      body: JSON.stringify(humps.decamelizeKeys(body)),
    }),
    err => `Couldn't generate Index portfolio template. (${err})`
  );

  return response;
};

const validateIndex = async (template: IndexTemplate) => {
  const headers = await getHeaders('POST');
  const body = humps.decamelizeKeys(template);
  const response = await responseHandler<IndexItem>(
    fetch(`${API_URL}/helper/validate`, {
      ...headers,
      body: JSON.stringify(body),
    }),
    err => `Couldn't validate Index. (${err})`
  );

  if (response.errors?.length) {
    const user = auth?.currentUser?.email;

    sentryLog(`${user} got an error validating new Index template`, {
      errors: response.errors,
      xRequestId: headers.headers['X-Request-ID'],
      body,
    });
  }
  return response;
};

const createIndex = async (template: IndexTemplate, indexType?: string) => {
  const headers = await getHeaders('POST');
  const body = humps.decamelizeKeys(template);
  const response = await responseHandler<IndexItem>(
    fetch(`${API_URL}/index`, {
      ...headers,
      body: JSON.stringify(body),
    }),
    err => {
      return `Couldn't create Index. (${err})`;
    }
  );

  if (response.errors?.length) {
    const user = auth?.currentUser?.email;

    sentryLog(`${user} got an error creating new ${indexType} Index`, {
      errors: response.errors,
      xRequestId: headers.headers['X-Request-ID'],
      indexType,
      body,
    });
  }

  return response;
};

const updatePortfolio = async (uuid: UUID, data: TargetPortfolio[]) => {
  const headers = await getHeaders('PUT');
  const promises = data.map(row =>
    responseHandler<{ ok: true; errors?: Array<{ message: string }> }>(
      fetch(`${API_URL}/index/${uuid}/target_portfolio`, {
        ...headers,
        body: JSON.stringify(humps.decamelizeKeys(row)),
      }),
      err => {
        return `Couldn't update Index Portfolio. (${err})`;
      }
    )
  );

  return Promise.all(promises);
};

const deleteIndex =
  (
    set: SetterType<IndexSlice & IndicesSlice & NotificationSlice>,
    get: GetterType<IndexSlice & IndicesSlice & NotificationSlice>
  ) =>
  async (uuid: string) => {
    if (uuid) {
      const headers = await getHeaders('DELETE');
      const response = await responseHandler<IndexItem>(
        fetch(`${API_URL}/index/${uuid}`, {
          ...headers,
        }),
        err => {
          return `Couldn't delete Index. (${err})`;
        }
      );
      if (!response.errors.length) {
        const { index, indices } = get();
        const newIndices = indices.items.filter(i => i.id !== uuid);
        if (index[uuid as any]) {
          delete index[uuid as any];
        }
        set({
          index: {
            ...index,
            selected: null,
          },
          indices: {
            ...indices,
            items: newIndices,
          },
        });
      }
      return response;
    }
    return {
      content: null,
      errors: [{ message: 'NO UUID provided.', type: 'error' } as NotificationItemType],
    };
  };

export const createIndexSlice: StateCreator<
  IndexSlice & IndicesSlice & NotificationSlice,
  [],
  [],
  IndexSlice
> = (set, get) => ({
  index: indexStore,
  getIndexByName: async (...a) => {
    const result = await getByName(get)(...a);
    set(result);
    return result.index.selected;
  },
  getIndexByUUID: getByUUID(set, get),
  getIndexList: getList(set, get),
  updateDocs: updateDocs(set, get),
  updateIndex: updateIndex(set, get),
  getIndexTemplate,
  getPortfolioTemplate,
  validateIndex,
  createIndex,
  updatePortfolio,
  deleteIndex: deleteIndex(set, get),
});

export default createIndexSlice;

interface IndexPayload {
  results: Index[];
}

interface IndexTemplate extends Omit<IndexItem, 'id' | 'uuid' | 'status'> {}

interface TemplateResponse {
  postTemplate: IndexTemplate;
  bbgIdentTemplate?: any;
  targetPorts?: TargetPortfolio[];
}
interface IndexTemplateResponse {
  content: TemplateResponse | null;
  errors: IndexTemplateError[] | NotificationItemType[];
}

interface IndexCreateResponse {
  content: IndexItem | null;
  errors: NotificationItemType[];
}

export interface IndexDeleteResponse {
  content: IndexItem | null;
  errors: NotificationItemType[];
}

interface Position {
  amount: number;
  asset_type: string;
  identifier: string;
  identifier_type: string;
}

export interface TargetPortfolio {
  positions: Position[];
  timestamp: string;
  unit_of_measure: string;
}

export interface Constitutent {
  date: string;
  selection_date?: string | null;
  identifier: string;
  asset_type: string;
  identifier_type: string;
  amount: number;
  position_id?: string;

  real_time_trade_types?: Array<'ELIGIBLE' | 'INELIGIBLE'>;
  use_primary_listing?: boolean;
}

export interface IndexTemplateError {
  type: 'error';
  code: number;
  message: Array<{
    input: string | { [key: string]: string };
    loc: string;
    msg: string;
    type: pyErrorType;
  }>;
}

type pyErrorType =
  | 'arguments_type'
  | 'assertion_error'
  | 'bool_parsing'
  | 'bool_type'
  | 'bytes_too_long'
  | 'bytes_too_short'
  | 'bytes_type'
  | 'callable_type'
  | 'dataclass_exact_type'
  | 'dataclass_type'
  | 'date_from_datetime_inexact'
  | 'date_from_datetime_parsing'
  | 'date_future'
  | 'date_parsing'
  | 'date_past'
  | 'date_type'
  | 'datetime_future'
  | 'datetime_object_invalid'
  | 'datetime_parsing'
  | 'datetime_past'
  | 'datetime_type'
  | 'decimal_max_digits'
  | 'decimal_max_places'
  | 'decimal_parsing'
  | 'decimal_type'
  | 'decimal_whole_digits'
  | 'dict_type'
  | 'enum'
  | 'extra_forbidden'
  | 'finite_number'
  | 'float_parsing'
  | 'float_type'
  | 'frozen_field'
  | 'frozen_instance'
  | 'frozen_set_type'
  | 'get_attribute_error'
  | 'greater_than'
  | 'greater_than_equal'
  | 'int_from_float'
  | 'int_parsing'
  | 'int_parsing_size'
  | 'int_type'
  | 'invalid_key'
  | 'is_instance_of'
  | 'is_subclass_of'
  | 'iterable_type'
  | 'iteration_error'
  | 'json_invalid'
  | 'json_type'
  | 'less_than'
  | 'less_than_equal'
  | 'list_type'
  | 'literal_error'
  | 'mapping_type'
  | 'missing'
  | 'missing_argument'
  | 'missing_keyword_only_argument'
  | 'missing_positional_only_argument'
  | 'model_attributes_type'
  | 'model_type'
  | 'multiple_argument_values'
  | 'multiple_of'
  | 'no_such_attribute'
  | 'none_required'
  | 'recursion_loop'
  | 'set_type'
  | 'string_pattern_mismatch'
  | 'string_sub_type'
  | 'string_too_long'
  | 'string_too_short'
  | 'string_type'
  | 'string_unicode'
  | 'time_delta_parsing'
  | 'time_delta_type'
  | 'time_parsing'
  | 'time_type'
  | 'timezone_aware'
  | 'timezone_naive'
  | 'too_long'
  | 'too_short'
  | 'tuple_type'
  | 'unexpected_keyword_argument'
  | 'unexpected_positional_argument'
  | 'union_tag_invalid'
  | 'union_tag_not_found'
  | 'url_parsing'
  | 'url_scheme'
  | 'url_syntax_violation'
  | 'url_too_long'
  | 'url_type'
  | 'uuid_parsing'
  | 'uuid_type'
  | 'uuid_version';

const sentryLog = (message: string, details: any) => {
  // Create a custom error object
  const error = new Error(message);

  // Add additional details
  Sentry.captureException(error, {
    extra: {
      details,
    },
  });
};
