import {
  Analytics,
  initializeAnalytics,
  logEvent,
  setAnalyticsCollectionEnabled,
} from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const {
  VITE_API_KEY,
  VITE_AUTH_DOMAIN,
  VITE_DATABASE_URL,
  VITE_PROJECT_ID,
  VITE_STORAGE_BUCKET,
  VITE_MESSAGING_SENDER_ID,
  VITE_APP_ID,
  VITE_MEASUREMENT_ID,
  VITE_DATABASE_NAME,
  VITE_DOMAIN_URL,
} = import.meta.env;

export const firebaseConfig = {
  apiKey: VITE_API_KEY,
  authDomain: VITE_AUTH_DOMAIN,
  databaseURL: VITE_DATABASE_URL,
  projectId: VITE_PROJECT_ID,
  storageBucket: VITE_STORAGE_BUCKET,
  messagingSenderId: VITE_MESSAGING_SENDER_ID,
  appId: VITE_APP_ID,
  measurementId: VITE_MEASUREMENT_ID,
  dataBaseName: VITE_DATABASE_NAME,
};

const isProd = import.meta.env.NODE_ENV === 'production';

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp, '(default)');
export const fireStorage = getStorage(firebaseApp);

export const providerConfig = {
  auth,
  firestore,
  fireStorage,
  domainURL: VITE_DOMAIN_URL,
};

let analyticsInstance: Analytics | undefined = undefined;

export const refreshToken = async () => {
  const user = auth.currentUser;
  if (user) {
    const tokenResult = await user.getIdTokenResult();
    const tokenExpiryTime = new Date(tokenResult.expirationTime).getTime();
    localStorage.setItem('auth', tokenResult.token || '');
    localStorage.setItem('authExpiry', tokenExpiryTime.toString());
    return tokenResult.token;
  }
};

if (isProd) {
  window.addEventListener('CookieScriptLoaded', function () {
    const state = (window as any).CookieScript.instance.currentState();
    analyticsInstance = initializeAnalytics(firebaseApp);
    if (state.categories.includes('performance')) {
      setAnalyticsCollectionEnabled(analyticsInstance, true);
    } else {
      setAnalyticsCollectionEnabled(analyticsInstance, false);
    }
  });

  window.addEventListener('CookieScriptAcceptAll', function () {
    if (!analyticsInstance) {
      analyticsInstance = initializeAnalytics(firebaseApp);
    }

    setAnalyticsCollectionEnabled(analyticsInstance, true);
    logEvent(analyticsInstance, 'page_view');
  });

  window.addEventListener('CookieScriptAccept', function (e: any) {
    if (!analyticsInstance) {
      analyticsInstance = initializeAnalytics(firebaseApp);
    }

    if (e.detail.categories.includes('performance')) {
      setAnalyticsCollectionEnabled(analyticsInstance, true);
      logEvent(analyticsInstance, 'page_view');
    } else {
      setAnalyticsCollectionEnabled(analyticsInstance, false);
    }
  });
} else {
  analyticsInstance = initializeAnalytics(firebaseApp);
  setAnalyticsCollectionEnabled(analyticsInstance, false);
}

// export const analytics =  getAnalytics(firebaseApp);

export const analytics = {
  logEvent: (name: string, data?: any) => {
    if (isProd && analyticsInstance) {
      logEvent(analyticsInstance, name, data);
    } else if (!isProd) {
      console.info('Analytics Event:', name, data);
    }
  },
};
