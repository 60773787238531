import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { SecurityExchangeItem } from './store';
import { StateCreator } from 'zustand';
import { NotificationSlice, batchNotifications } from 'store/Notification/slice';
const API_URL = getAPIUrl();

export interface SecurityExchangeSlice {
  securityExchange: SecurityExchangeItem[];
  getSecurityExchange: () => void;
}

export const createSecurityExchangeSlice: StateCreator<
  SecurityExchangeSlice & NotificationSlice,
  [],
  [],
  SecurityExchangeSlice
> = (set, get) => ({
  securityExchange: [],
  getSecurityExchange: async () => {
    const securityExchange = get().securityExchange;
    if (securityExchange.length) {
      return;
    }

    try {
      const headers = await getHeaders('GET');
      const response = await responseHandler<{ results: SecurityExchangeItem[] }>(
        fetch(`${API_URL}/security/exchange`, headers),
        err => `Couldn't load SecurityExchange. (${err})`
      );

      const { content, errors } = response;
      set({
        ...(errors.length ? batchNotifications(get)(errors) : {}),
        securityExchange: content.results,
      });
    } catch (e: any) {
      set({
        ...batchNotifications(get)(e.toString()),
      });
    }
  },
});

export default createSecurityExchangeSlice;
