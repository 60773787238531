import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { NotificationSlice, batchNotifications } from 'store/Notification/slice';
import { StateCreator } from 'zustand';
import { Index } from '../types';
import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { PortfolioStore, portfolioStore } from './store';

dayjs.extend(utc);

export interface IBenchmark {
  id: string;
  name: string;
  metric: string;
}

const API_URL = getAPIUrl();

export interface PortfolioSlice {
  portfolio: PortfolioStore;
  getPortfolioDates: (index: Index) => void;
  getPortfolioData: (index: Index, date: string) => void;
}

export const createPortfolioSlice: StateCreator<
  PortfolioSlice & NotificationSlice,
  [],
  [],
  PortfolioSlice
> = (set, get) => ({
  portfolio: portfolioStore,
  getPortfolioDates: async (index: Index) => {
    const url = `${API_URL}/index/${index.id}/portfolio?dates_only=True`;
    const portfolio = get().portfolio;

    if (portfolio[index.id]) {
      return { portfolio };
    }
    const headers = await getHeaders('GET');
    const { content, errors } = await responseHandler<any>(
      fetch(url, headers),
      err => `Couldn't load portfolio dates for ${index.id} (${err})`
    );

    const dates = ((content || {}).results || []).map((r: any) =>
      dayjs(r.id).utc().format('YYYY-MM-DD')
    );
    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      portfolio: {
        ...portfolio,
        [index.id]: {
          dates,
        },
      },
    });
  },

  getPortfolioData: async (index: Index, date: string) => {
    const url = `${API_URL}/index/${index.id}/portfolio?time=${date}`;

    const portfolio = get().portfolio;
    const headers = await getHeaders('GET');
    const response = await fetch(url, headers);

    const data = JSON.parse((await response.text()).replace(/NaN/g, 'null'));

    // const { content, errors } = await responseHandler(
    //   fetch(url, headers('GET')),
    //   err => `Couldn't load Caps for ${indexName} (${err})`
    // );
    const id = dayjs(data.results[0].id).utc().format('YYYY-MM-DD');
    set({
      // ...NotificationActions.batch(state)(errors),
      portfolio: {
        ...portfolio,
        [index.id]: {
          ...(portfolio[index.id] || {}),
          [id]: data.results[0].portfolio,
          // ...data.results,
        },
      },
    });
  },
});

export default createPortfolioSlice;
