import { StateCreator } from 'zustand';
import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { indexOptionStore, IndexOptionStore, IndexQuery } from './store';
import { NotificationSlice, batchNotifications } from 'store/Notification/slice';
import queryString from 'query-string';
const API_URL = getAPIUrl();

export interface IndexOptionSlice {
  indexOptions: IndexOptionStore;
  getIndexOptions: (i: number, queryParams?: string, force?: boolean) => Promise<IndexQuery>;
  addIndexOption: () => IndexQuery;
  removeIndexOption: (i: number) => void;
}
const createIndexOptionSlice: StateCreator<
  IndexOptionSlice & NotificationSlice,
  [],
  [],
  IndexOptionSlice
> = (set, get) => ({
  indexOptions: indexOptionStore,

  getIndexOptions: async (i, queryParams = '') => {
    const { cached, ...rest } = get().indexOptions;
    console.log(cached, queryParams);
    const params = queryString.parse(`filter=${queryParams}`);
    params.stage = params.stage || 'prod';

    if (cached.length > i && cached[i].query === queryParams) {
      return cached[i];
    }

    set({ indexOptions: { cached, ...rest, status: 'loading' } });

    params.fields = ['title', 'name'];
    queryParams = queryString.stringify(params, { arrayFormat: 'comma' });
    const headers = await getHeaders('GET');
    const { content, errors, code, status } = await responseHandler<{ results: IndexResponse[] }>(
      fetch(`${API_URL}/index?${queryParams}`, headers),
      err => `Couldn't load Indices list. (${err})`
    );

    const results = ((content && content.results) || []).map(r => ({
      label: r.name,
      value: r.name,
      title: r.title,
    }));

    const newCached =
      cached.length === i
        ? [...cached, { query: queryParams, results }]
        : cached.map((c, j) => (j === i ? { query: queryParams, results } : c));

    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      indexOptions: {
        status,
        code,
        stage: params.stage as 'all' | 'dev' | 'prod',
        cached: newCached,
      },
    });

    return newCached[i];
  },

  addIndexOption: () => {
    const { cached, ...rest } = get().indexOptions;
    set({ indexOptions: { cached: [...cached, { query: '', results: [] }], ...rest } });
    return cached[cached.length - 1];
  },

  removeIndexOption: i => {
    const { cached, ...rest } = get().indexOptions;
    const newCached = cached.filter((_, j) => j !== i);
    set({ indexOptions: { cached: newCached, ...rest } });
  },
});

export { createIndexOptionSlice };
export default createIndexOptionSlice;

interface IndexResponse {
  title: string;
  name: string;
}
