import { StateCreator } from 'zustand';
import {
  getDoc,
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  collection,
  where,
  documentId,
  getDocs,
  query,
} from 'firebase/firestore';
import { firestore } from '../../settings/firebase';
import { IndexTemplate } from 'pages/IndexWizard/common/utils';

export interface IndexParamsSlice {
  value: {
    [indexName: string]: IndexTemplate;
  };
  get: (indexName: string) => Promise<IndexTemplate | undefined>;
  getList: (indexName: string[]) => Promise<
    | {
        [indexName: string]: IndexTemplate;
      }
    | undefined
  >;
  save: (indexName: string, template: IndexTemplate) => Promise<{ ok?: boolean; error?: string }>;
  delete: (indexName: string) => Promise<{ ok?: boolean; error?: string }>;
}

export const createIndexParamsSlice: StateCreator<
  IndexParamsSlice,
  [],
  [],
  IndexParamsSlice
> = setState => ({
  value: {},
  get: async name => {
    const requestRef = doc(firestore, 'indexParams', name);
    const requestDoc = (await getDoc(requestRef)).data();

    if (requestDoc?.value) {
      return JSON.parse(requestDoc.value);
    }
  },

  getList: async names => {
    const uniqueNames = Array.from(new Set(names));
    try {
      const promises = splitArray(uniqueNames, 30).map(async chunk => {
        const querySnapshot = await getDocs(
          query(collection(firestore, 'indexParams'), where(documentId(), 'in', chunk))
        );
        const chunkResults: {
          [indexName: string]: IndexTemplate;
        } = {};
        querySnapshot.forEach(doc => {
          const data = JSON.parse(doc.data().value) as IndexTemplate;
          chunkResults[data.name] = data;
        });
        return chunkResults;
      });

      const responses = await Promise.all(promises);
      const results = responses.reduce((acc, chunk) => {
        return { ...acc, ...chunk };
      }, {});

      setState({ value: results });
      return results;
    } catch (error: any) {
      console.error('Error getting index params:', error);
    }
  },

  save: async (name, template) => {
    try {
      const requestRef = doc(firestore, 'indexParams', name);
      const requestDoc = await getDoc(requestRef);

      if (requestDoc.exists()) {
        // If it exists, update the existing document
        await updateDoc(requestRef, { value: JSON.stringify(template) });
      } else {
        // If it doesn't exist, create a new document
        await setDoc(requestRef, {
          value: JSON.stringify(template),
        });
      }
    } catch (error: any) {
      return { error: 'There was an error saving index params: ' + error.code };
    }
    return { ok: true };
  },
  delete: async name => {
    try {
      const requestRef = doc(firestore, 'indexParams', name);
      await deleteDoc(requestRef);
    } catch (error: any) {
      return { error: 'There was an error deleting index params: ' + error.code };
    }
    return { ok: true };
  },
});

export default createIndexParamsSlice;

const splitArray = <T>(array: T[], chunkSize: number): T[][] => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};
