import { UUID } from 'store/types';

export interface DataCollectionItem {
  auto_persist?: boolean;
  compression?: 'none' | 'gzip';
  date_col?: string;
  format?: 'csv' | 'json';
  index_dcc_subtype: string;
  layout_type?: 'SINGLE_FILE' | 'YEARLY_DIR_DAILY_FILE';
  metric_to_use?: string[];
  location: string;
  name: string;
  seed_file_path?: string;
  start_date?: string;
}

export interface DataCollectionData {
  [name: string]: {
    [metric: string]: number[][];
  };
}

export interface DataCollectionStore {
  [UUID: UUID]: {
    collections: {
      [collectionName: string]: DataCollectionItem;
    };
    data: {
      [collectionName: string]: any[];
    };
  };
}

const dataCollectionStore: DataCollectionStore = {};

export { dataCollectionStore };
