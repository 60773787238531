import { useFirebase } from '@merq/web-ui/dist/providers/firebaseProvider';
import { useTheme } from '@merq/web-ui/dist/providers/themeProvider';
import { useAuth } from '@merq/web-ui/dist/providers/userProvider';
import CloseIcon from '@mui/icons-material/Close';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import DownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery, useTheme as useMuiTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import getSessionId from 'hooks/getSessionId';
import useMenu, { RootItem } from 'hooks/useMenu';
import useSalesforceLogin from 'hooks/useSalesforceLogin';
import Logo from 'images/MerQube_logos/Logo-MerQube-Horizontal.svg';
import LogoDark from 'images/MerQube_logos/logo-horizontal-dark.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import useStore from 'store';
import MobileMenu from './MobileMenu';
import ProfileBox from './ProfileBox';
import * as Sentry from '@sentry/react';
import './styles.scss';

const hardCodedFrenchIndices = ['FR001400MVY0', 'FR001400Q2T1'];

const TopBar = () => {
  const location = useLocation();
  /* Add top bar -- need to make it a separate component to handle login */
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user } = useAuth();
  const { logout } = useFirebase();
  const { toggleTheme, mode } = useTheme();
  const theme = useMuiTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const indexPages = useStore(state => state.indexPages);
  const { onLogin } = useSalesforceLogin();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const pathNameArray = location.pathname.split('/');
    const lastPath = pathNameArray[pathNameArray.length - 1];
    if (lastPath !== 'fr') {
      if (hardCodedFrenchIndices.includes(lastPath)) {
        i18n.changeLanguage('fr');
      } else {
        i18n.changeLanguage('en');
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    indexPages.get(user?.email || '');
  }, [user?.email]);

  const isActive = (item: RootItem) => {
    return (item.items || []).some(item => item.path === location.pathname);
  };

  const verifiedUser = !!user && user.emailVerified;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onClose = () => {
    if (mobileOpen) {
      setMobileOpen(false);
    }
  };

  const onLogout = async () => {
    await logout();
    await localStorage.setItem('auth', '');
    await localStorage.setItem('session-id', getSessionId());
    Sentry.getCurrentScope().setUser(null);

    setMobileOpen(false);
    window.location.assign('https://support.merqube.com/secur/logout.jsp');
    // window.location.href = import.meta.env.VITE_DOMAIN_URL || '/login';
  };

  const { userMenu, adminMenu, mainMenu, supportMenu, contactItem } = useMenu(onLogout);

  const headerBgColor = theme.palette.background.paper + 'cc';
  return (
    <header>
      <div id="topbar" style={{ backgroundColor: theme.palette.background.paper }}>
        <AppBar
          position="fixed"
          classes={{ root: 'navbar override' }}
          sx={{
            backgroundColor: headerBgColor,
            zIndex: theme => theme.zIndex.drawer + 1,
            paddingRight: 0,
          }}
        >
          <div className="stock-bar" style={{ backgroundColor: theme.palette.background.default }}>
            <div className="marquee">
              <IconButton onClick={toggleTheme} sx={{ height: '30px', width: '30px', mx: 2.5 }}>
                {mode !== 'dark' ? (
                  <LightModeRoundedIcon sx={{ fontSize: '18px' }} />
                ) : (
                  <DarkModeRoundedIcon sx={{ fontSize: '18px' }} />
                )}
              </IconButton>
              |
              {!isMobile && (
                <>
                  <div className="menu-wrapper small">
                    <Button
                      className="marquee-button"
                      variant="text"
                      color="inherit"
                      sx={{ height: '30px', minHeight: '30px' }}
                    >
                      {supportMenu[0].title}
                    </Button>
                    <MenuContent theme={theme}>
                      {supportMenu[0].items.map(menu => (
                        <MenuItem
                          component={menu.path ? Link : 'li'}
                          to={menu.path}
                          onClick={menu.onClick}
                          target={menu.target}
                          key={menu.title}
                          className={clsx({ active: menu.path === location.pathname })}
                        >
                          {menu.title}
                        </MenuItem>
                      ))}
                    </MenuContent>
                  </div>
                  |
                </>
              )}
              <Button
                className="marquee-button"
                variant="text"
                component={Link}
                to={contactItem.path}
                color="inherit"
                sx={{ height: '30px', minHeight: '30px' }}
              >
                {contactItem.title}
              </Button>
              |
              {!verifiedUser ? (
                <Button
                  className="marquee-button"
                  variant="text"
                  onClick={onLogin}
                  color="inherit"
                  sx={{ height: '30px', minHeight: '30px' }}
                >
                  {t('navbar.login')}
                </Button>
              ) : (
                <div className="menu-wrapper small">
                  <Button
                    className="marquee-button"
                    variant="text"
                    color="inherit"
                    data-testid="account-menu"
                    sx={{ height: '30px', minHeight: '30px' }}
                  >
                    {isMobile ? userMenu[0].icon : userMenu[0].title}
                  </Button>
                  <MenuContent theme={theme}>
                    <ProfileBox user={user} />

                    {userMenu[0].items.map(menu => (
                      <MenuItem
                        component={menu.path ? Link : 'li'}
                        to={menu.path}
                        onClick={menu.onClick}
                        key={menu.title}
                        data-testid={menu.id}
                        className={clsx({ active: menu.path === location.pathname })}
                      >
                        {menu.title}
                      </MenuItem>
                    ))}
                  </MenuContent>
                </div>
              )}
            </div>
          </div>
          <Toolbar className="toolbar">
            <Link to="/" style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}>
              <img
                src={theme.palette.mode === 'light' ? Logo : LogoDark}
                className="merqube-logo"
                alt="MerQube logo"
                height="50px"
                width="176px"
                loading="lazy"
              />
            </Link>
            {import.meta.env.VITE_ENV !== 'production' && (
              <b style={{ color: 'red', textTransform: 'uppercase' }}>{import.meta.env.VITE_ENV}</b>
            )}

            {isMobile ? (
              <IconButton
                color="inherit"
                aria-label="Open sidebar menu"
                onClick={handleDrawerToggle}
                size="large"
                sx={{ opacity: Number(!mobileOpen), transition: 'opacity 0.3s ease-in-out' }}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              [...adminMenu, ...mainMenu].map(menu => (
                <div className="menu-wrapper" key={menu.title}>
                  <Button
                    className={clsx('navbar-button', {
                      active: isActive(menu),
                    })}
                    aria-haspopup="true"
                    variant="text"
                    color="inherit"
                  >
                    {menu.title}
                    <DownIcon className="caret" />
                  </Button>
                  <MenuContent theme={theme}>
                    <MenuItem className="submenu-header">
                      {menu.title}
                      <DownIcon className="caret" />
                    </MenuItem>
                    {(menu.items || []).map(item => (
                      <MenuItem
                        component={Link}
                        to={item.path}
                        onClick={onClose}
                        key={item.path}
                        className={clsx({ active: item.path === location.pathname })}
                      >
                        {item.title}
                      </MenuItem>
                    ))}
                  </MenuContent>
                </div>
              ))
            )}
          </Toolbar>
        </AppBar>
        {isMobile && (
          <Drawer
            // variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            disableScrollLock={true}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            classes={{
              paper: 'sidebar-container override',
            }}
            variant="temporary"
            slotProps={{
              backdrop: {
                sx: { backgroundColor: '#00000000' },
              },
            }}
            sx={{
              width: '100%',
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: '100%',
                boxSizing: 'border-box',
                backgroundImage: 'none',
              },
            }}
          >
            <div className="sidebar-header">
              <IconButton
                onClick={handleDrawerToggle}
                className="close-button"
                size="large"
                sx={{ opacity: Number(mobileOpen) }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <MobileMenu
              items={[
                ...mainMenu,
                ...adminMenu,
                ...userMenu,
                ...supportMenu,
                contactItem,
                { title: t('logout'), onClick: onLogout, path: '' },
              ]}
              onClose={onClose}
            />
          </Drawer>
        )}
      </div>
    </header>
  );
};

export default TopBar;

const MenuContent = ({ children, theme }: { children: any; theme: any }) => {
  const boxShadow =
    theme.palette.mode === 'dark' ? '0px 3px 6px #FFFFFF29' : '0px 3px 6px #00000029';

  return (
    <div
      className="menu-content"
      style={{
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.divider,
        boxShadow,
      }}
    >
      {children}
    </div>
  );
};
