export interface CapItem {
  [dateTime: string]: {
    [key: string]: number;
  };
}

export interface CapsStore {
  [indexId: string]: CapItem;
}

const capsStore: CapsStore = {};

export { capsStore };
