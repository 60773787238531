import dayjs from 'dayjs';
import { collection, onSnapshot } from 'firebase/firestore';
import { StateCreator } from 'zustand';
import { firestore } from '../../settings/firebase';

export interface Post {
  id?: string;
  date: string;
  source?: string;
  title: string;
  description: string;
  content?: string;
  link: string;
  thumbnail: string;
  isPublished?: boolean;
  category: string;
  slug?: string;
  author?: string;
}

export interface QubitsSlice {
  news: Post[];
  pressReleases: Post[];
  blogDocs: Post[];
  blogPosts: Post[];
  status: 'init' | 'loading' | 'success' | 'error';
  subscribeNews: (isAdmin?: boolean) => {
    unSubscribe: () => void;
  };
  subscribePress: (isAdmin?: boolean) => {
    unSubscribe: () => void;
  };
  subscribeBlogPosts: (isAdmin?: boolean) => {
    unSubscribe: () => void;
  };
  subscribeBlogDocs: (isAdmin?: boolean) => {
    unSubscribe: () => void;
  };

  //   save: (
  //     name: string,
  //     requestedBy: string,
  //     approvedBy: string,
  //     status: ApprovalStatus
  //   ) => Promise<{ ok: boolean } | { error: string }>;
}

export const createQubitsSlice: StateCreator<QubitsSlice, [], [], QubitsSlice> = setState => ({
  news: [],
  pressReleases: [],
  blog: [],
  blogDocs: [],
  blogPosts: [],
  status: 'init',
  // Get the distribution flow item by indexName

  // Subscribe to changes in the news (this will update the UI in real time)
  subscribeNews: (isAdmin = false) => {
    setState({ status: 'loading' });
    const unSubscribe = onSnapshot(collection(firestore, 'news'), snapshot => {
      const currentNews: any[] = [];
      snapshot.forEach(e => {
        const data = e.data();
        if (
          data.date < dayjs().valueOf() &&
          (data.isPublished || isAdmin) &&
          data.category === 'news'
        ) {
          const item = {
            ...e.data(),
            id: e.id,
          };

          currentNews.push(item);
        }
      });

      setState({
        status: 'success',
        news: currentNews.sort((a, b) => b.date - a.date),
      });
    });

    // To stop listening to changes when the component unmounts
    return {
      unSubscribe,
    };
  },

  subscribePress: (isAdmin = false) => {
    setState({ status: 'loading' });
    const unSubscribe = onSnapshot(collection(firestore, 'news'), snapshot => {
      const currentNews: any[] = [];
      snapshot.forEach(e => {
        const data = e.data();

        if (
          (data.date || 0) < dayjs().valueOf() &&
          (data.isPublished || isAdmin) &&
          data.category === 'press'
        ) {
          const item = {
            ...e.data(),
            id: e.id,
          };

          currentNews.push(item);
        }
      });

      setState({
        status: 'success',
        pressReleases: currentNews.sort((a, b) => b.date - a.date),
      });
    });

    // To stop listening to changes when the component unmounts
    return {
      unSubscribe,
    };
  },

  subscribeBlogDocs: (isAdmin = false) => {
    setState({ status: 'loading' });
    const unSubscribe = onSnapshot(collection(firestore, 'news'), snapshot => {
      const currentNews: any[] = [];
      snapshot.forEach(e => {
        const data = e.data();

        if (
          (data.date || 0) < dayjs().valueOf() &&
          (data.isPublished || isAdmin) &&
          data.category === 'blog'
        ) {
          const item = {
            ...e.data(),
            id: e.id,
          };

          currentNews.push(item);
        }
      });

      setState({
        status: 'success',
        blogDocs: currentNews.sort((a, b) => b.date - a.date),
      });
    });

    // To stop listening to changes when the component unmounts
    return {
      unSubscribe,
    };
  },

  subscribeBlogPosts: (isAdmin = false) => {
    setState({ status: 'loading' });
    const unSubscribe = onSnapshot(collection(firestore, 'blog'), snapshot => {
      const currentNews: any[] = [];
      snapshot.forEach(e => {
        const data = e.data();
        if (data.date < dayjs().valueOf() && (data.isPublished || isAdmin)) {
          const item = {
            ...e.data(),
            id: e.id,
          };

          currentNews.push(item);
        }
      });

      setState({
        status: 'success',
        blogPosts: currentNews.sort((a, b) => b.date - a.date),
      });
    });

    // To stop listening to changes when the component unmounts
    return {
      unSubscribe,
    };
  },

  // Create a new distribution flow item or update an existing one
  // save: async (name: string, requestedBy: string, approvedBy: string, status = 'pending') => {
  //   try {
  //     const itemRef = doc(firestore, 'indexDistributionFlow', name);
  //     const itemDoc = await getDoc(itemRef);
  //     if (itemDoc.exists()) {
  //       // If it exists, update the existing document
  //       await updateDoc(itemRef, {
  //         status,
  //         requestedBy,
  //         approvedBy,
  //         modified: new Date(),
  //       });
  //     } else {
  //       // If it doesn't exist, create a new document
  //       await setDoc(itemRef, {
  //         name,
  //         status,
  //         requestedBy,
  //         approvedBy,
  //         modified: new Date(),
  //       });
  //     }
  //   } catch (error: any) {
  //     return { error: 'There was an error submitting request: ' + error.code };
  //   }
  //   return { ok: true };
  // },
});

export default createQubitsSlice;
