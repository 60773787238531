import { FlatCalendar } from 'components/HolidayCalendarField/types';
import { Constitutent } from './slice';

export interface IndexIdentifier {
  provider: string;
  name: string;
  enabled?: boolean; // default true
}
interface PortfolioDisplay {
  fields: Array<{
    displayName: string;
    fieldName: string;
    roundingDecimals?: number;
  }>;
  displayOrder?: {
    fieldName: string;
    valueOrder: string[];
  };
}

export interface Benchmark {
  symbol: string;
  plotMetric: string;
  name: string;
}

export type UUID = `${string}-${string}-${string}-${string}-${string}`;

export interface RelatedItem {
  defaultDisplay: boolean;
  id: UUID;
  metric: string;
  name: string;
  indexName?: string;
  type: 'index' | 'security';
}
export type Related = RelatedItem;
export type ChartType = 'line' | 'spline' | 'area' | 'bar' | 'column';
export type CompareMode = 'absolute' | 'percent';

interface IntradaySettings {
  ticker_period: number;
  enabled: boolean;
  publish_config: {
    [intradayMetric: string]: any;
  };
}
interface AdministrativeSettings {
  role: string;
  client?: {
    requestedBy?: string;
    approvedBy?: string;
  };
}

interface StatusBlock {
  createdAt: string;
  createdBy: string;
  lastModified: string;
  lastModifiedBy: string;
}

export interface CustomChartProps {
  name: string;
  xlabel: string;
  ylabel: string;
  chartType?: ChartType; // default: 'line'
  compareMode?: CompareMode; // default: 'percent'
  constructorType?: 'chart' | 'stockChart'; // default: 'stockChart'
  size?: number; // default: 12; chart width: 12 means 100%,
  data: Array<{
    url: string;
    arrayField: string;
    values: Array<{ displayName: string; path: string }>;
    keyField: string;
  }>;
}

export type IndexDocument = { filename: string; shared: boolean };
export type IndexSpec = {
  indexClass: string;
  indexClassArgs: {
    spec: {
      baseDate: string;
      baseVal: number;
      calendar: {
        calendarIdentifiers: string[];
        holidaysToAdd: string[];
      };
      corporateActions: {
        dividend: {
          deductTax: boolean;
          reinvestDay: string;
          reinvestStrategy: string;
        };
      };
      holidaySpec: {
        calendarIdentifiers: string[];
        holidaysToAdd: string[];
      };
      indexId: string;
      levelOverrides: [];
      portfolios: {
        constituents: [];
        dateType: string;
        identifierType: 'RIC' | 'MERQUBE_INDEX';
        quantityType: string;
        specificationType: 'API';
      };
      validMics: string[];
    };
  };
};

/////////////////////////// INDEX LIST ///////////////////////////
export interface IndexListItem {
  id: UUID; // /indices *
  name: string; // /indices *
  title: string; // /indices *
  description: string; // /indices *
  family: string; // /indices *
  familyDescription?: string; // /indices *
  launchDate: string; // /indices *
  baseDate: string; // /indices *
  plotMetric?: string; // compare dropdown ?
  namespace: string; // /indices *
  webpage?: {
    customCharts?: CustomChartProps[];
    roundingDecimals?: number;
    disableFactsheet?: boolean;
    chartType?: ChartType; // default: 'line'
    compareMode?: CompareMode; // default: 'percent'
    disableStats?: boolean;
    visible?: boolean;
  };
  tags: string; // /indices
  stage: 'prod' | 'test' | 'development'; // /indices
}

/////////////////////// FULL INDEX MANIFEST ///////////////////////
export interface IndexItem extends IndexListItem {
  calcFreq: string;
  currency: string;
  documents?: { [label: string]: IndexDocument | string };
  identifiers?: IndexIdentifier[];
  plotType?: 'area' | 'line' | 'spline' | 'column' | 'bar';
  portfolioDisplay?: PortfolioDisplay;
  portfolioAllocationDisplay?: boolean;
  rebalFreq: string;
  related: Related[];
  ticker: string;
  administrative: AdministrativeSettings;
  intraday?: IntradaySettings;
  status: StatusBlock;
  runConfiguration?: {
    indexReports: Array<{
      programArgs: Record<string, string>;
      uuid: UUID;
    }>;
    jobEnabled: boolean;
    numDaysToLoad: number;
    podImageAndTag: string;
    schedule: {
      retries: number;
      retryIntervalMin: number;
      scheduleStart: string;
    };
    tzinfo: string;
  };
  spec?: IndexSpec;
}

/////////////////////////// INDEX SELECTOR ///////////////////////////
export interface IndexSelectorItem extends IndexListItem {
  intraday?: IntradaySettings;
}

/////////////////////////// INDEX WIZARD ///////////////////////////
export interface IndexWizardItem extends IndexListItem {
  currency: string;
}

/////////////////////////// INDEX ADMIN ///////////////////////////
export interface IndexAdminItem extends IndexListItem {
  documents?: { [label: string]: IndexDocument | string };
  status: StatusBlock;
}

/////////////////// INDEX ADMIN LIST & MY INDICES //////////////////////
export interface IndexAdminListItem extends IndexListItem {
  administrative: AdministrativeSettings;
  namespace: string;
  currency: string;
  status: StatusBlock;
  spec?: IndexSpec;
}

/////////////////////////// BASKET TEMPLATE ///////////////////////////

export interface IndexStore {
  [key: UUID]: IndexItem;
  code: number;
  selected: IndexItem | null;
}

const indexStore: IndexStore = {
  code: 0,
  selected: null,
};

export { indexStore };

export const DEFAULT_METRIC = 'price_return';

export const INDEX_FIELDS = [
  'base_date',
  'description',
  'family',
  'family_description',
  'id',
  'launch_date',
  'name',
  'namepsace',
  'plot_metric',
  'tags',
  'title',
  'webpage',
  'stage',
];

interface ClientIndexBaseProperties {
  bbg_ticker?: string;
  description: string;
  email_list?: string[];
  name: string;
  namespace: string;
  title: string;
}

type RebalFrequency =
  | 'Annual'
  | 'Daily'
  | 'Monthly'
  | 'Quarterly'
  | 'Semi-Annual'
  | 'Weekly'
  | 'Bi-Monthly'
  | 'Intraday'
  | 'None';

export type UnitOfMesure = 'SHARES' | 'WEIGHT';

export interface ClientMultiEBPortUpdate {
  constituents: Constitutent[];
  index_currency?: string;
  unit_of_measure?: UnitOfMesure;
}

export interface DisseminationSetting {
  destination: 'BLOOMBERG' | 'REFINITIV' | 'MORNINGSTAR' | 'FACTSET';
  ticker: string;
}

interface LevelOverride {
  comment: string;
  date: string;
  level: number;
}

interface TickConfiguration {
  intraday_timezone: string;
  tick_end_hour: number;
  tick_end_minute: number;
  tick_start_hour: number;
  tick_start_minute: number;
}

interface ClientEBCoraxConfig {
  reinvest_dividend_type?: 'INDEX' | 'SECURITY' | 'CASH';
  reinvest_dividends: boolean;
  deduct_tax?: boolean;
}

interface ClientMultiEBSpecific {
  corporate_actions?: ClientEBCoraxConfig;
  dissemination_settings?: DisseminationSetting[];
  level_overrides?: LevelOverride[];
  precision_level?: number;
  rebal_freq?: RebalFrequency;
  tick_configuration?: TickConfiguration;
}

interface ClientBaseVal {
  base_date: string;
  base_value: number;
}

interface ClientMultiEBPortConfig extends ClientMultiEBPortUpdate, ClientBaseVal {
  // No additional properties needed, already defined in ClientMultiEBPortUpdate and ClientBaseVal
}

interface ClientRuntimeInfo {
  run_hour: number;
  run_minute: number;
  timezone: string;
}

interface ClientEquityBasketConfigBase extends ClientIndexBaseProperties, ClientBaseVal {
  currency: string;
  holiday_calendar: FlatCalendar;
  is_intraday: boolean;
}

export interface ClientMultiEBConfig
  extends ClientMultiEBPortConfig,
    ClientMultiEBSpecific,
    ClientRuntimeInfo,
    ClientEquityBasketConfigBase {
  // No additional properties needed, already defined in ClientMultiEBPortConfig, ClientMultiEBSpecific, ClientRuntimeInfo, and ClientEquityBasketConfigBase
}
