import React, { createContext, useState } from 'react';

interface LoaderContextType {
  visited: string[];
  isVisited: (path: string) => boolean;
  register: (path: string) => void;
}

const LoaderContext = createContext<LoaderContextType>({
  visited: [],
  isVisited: () => false,
  register: () => {},
});

const LoaderProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [visited, setVisited] = useState<string[]>([]);

  const isVisited = (path: string) => visited.includes(path);
  const register = (path: string) => setVisited([...visited, path]);

  const value = React.useMemo(() => ({ register, visited, isVisited }), [visited]);

  return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>;
};

export const useLoader = () => React.useContext(LoaderContext);

export default LoaderProvider;
