export interface MetricItem {
  dataType: string;
  description: string;
  name: string;
}

export type MetricType = 'index' | 'equity';

export interface MetricsStore {
  index: {
    [id: string]: MetricItem[];
  };
  equity: {
    [id: string]: MetricItem[];
  };
  selected: {
    [id: string]: string;
  };
}

const metricsStore: MetricsStore = {
  index: {},
  equity: {},
  selected: {},
};

export { metricsStore };
