import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const { VITE_MEASUREMENT_ID } = import.meta.env;

ReactGA.initialize(VITE_MEASUREMENT_ID, {
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});

export default function GoogAnal() {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: window.location.pathname,
    });
  }, [pathname]);

  return null;
}
