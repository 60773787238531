export type PermissionType = 'read' | 'write' | 'create' | 'update' | 'delete';

export interface APIKey {
  id?: string;
  email: string;
  name: string;
  namespace: string;
  sha256hash?: string;
  status?: {
    createdBy: string;
    createdAt: string;
    lastModified: string;
    lastModifiedBy: string;
  };
  provisioningStatus?: {
    lastModified: string;
    state: string;
  };
  permissions: {
    adminUi: {
      pages: string[];
    };
    apis: {
      dataapi: {
        fieldAllowlistWrite: string[];
        namespacesRead: string[];
        namespacesWrite: string[];
        resourceTypeAllowlistRead: string[];
        resourceTypeAllowlistWrite: string[];
      };
      dissapi: {
        fieldAllowlistWrite: string[];
        namespacesRead: string[];
        namespacesWrite: string[];
        resourceTypeAllowlistRead: string[];
        resourceTypeAllowlistWrite: string[];
      };
      indexapi: {
        fieldAllowlistWrite: string[];
        namespacesRead: string[];
        namespacesWrite: string[];
        resourceTypeAllowlistRead: string[];
        resourceTypeAllowlistWrite: string[];
      };
      usersapi: {
        fieldAllowlistWrite: string[];
        namespacesRead: string[];
        namespacesWrite: string[];
        resourceTypeAllowlistRead: string[];
        resourceTypeAllowlistWrite: string[];
      };
    };
    version: string;
  };
}

const apiKeyStore: APIKey[] = [];

export { apiKeyStore };

export const emptyKey: APIKey = {
  name: '',
  email: '',
  namespace: 'test',
  permissions: {
    adminUi: {
      pages: [],
    },
    apis: {
      dataapi: {
        fieldAllowlistWrite: [],
        namespacesRead: [],
        namespacesWrite: [],
        resourceTypeAllowlistRead: [],
        resourceTypeAllowlistWrite: [],
      },
      dissapi: {
        fieldAllowlistWrite: [],
        namespacesRead: [],
        namespacesWrite: [],
        resourceTypeAllowlistRead: [],
        resourceTypeAllowlistWrite: [],
      },
      indexapi: {
        fieldAllowlistWrite: [],
        namespacesRead: [],
        namespacesWrite: [],
        resourceTypeAllowlistRead: [],
        resourceTypeAllowlistWrite: [],
      },
      usersapi: {
        fieldAllowlistWrite: [],
        namespacesRead: [],
        namespacesWrite: [],
        resourceTypeAllowlistRead: [],
        resourceTypeAllowlistWrite: [],
      },
    },
    version: 'v2.1',
  },
};
