import { IndexListItem } from '../Index/store';
import { SubStore } from '../types';

export interface IndicesStore extends SubStore {
  items: IndexListItem[];
  stage: 'all' | 'dev' | 'prod';
  fields: string[];
}

const indicesStore: IndicesStore = {
  items: [],
  stage: 'prod',
  status: 'init',
  code: 0,
  fields: [],
};

export { indicesStore };
