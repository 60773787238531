import { Index } from '../types';
import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { NotificationSlice, batchNotifications } from 'store/Notification/slice';
import { StateCreator } from 'zustand';
import { portfolioAllocationsStore, PortfolioAllocations } from './store';

export interface IBenchmark {
  id: string;
  name: string;
  metric: string;
}

const API_URL = getAPIUrl();

export interface PortfolioAllocationsSlice {
  portfolioAllocations: PortfolioAllocations;
  getPortfolioAllocations: (index: Index) => void;
}

export const createPortfolioAllocationsSlice: StateCreator<
  PortfolioAllocationsSlice & NotificationSlice,
  [],
  [],
  PortfolioAllocationsSlice
> = (set, get) => ({
  portfolioAllocations: portfolioAllocationsStore,
  getPortfolioAllocations: async (index: Index) => {
    const portfolioAllocations = get().portfolioAllocations;
    const url = `${API_URL}/index/${index.id}/portfolio_allocations`;

    if (portfolioAllocations[index.id]) {
      return { portfolioAllocations };
    }
    const headers = await getHeaders('GET');
    const { content, errors } = await responseHandler<any>(
      fetch(url, headers),
      err => `Couldn't load Portfolio Allocations for ${index.name} (${err})`,
      true
    );
    return {
      ...(errors.length ? batchNotifications(get)(errors) : {}),

      portfolioAllocations: {
        ...portfolioAllocations,
        [index.id]: content.results.reduce(
          (acc: any, item: any) => ({
            ...acc,
            [item.date]: item.portfolio,
          }),
          {}
        ),
      },
    };
  },
});

export default createPortfolioAllocationsSlice;
