import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { batchNotifications, NotificationSlice } from '../Notification/slice';
import { Document } from './store';
import { StateCreator } from 'zustand';
import { UUID } from 'store/types';

const INTERNAL_URL = 'https://internal.api.merqube.com';
const API_URL = getAPIUrl();

export interface DocsSlice {
  documents: Document[];
  getDocuments: (force?: boolean) => Promise<Document[]>;
  getCSV: (uuid: UUID, label: string) => Promise<string[][]>;
}

export const createDocsSlice: StateCreator<DocsSlice & NotificationSlice, [], [], DocsSlice> = (
  set,
  get
) => ({
  documents: [],

  getDocuments: async force => {
    const url = `${INTERNAL_URL}/index-documents`;
    const documents = get().documents;

    if (documents.length && !force) {
      return documents;
    }
    const headers = await getHeaders('GET');
    const { content, errors } = await responseHandler<{ results: Document[] }>(
      fetch(url, headers),
      err => `Couldn't load Documents (${err})`
    );
    const results = (content && content.results) || [];
    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      documents: results,
    });
    return results;
  },

  getCSV: async (uuid, label) => {
    const apiUrl = `${API_URL}/index/${uuid}/documents?document_type=${label}`;
    const header = await getHeaders('GET');

    try {
      const responseURL = await fetch(apiUrl, header);

      if (responseURL.ok) {
        const { url } = await responseURL.json();
        const response = await fetch(url);

        if (response.ok) {
          const text = await response.text();
          return parseAndCleanCSV(text);
        }
      }
    } catch (err) {
      console.error(err);
    }

    return [];
  },
});

export default createDocsSlice;

function parseAndCleanCSV(csvData: string): string[][] {
  // Regular expression to match CSV values, including quoted fields with commas
  const csvRegex = /"(.*?)"|([^",\r\n]+)|(?<=,)(?=,)/g;

  let rows = csvData
    .trim()
    .split(/\r?\n/)
    .map(line => {
      const matches = [...line.matchAll(csvRegex)];
      return matches.map(match => match[1] ?? match[2] ?? ''); // Handle empty fields
    });

  // Find empty column indices
  const numCols = Math.max(...rows.map(row => row.length)); // Ensure we check all rows
  const emptyColumns = new Set<number>();

  const [_, ...body] = rows;
  for (let col = 0; col < numCols; col++) {
    if (body.every(row => row[col] === '')) {
      emptyColumns.add(col);
    }
  }

  // Remove empty columns
  rows = rows.map(row => row.filter((_, col) => !emptyColumns.has(col)));

  return rows;
}
