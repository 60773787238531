import { StateCreator } from 'zustand';
import { getHeaders, responseHandler } from '../utils';

const API_URL = import.meta.env.VITE_INTERNAL_API_URL;

export interface NamespaceSlice {
  items: string[];
  get: () => Promise<string[]>;
  status: 'init' | 'loading' | 'done' | 'error';
}

interface Response {
  id: string;
  name: string;
  namespace: string;
}

export const createNamespaceSlice: StateCreator<NamespaceSlice, [], [], NamespaceSlice> = (
  setState,
  getState
) => ({
  items: [],
  status: 'init',
  // Get the distribution flow item by indexName
  get: async () => {
    const { items } = getState();

    if (items.length) {
      return items;
    }

    setState({ status: 'loading' });

    try {
      const headers = await getHeaders('GET');
      const response = await responseHandler<{ results: Response[] }>(
        fetch(`${API_URL}/namespace`, { ...headers, signal: AbortSignal.timeout(3000) }),

        err => `Couldn't load namespace list. (${err})`
      );

      const { content, errors } = response;
      if (errors.length) {
        setState({
          status: 'error',
        });
        console.error('errors: ', errors);

        return [];
      }

      const items = (content?.results || []).map(item => item.name);

      setState({
        items,
        status: 'done',
      });

      return items;
    } catch (e: any) {
      setState({
        status: 'error',
      });
    }
    return [];
  },
});

export default createNamespaceSlice;
