import { StateCreator } from 'zustand';
import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { APIKey, emptyKey } from './store';
import { NotificationSlice, batchNotifications } from 'store/Notification/slice';
import humps from 'humps';

const API_URL = getAPIUrl();

export interface APIKeySlice {
  apiKeys: APIKey[];
  getAPIKeys: (email?: string, force?: boolean) => void;
  addAPIKey: (name: string, email: string) => Promise<AddAPIKeyResponse | APIKeyError>;
  updateAPIKey: (di: string, name: string, email: string) => Promise<AddAPIKeyResponse>;
  deleteAPIKey: (id: string) => void;
}
const createAPIKeysSlice: StateCreator<APIKeySlice & NotificationSlice, [], [], APIKeySlice> = (
  set,
  get
) => ({
  apiKeys: [],

  getAPIKeys: async (email = '', force = false) => {
    const apiKeys = get().apiKeys;
    if (!!apiKeys.length && !force) {
      return { apiKeys };
    }
    const queryParams = email && email.endsWith('@merqube.com') ? '' : `?email=${email}`;
    const headers = await getHeaders('GET');
    const { content, errors } = await responseHandler<{ results: APIKey[] }>(
      fetch(`${API_URL}/apikey${queryParams}`, headers),
      err => `Couldn't load Indices list. (${err})`
    );

    const results = (content && content.results) || [];
    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      apiKeys: results || [],
    });
  },

  addAPIKey: async (name: string, email: string) => {
    const newAPIKey: APIKey = {
      ...emptyKey,
      name,
      email,
      namespace: email.endsWith('@merqube.com') ? 'merqube' : 'test',
    };
    const headers = await getHeaders('POST');
    const { content, errors } = await responseHandler<AddAPIKeyResponse>(
      fetch(`${API_URL}/apikey`, {
        ...headers,
        body: JSON.stringify(humps.decamelizeKeys(newAPIKey)),
      }),
      err => err
    );
    if (errors.length) {
      return { error: errors[0].message } as APIKeyError;
    }
    return content;
  },

  updateAPIKey: async (updateId: string, name: string, email: string) => {
    const apiKeys = get().apiKeys;
    const { id, ...oldKey } = apiKeys.find(key => key.id === updateId) as APIKey;

    const newAPIKey: Partial<APIKey> = {
      ...oldKey,
      name,
      email,
    };

    const headers = await getHeaders('PATCH');
    const { content, errors } = await responseHandler<AddAPIKeyResponse>(
      fetch(`${API_URL}/apikey/${id}`, {
        ...headers,
        body: JSON.stringify(humps.decamelizeKeys(newAPIKey)),
      }),
      err => `Couldn't load Indices list. (${err})`
    );
    if (errors.length) {
      set({
        ...batchNotifications(get)(errors),
      });
    }
    return content;
  },

  deleteAPIKey: async (id: string) => {
    const apiKeys = get().apiKeys;
    if (!apiKeys.length || !apiKeys.find(key => key.id === id)) {
      return { apiKeys };
    }
    const headers = await getHeaders('DELETE');
    const { content, errors } = await responseHandler<AddAPIKeyResponse>(
      fetch(`${API_URL}/apikey/${id}`, headers),
      err => `Couldn't load Indices list. (${err})`
    );
    if (errors.length) {
      set({
        ...batchNotifications(get)(errors),
      });
    }

    set({
      apiKeys: apiKeys.filter(key => key.id !== id),
    });
    return content;
  },
});

export { createAPIKeysSlice };
export default createAPIKeysSlice;

export interface AddAPIKeyResponse {
  apikey: string;
  id: string;
  status: string;
}

export interface APIKeyError {
  error: string;
}
