import queryString from 'query-string';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Loading from '@merq/web-ui/dist/components/Loading';
import { UserContextState } from '@merq/web-ui/dist/providers/userProvider';

interface Props {
  auth: UserContextState;
  authLevel: 'guest' | 'user' | 'verified-user';
}

const AuthRoute: React.FC<React.PropsWithChildren<Props>> = ({ auth, authLevel, children }) => {
  const location = useLocation();
  const nextLocation = (location.search && queryString.parse(location.search).next) || '/';
  const next = Array.isArray(nextLocation) ? nextLocation[0] : nextLocation;
  if (auth.status === 'checking') {
    return <Loading active={true} height={window.innerHeight - 10} />;
  } else if (authLevel === 'guest' && auth.user && auth.user.emailVerified) {
    return <Navigate to={next.startsWith('/login') ? '/' : next} />;
  } else if (authLevel === 'user' && !auth.user) {
    return <Navigate to="/login" />;
  } else if (authLevel === 'verified-user' && (!auth.user || !auth.user.emailVerified)) {
    return <Navigate to="/verify-address" />;
  } else {
    return <>{children}</>;
  }
};

export default AuthRoute;
