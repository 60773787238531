export type pageName =
  | 'apikeys'
  | 'adminIndices'
  | 'adminDocuments'
  | 'adminNews'
  | 'adminTeam'
  | 'userIndicesDashboard'
  | 'adminApproveIndex'
  | 'userCreateSstr'
  | 'userCreateDecrement'
  | 'userCreateBasket'
  | 'userCreateBuffer'
  | 'userCreateStaticBasket'
  | 'userCreateSingleOption'
  | 'userCreateOptions'
  | 'userCreateDefinedOutcome';

export const pageNames: pageName[] = [
  'apikeys',
  'adminIndices',
  'adminDocuments',
  'adminNews',
  'adminTeam',
  'userIndicesDashboard',
  'userCreateSstr',
  'userCreateDecrement',
  'userCreateBasket',
  'userCreateBuffer',
  'adminApproveIndex',
  'userCreateStaticBasket',
  'userCreateSingleOption',
  'userCreateOptions',
  'userCreateDefinedOutcome',
];

export type pageAction = 'ADMIN' | 'VIEW' | 'ADD' | 'EDIT' | 'DELETE';

interface PagePermission {
  name: pageName;
  actions: pageAction[];
  fieldRestrictions: {
    fieldAllowlistWrite?: string[];
    fieldAllowlistRead?: string[];
  };
}

interface FieldAllowItem {
  resourceType: string;
  fieldList: string[];
  fullAccessNamespaces: string[];
}

interface ApiPermission {
  namespacesRead: string[];
  namespacesWrite: string[];
  resourceTypeAllowlistRead: string[];
  resourceTypeAllowlistWrite: string[];
  fieldAllowlistWrite: FieldAllowItem[];
}

export interface OauthPermissions {
  id: string;
  name: string;
  namespace: string;
  permissions: {
    version: string;
    apis: {
      dataapi: ApiPermission;
      dissapi: ApiPermission;
      indexapi: ApiPermission;
      usersapi: ApiPermission;
    };
    adminUi: {
      pages: string[];
    };
    merqubeWebsite?: {
      pages: PagePermission[];
    };
  };
  website?: {
    theme?: {
      logo_uri: string;
      backgroundColor: string;
      primaryColor: string;
      primaryTextColor: string;
      secondaryColor: string;
      secondaryTextColor: string;
      successColor: string;
      successTextColor: string;
      errorColor: string;
      errorTextColor: string;
    };
    userType: 'distributor' | 'customer' | 'employee';
  };
}
export interface UserPermissions {
  id: string;
  name: string;
  namespace: string;
  permissions: {
    adminUi: {
      pages: string[];
    };
    apis: {
      dataapi: ApiPermission;
      dissapi: ApiPermission;
      indexapi: ApiPermission;
      usersapi: ApiPermission;
    };
    website: {
      [page in pageName]: PagePermission;
    };
    version: string;
  };
  website?: {
    userType?: 'distributor' | 'customer' | 'employee';
  };
}

const permissionsStore: UserPermissions = {
  id: '',
  name: '',
  namespace: '',
  permissions: {
    adminUi: {
      pages: [],
    },
    apis: {
      dataapi: {
        fieldAllowlistWrite: [],
        namespacesRead: [],
        namespacesWrite: [],
        resourceTypeAllowlistRead: [],
        resourceTypeAllowlistWrite: [],
      },
      dissapi: {
        fieldAllowlistWrite: [],
        namespacesRead: [],
        namespacesWrite: [],
        resourceTypeAllowlistRead: [],
        resourceTypeAllowlistWrite: [],
      },
      indexapi: {
        fieldAllowlistWrite: [],
        namespacesRead: [],
        namespacesWrite: [],
        resourceTypeAllowlistRead: [],
        resourceTypeAllowlistWrite: [],
      },
      usersapi: {
        fieldAllowlistWrite: [],
        namespacesRead: [],
        namespacesWrite: [],
        resourceTypeAllowlistRead: [],
        resourceTypeAllowlistWrite: [],
      },
    },
    website: {
      apikeys: {
        name: 'apikeys',
        actions: [],
        fieldRestrictions: {},
      },
      adminIndices: {
        name: 'adminIndices',
        actions: [],
        fieldRestrictions: {},
      },
      adminDocuments: {
        name: 'adminDocuments',
        actions: [],
        fieldRestrictions: {},
      },
      adminNews: {
        name: 'adminNews',
        actions: [],
        fieldRestrictions: {},
      },
      adminTeam: {
        name: 'adminTeam',
        actions: [],
        fieldRestrictions: {},
      },
      userIndicesDashboard: {
        name: 'userIndicesDashboard',
        actions: [],
        fieldRestrictions: {},
      },
      userCreateSstr: {
        name: 'userCreateSstr',
        actions: [],
        fieldRestrictions: {},
      },
      userCreateDecrement: {
        name: 'userCreateDecrement',
        actions: [],
        fieldRestrictions: {},
      },
      userCreateBasket: {
        name: 'userCreateBasket',
        actions: [],
        fieldRestrictions: {},
      },
      userCreateBuffer: {
        name: 'userCreateBuffer',
        actions: [],
        fieldRestrictions: {},
      },
      userCreateStaticBasket: {
        name: 'userCreateStaticBasket',
        actions: [],
        fieldRestrictions: {},
      },
      userCreateSingleOption: {
        name: 'userCreateSingleOption',
        actions: [],
        fieldRestrictions: {},
      },
      userCreateOptions: {
        name: 'userCreateOptions',
        actions: [],
        fieldRestrictions: {},
      },
      userCreateDefinedOutcome: {
        name: 'userCreateDefinedOutcome',
        actions: [],
        fieldRestrictions: {},
      },
    } as any,
    version: '',
  },
};

export { permissionsStore };
