import { useTheme } from '@mui/material/styles';
import SuspenseLoader from 'components/SuspenseLoader';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import React, { Suspense, lazy, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ScrollToTop from 'scrolltop';
import NavBar from '../NavBar';
import Notifications from '../Notifications';
import { hasFooter, hasNavBar } from './settings';
import './styles.scss';

const Footer = lazy(() => import('components/Footer'));

const Layout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const theme = useTheme();
  useGoogleAnalytics();

  useEffect(() => {
    const pathNameArray = location.pathname.split('/');
    const lastPath = pathNameArray[pathNameArray.length - 1];
    if (lastPath !== 'fr') {
      i18n.changeLanguage('en');
    }
  }, [location.pathname]);

  const displayFooter = hasFooter(location.pathname);
  const displayNavBar = hasNavBar(location.pathname);

  return (
    <>
      <ScrollToTop />
      {displayNavBar && <NavBar />}

      <div className="layout-body" style={{ backgroundColor: theme.palette.background.default }}>
        {children}
        {displayFooter && (
          <Suspense fallback={<SuspenseLoader />}>
            <Footer />
          </Suspense>
        )}
      </div>
      <Notifications />
    </>
  );
};

export default Layout;
