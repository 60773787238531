import { StateCreator } from 'zustand';
import { IndexItem } from '../Index/store';
import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { PerformanceMetrics } from './store';

const API_URL = getAPIUrl();

export interface IndexAnalyticsSlice {
  items: {
    [indexId: string]: PerformanceMetrics[];
  };
  status: 'init' | 'loading' | 'done' | 'error';
  get: (index: IndexItem) => Promise<void>;
}

export const createIndexAnalyticsSlice: StateCreator<
  IndexAnalyticsSlice,
  [],
  [],
  IndexAnalyticsSlice
> = (setState, getState) => ({
  items: {},
  status: 'init',

  get: async (index: IndexItem) => {
    const mainUrl = `${API_URL}/index/${index.id}/stats`;
    const analyticsStats = getState().items;
    const cached = Object.keys(analyticsStats);
    const mainCached = cached.includes(index.id);

    const related = (index.related || []).filter(
      d => d.defaultDisplay && d.id && !cached.includes(d.id)
    );

    const relatedUrls = related.map(r => {
      const url = `${API_URL}/${r.type === 'security' ? 'security/equity_legacy' : 'index'}/${
        r.id
      }/stats`;

      return url;
    });

    const ids = mainCached ? related.map(r => r.id) : [index.id, ...related.map(r => r.id)];
    const urls = mainCached ? relatedUrls : [mainUrl, ...relatedUrls];

    if (!urls.length) {
      return;
    }
    setState({ status: 'loading' });
    const headers = await getHeaders('GET');

    const promises = urls.map(url =>
      responseHandler<{ results: PerformanceMetrics[] }>(
        fetch(url, headers),
        err => `Couldn't load Stats for ${index.name} (${err})`,
        true
      )
    );

    const responses = await Promise.all(promises);

    const newStats = responses.reduce((acc, { content }, idx) => {
      const current = ids[idx];
      return {
        ...acc,
        [current]: content?.results || [],
      };
    }, analyticsStats);

    setState({
      status: 'done',
      items: newStats,
    });
  },
});

export default createIndexAnalyticsSlice;
