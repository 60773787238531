import { UUID } from 'store/types';
import { StateCreator } from 'zustand';
import { DEFAULT_METRIC, IndexItem, IndexListItem } from 'store/Index/store';
import { SelectorOption } from 'hooks/chartSelector';

export interface ChartSerie extends SelectorOption {
  colorIndex: number;
  // defaultDisplay: boolean;
}

export interface ChartStateSlice {
  id: UUID | null;
  options: SelectorOption[];
  selected: ChartSerie[];

  initFromIndex: (index: IndexItem, extras?: string[]) => void;
  initSeries: (indices: IndexListItem[], extras?: string[]) => void;
  selectSerie: (id: UUID, color: number) => void;
  removeSerie: (id: UUID) => void;
  getSeries: () => { options: SelectorOption[]; selected: ChartSerie[] };
  setSelected: (selected: ChartSerie[]) => void;
}

export const createChartStateSlice: StateCreator<ChartStateSlice, [], [], ChartStateSlice> = (
  set,
  get
) => ({
  id: null,
  chart: null,
  options: [],
  selected: [],

  initFromIndex: (index, extras = []) => {
    const { options } = get();
    const mainSerie: ChartSerie = {
      value: index.id,
      colorIndex: 0,
      metric: index.plotMetric || DEFAULT_METRIC,
      defaultDisplay: true,
      label: index.name,
      type: 'index',
      isRealTime: false,
    };
    const series: ChartSerie[] = (index.related || [])
      .filter(r => r.defaultDisplay && r.id !== index.id)
      .map((related, i) => ({
        value: related.id,
        colorIndex: 1 + i,
        metric: related.metric,
        defaultDisplay: related.defaultDisplay,
        label: related.name,
        type: related.type || 'index',
        isRealTime: false,
      }));

    // add security series that are not in the options
    const related = series
      .filter(s => !options.find(o => o.value === s.value))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ colorIndex, ...option }) => option);

    const selectedExtras = extras
      .map((name, i) => {
        const option = options.find(o => o.label === name);
        if (!option) return;

        return {
          label: name,
          value: option.value,
          type: 'index',
          isRealTime: false,
          defaultDisplay: true,
          metric: option.metric,
          colorIndex: i + related.length + 1,
        };
      })
      .filter(Boolean) as ChartSerie[];

    set({
      id: index.id,
      selected: [mainSerie, ...series, ...selectedExtras],
      options: [...options, ...related],
    });
  },
  initSeries: (indices, extras = []) => {
    const { options, selected } = get();
    if (!indices.length || options.find(o => o.type === 'index')) {
      return;
    }

    const indexOptions: SelectorOption[] = indices.map(index => ({
      value: index.id,
      metric: index.plotMetric || DEFAULT_METRIC,
      label: index.name,
      type: 'index',
      isRealTime: false,
    }));

    const selectedExtras = extras
      .map((name, i) => {
        const option = indexOptions.find(o => o.label === name);
        if (!option) return;

        return {
          label: name,
          value: option.value,
          type: 'index',
          isRealTime: false,
          metric: option.metric,
          colorIndex: i + selected.length,
        };
      })
      .filter(Boolean) as ChartSerie[];

    // add security series that are not in the options
    if (selectedExtras.length) {
      set({ selected: [...selected, ...selectedExtras], options: [...options, ...indexOptions] });
    } else {
      set({ options: [...options, ...indexOptions] });
    }
  },
  getSeries: () => {
    const { options, selected } = get();

    return { options, selected };
  },

  selectSerie: (serieId, colorIndex) => {
    const { selected, options } = get();

    const serie = { colorIndex, ...options.find(s => s.value === serieId)! };

    set({ selected: [...selected, serie] });
  },

  setSelected: selected => {
    set({ selected });
  },

  removeSerie: id => {
    const { selected } = get();

    set({ selected: selected.filter(s => s.value !== id) });
  },
});

export default createChartStateSlice;
