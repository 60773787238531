import { Index } from '../types';
import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { batchNotifications, NotificationSlice } from '../Notification/slice';
import { CapsStore, capsStore } from './store';
import { StateCreator } from 'zustand';

const API_URL = getAPIUrl();

export interface CapsSlice {
  caps: CapsStore;
  getCaps: (index: Index) => void;
}

export const createCapsSlice: StateCreator<CapsSlice & NotificationSlice, [], [], CapsSlice> = (
  set,
  get
) => ({
  caps: capsStore,

  getCaps: async (index: Index) => {
    const url = `${API_URL}/index/${index.id}/caps`;
    const caps = get().caps;

    if (caps[index.id]) {
      return { caps };
    }
    const headers = await getHeaders('GET');
    const { content, errors } = await responseHandler<any>(
      fetch(url, headers),
      err => `Couldn't load Caps for ${index.id} (${err})`
    );
    const results = (content && content.results) || [];
    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      caps: {
        ...caps,
        [index.id]: results.reduce((acc: any, cap: any) => ({ ...acc, [cap.date]: cap.cap }), {}),
      },
    });
  },
});

export default createCapsSlice;
