import { lazy } from 'react';
import { useLocation } from 'react-router-dom';
import { pageAction, pageName } from 'store/Permission/store';
const About = lazy(() => import('pages/About'));
const API = lazy(() => import('pages/API'));
const AdminApiKeys = lazy(() => import('pages/Admin/ApiKeys'));
const AdminBlogPost = lazy(() => import('pages/Admin/BlogPost'));
const AdminIndices = lazy(() => import('pages/Admin/Indices'));
const AdminLandingPages = lazy(() => import('pages/Admin/LandingPages'));
const AdminIndex = lazy(() => import('pages/Admin/IndexPage'));
const AdminDocs = lazy(() => import('pages/Admin/Documents'));
const AdminNews = lazy(() => import('pages/Admin/News'));
const AdminTeam = lazy(() => import('pages/Admin/Team'));
const APIRaw = lazy(() => import('pages/APIRaw'));
const Login = lazy(() => import('pages/Auth/Login'));
const Logout = lazy(() => import('pages/Auth/Logout'));
const PasswordReset = lazy(() => import('pages/Auth/PasswordReset'));
const PasswordChange = lazy(() => import('pages/Auth/PasswordChange'));
const Profile = lazy(() => import('pages/Auth/Profile'));
const Register = lazy(() => import('pages/Auth/Register'));
const Verify = lazy(() => import('pages/Auth/Verify'));
const BlogPost = lazy(() => import('pages/Blog/post'));
const Qubits = lazy(() => import('pages/Qubits'));
const Contact = lazy(() => import('pages/Contact'));
const Home = lazy(() => import('pages/Home'));
const IndexPage = lazy(() => import('pages/IndexPage'));
// const IndexBenchmark = lazy(() => import('pages/IndexBenchmark'));
const IndexWizard = lazy(() => import('pages/IndexWizard'));
const Indices = lazy(() => import('pages/Indices'));
const MyIndices = lazy(() => import('pages/MyIndices'));
const NotFound = lazy(() => import('pages/NotFound'));
const Docs = lazy(() => import('pages/PDF'));
const Files = lazy(() => import('pages/File'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('pages/Terms'));
const Governance = lazy(() => import('pages/Governance'));
const SupplementalIndices = lazy(() => import('pages/Governance/SupplementalIndices'));
const SupportDocs = lazy(() => import('pages/SupportDocs'));
const adminPerms = lazy(() => import('pages/Admin/Permissions'));
const authHandler = lazy(() => import('pages/Auth/AuthHandler'));
const events = lazy(() => import('pages/Events'));
const eventAlbum = lazy(() => import('pages/Events/Album'));

const APIOrNotFound = () => {
  const location = useLocation();
  const isApi = !!location.pathname.match(
    /(api.merqube.com|(\/api(\/staging|\/internal|\/main|\/indexapi|\/data|\/diss|\/indexapi-staging)))/
  );
  return isApi ? <APIRaw /> : <NotFound />;
};

const GoToStaging = () => {
  window.location.replace('https://staging.merqube.com');
  return <></>;
};

export const routes = {
  about: { path: '/about/our-story', component: About },
  aboutCareers: { path: '/about/careers', component: About },
  aboutTeam: { path: '/about/our-team', component: About },
  api: { path: '/api', component: API },
  contact: { path: '/contact', component: Contact },
  docs: { path: '/docs', component: Docs },
  verify: { path: '/verify', component: Verify },
  filesDirectoryPathname: { path: 'files/:directory/:pathname', component: Files },
  indexFilePathname: { path: 'files/:uuid/:label/:filename', component: Files },
  filesPathname: { path: 'files/:pathname', component: Files },
  governance: { path: '/governance', component: Governance },
  announcements: { path: '/governance/announcements', component: Governance },
  announcementFile: { path: '/governance/announcements/:filename', component: Files },
  consultations: { path: '/governance/consultations', component: Governance },
  consultationFile: { path: '/governance/consultations/:filename', component: Files },
  policies: { path: '/governance/policies', component: Governance },
  policieFile: { path: '/governance/policies/:filename', component: Files },
  supplementalData: { path: '/governance/supplemental-data', component: Governance },
  supplementalDataIndices: {
    path: '/governance/supplemental-data/indices/:language?',
    component: SupplementalIndices,
  },
  // indexBenchmark: { path: '/indices/:indexName/benchmark', component: IndexBenchmark },
  // indexBenchmarkRisk: { path: '/indices/:indexName/benchmark/risk', component: IndexBenchmark },
  // indexBenchmarkMeasure: {
  //   path: '/indices/:indexName/benchmark/measure',
  //   component: IndexBenchmark,
  // },
  // indexBenchmarkStats: {
  //   path: '/indices/:indexName/benchmark/statistics',
  //   component: IndexBenchmark,
  // },
  index: { path: '/index/:indexName', component: IndexPage },
  indexPage: { path: '/indices/:indexName/:language?', component: IndexPage },
  indices: { path: '/indices', component: Indices },
  customLanding: { path: '/indices/partners/:slug', component: Indices },
  myIndices: { path: '/account/my-indices', component: MyIndices },
  news: { path: '/news/coverage', component: Qubits },
  blog: { path: '/news/blog', component: Qubits },
  blogdoc: { path: '/news/blog/file/:filename', component: Files },
  blogpost: { path: '/news/blog/:slug', component: BlogPost },
  press: { path: '/news/press', component: Qubits },
  newsPressFile: { path: '/news/press/:filename', component: Files },
  passwordReset: { path: '/password-reset', component: PasswordReset },
  privacyPolicy: { path: '/privacy-policy', component: PrivacyPolicy },
  rawApiUrl: { path: '/raw-api/:url', component: APIRaw },
  staging: { path: '/staging', component: GoToStaging },
  supportDocs: { path: '/support/documents', component: SupportDocs },
  supportDocsItems: { path: '/support/documents/:filename', component: Files },
  swagger: { path: '/swagger', component: API },
  termsOfUse: { path: '/terms-of-use', component: TermsOfUse },
  logout: { path: '/logout', component: Logout },
  authHandler: { path: '/auth-handler', component: authHandler },
  events: { path: '/events/:eventName', component: events },
  eventAlbum: { path: '/events/:eventName/album', component: eventAlbum },
  // default routes, must be at the bottom
  main: {
    path: '/',
    component: Home,
  },
  notFound: {
    path: '*',
    component: APIOrNotFound,
  },
};

interface AuthRoute {
  path: string;
  component: any;
  level: 'guest' | 'user' | 'verified-user';
}
interface AuthRoutes {
  login: AuthRoute;
  register: AuthRoute;
  protectedFiles: AuthRoute;
  profile: AuthRoute;
  accountMyIndices: AuthRoute;
  passwordChange: AuthRoute;
  accountApiKeys: AuthRoute;
}

export const authRoutes: AuthRoutes = {
  login: { path: '/login', component: Login, level: 'guest' },
  register: { path: '/register', component: Register, level: 'guest' },
  protectedFiles: { path: 'protected/:pathname', component: Files, level: 'verified-user' },
  profile: { path: '/account/profile', component: Profile, level: 'user' },
  accountMyIndices: { path: '/account/my-indices', component: MyIndices, level: 'verified-user' },
  accountApiKeys: { path: '/account/api-keys', component: AdminApiKeys, level: 'verified-user' },
  passwordChange: { path: '/account/password-change', component: PasswordChange, level: 'user' },
};

interface AdminRoute {
  path: string;
  component: any;
  namespace: pageName;
  level?: pageAction;
}

interface AdminRoutes {
  accountNewBacktest: AdminRoute;
  accountNewIndexRequest: AdminRoute;
  accountNewIndex: AdminRoute;
  accountTemplateIndex: AdminRoute;
  adminIndices: AdminRoute;
  adminNewIndex: AdminRoute;
  adminEditIndex: AdminRoute;
  adminLandingPages: AdminRoute;
  adminNews: AdminRoute;
  adminPress: AdminRoute;
  adminBlog: AdminRoute;
  adminBlogPosts: AdminRoute;
  adminBlogDocs: AdminRoute;
  adminIndexDocs: AdminRoute;
  adminTeam: AdminRoute;
  adminNewBlogPost: AdminRoute;
  adminEditBlogPost: AdminRoute;
  adminPerms: AdminRoute;
}

export const adminRoutes: AdminRoutes = {
  accountNewBacktest: {
    path: `/account/new-backtest:/:indexType`,
    namespace: 'userIndicesDashboard',
    level: 'VIEW',
    component: () => <IndexWizard flowType="backtest" />,
  },
  accountNewIndexRequest: {
    path: `/account/new-index-request/:indexType`,
    namespace: 'userIndicesDashboard',
    level: 'VIEW',
    component: () => <IndexWizard flowType="indexRequest" />,
  },
  accountNewIndex: {
    path: `/account/new-index/:indexType`,
    namespace: 'userIndicesDashboard',
    level: 'VIEW',
    component: () => <IndexWizard flowType="indexCreation" />,
  },
  accountTemplateIndex: {
    path: `/account/new-index/:indexType/:templateId`,
    namespace: 'userIndicesDashboard',
    level: 'VIEW',
    component: () => <IndexWizard flowType="indexCreation" />,
  },
  adminIndices: {
    path: `/admin/indices`,
    namespace: 'adminIndices',
    component: AdminIndices,
  },
  adminNewIndex: {
    path: `/admin/indices/new`,
    namespace: 'adminIndices',
    level: 'ADD',
    component: AdminIndex,
  },
  adminEditIndex: {
    path: `/admin/indices/:indexName`,
    namespace: 'adminIndices',
    level: 'EDIT',
    component: AdminIndex,
  },
  adminLandingPages: {
    path: `/admin/landing-pages`,
    namespace: 'adminIndices',
    component: AdminLandingPages,
  },
  adminNews: {
    path: `/admin/news/coverage`,
    namespace: 'adminNews',
    component: AdminNews,
  },
  adminPress: {
    path: `/admin/news/press`,
    namespace: 'adminNews',
    component: AdminNews,
  },
  adminBlog: {
    path: `/admin/news/blog`,
    namespace: 'adminNews',
    component: AdminNews,
  },
  adminBlogPosts: {
    path: `/admin/news/blog-posts`,
    namespace: 'adminNews',
    component: AdminNews,
  },
  adminBlogDocs: {
    path: `/admin/news/blog-docs`,
    namespace: 'adminNews',
    component: AdminNews,
  },
  adminNewBlogPost: {
    path: `/admin/news/blog/post`,
    namespace: 'adminNews',
    component: AdminBlogPost,
  },
  adminEditBlogPost: {
    path: `/admin/news/blog/:slug`,
    namespace: 'adminNews',
    component: AdminBlogPost,
  },
  adminIndexDocs: {
    path: `/admin/docs`,
    namespace: 'adminDocuments',
    component: AdminDocs,
  },
  adminTeam: {
    path: `/admin/team`,
    namespace: 'adminTeam',
    component: AdminTeam,
  },
  adminPerms: {
    path: `/admin/permissions`,
    namespace: 'adminIndices',
    component: adminPerms,
  },
};

const allRoutes = { ...routes, ...authRoutes, ...adminRoutes };
type RouteConfig = {
  [K in keyof typeof allRoutes]: string;
};
const routeConfig = Object.keys(allRoutes).reduce<RouteConfig>(
  (acc, route) => ({
    ...acc,
    [route]: (allRoutes as any)[route].path,
  }),
  {} as RouteConfig
);

export default routeConfig;
