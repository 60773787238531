export interface StatsItem {
  annualVolatility: number;
  annualizedReturn: number;
  cumulativeReturn: number;
  endDate: string;
  maxDrawdown: number;
  sharpeRatio: number;
  startDate: string;
  lookback?: string;
}

export interface StatsRange {
  [period: string]: StatsItem;
}

export type UUID = `${string}-${string}-${string}-${string}-${string}`;

export interface StatsStore {
  startDate: string;
  endDate: string;
  [indexId: UUID]: {
    [metricName: string]: {
      asOfDate: string;
      range: StatsRange;
    };
  };
}

const statsStore: StatsStore = { startDate: '', endDate: '' };

export { statsStore };
