import { UUID } from 'store/types';

export interface IdentifierTemplate {
  index_name: string;
  metric: string;
  name: string;
  namespace: string;
  rounding_decimals: number;
}

export interface StatusBlock {
  created_at: string;
  created_by: string;
  last_modified: string;
  last_modified_by: string;
  locked_after?: string;
}

export type IdentifierType = 'BLOOMBERG' | 'REFINITIV' | 'MORNINGSTAR' | 'FACTSET';

export interface IdentifierItem<ProviderArgs> {
  display_name?: string;
  id: UUID;
  index_name: string;
  namespace: string;
  start_date?: string;
  metric?: string;
  ticker: string;
  rounding_decimals?: number;
  provider_kwargs?: ProviderArgs;
  status: StatusBlock;
}

export type Identifier = IdentifierItem<any>;
export interface IdentifierPost extends Omit<Identifier, 'id' | 'status'> {}

export interface BloombergKWArgs {
  post_type: 'EOD' | 'RT' | 'EOD_AND_RT';
}

export interface ReutersKWArgs {
  post_type: 'EOD' | 'RT' | 'EOD_AND_RT';
}

export interface SecApitKWArgs {
  metric: string;
  security_type: string;
  security_name: string;
}

export interface NasdaqKWArgs {
  eod_date_delta: number;
  nasdaq_env: 'production' | 'testing';
}

export interface IdentifierStore {
  [provider: string]: {
    [indexName: string]: Identifier;
  };
}

const identifierStore: IdentifierStore = {};

export { identifierStore };
