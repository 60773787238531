import { create } from 'zustand';
import { APIKeySlice, createAPIKeysSlice } from './APIKeys/slice';
import { CalendarTypeSlice, createCalendarTypeSlice } from './CalendarType/slice';
import { CapsSlice, createCapsSlice } from './Caps/slice';
import { CustomHistorySlice, createCustomHistorySlice } from './CustomHistory/slice';
import { DataCollectionSlice, createDataCollectionSlice } from './DataCollection/slice';
import { DocsSlice, createDocsSlice } from './Documents/slice';
import { IdentifierSlice, createIdentifierSlice } from './Identifier/slice';
import { IndexSlice, createIndexSlice } from './Index/slice';
import {
  IndexDistributionFlowSlice,
  createIndexDistributionFlowSlice,
} from './IndexDistributionFlow/slice';
import { IndexOptionSlice, createIndexOptionSlice } from './IndexOptions/slice';
import { IndexParamsSlice, createIndexParamsSlice } from './IndexParams/slice';
import { IndexRunStatusSlice, createIndexRunStatusSlice } from './IndexRunStatus/slice';
import { IndicesSlice, createIndicesSlice } from './Indices/slice';
import { JobSlice, createJobsSlice } from './Jobs/slice';
import { MetricsSlice, createMetricsSlice } from './Metrics/slice';
import { NotificationSlice, createNotificationSlice } from './Notification/slice';
import { PermissionSlice, createPermissionSlice } from './Permission/slice';
import { PortfolioSlice, createPortfolioSlice } from './Portfolio/slice';
import {
  PortfolioAllocationsSlice,
  createPortfolioAllocationsSlice,
} from './PortfolioAllocations/slice';
import { PriceHistorySlice, createPriceHistorySlice } from './PriceHistory/slice';
import { RealTimeSlice, createRealTimeSlice } from './RealTime/slice';
import { SecurityExchangeSlice, createSecurityExchangeSlice } from './SecurityExchange/slice';
import { StatsSlice, createStatsSlice } from './Stats/slice';
import { QubitsSlice, createQubitsSlice } from './Qubits/slice';
import { IndexPageSlice, createIndexPageSlice } from './IndexPages/slice';
import { ChartStateSlice, createChartStateSlice } from './ChartState/slice';
import { createOptionAvailabilitySlice, OptionAvailabilitySlice } from './OptionAvailability/slice';
import { NamespaceSlice, createNamespaceSlice } from './Namespace/slice';
import { namespace } from './utils';
import { IndexAnalyticsSlice, createIndexAnalyticsSlice } from './IndexAnalytics/slice';

const useStore = create<
  NotificationSlice &
    IndicesSlice &
    IndexSlice &
    PriceHistorySlice &
    PortfolioSlice &
    CapsSlice &
    StatsSlice &
    CustomHistorySlice &
    MetricsSlice &
    PortfolioAllocationsSlice &
    RealTimeSlice &
    DataCollectionSlice &
    JobSlice &
    APIKeySlice &
    SecurityExchangeSlice &
    PermissionSlice &
    DocsSlice &
    IndexOptionSlice & {
      distributionFlow: IndexDistributionFlowSlice;
      indexAnalytics: IndexAnalyticsSlice;
      indexParams: IndexParamsSlice;
      indexRunStatus: IndexRunStatusSlice;
      calendarType: CalendarTypeSlice;
      identifier: IdentifierSlice;
      qubits: QubitsSlice;
      indexPages: IndexPageSlice;
      chartState: ChartStateSlice;
      optionAvailability: OptionAvailabilitySlice;
      namespaces: NamespaceSlice;
    }
>()((...a) => ({
  ...createNotificationSlice(...a),
  ...createIndicesSlice(...a),
  ...createIndexSlice(...a),
  ...createPriceHistorySlice(...a),
  ...createPortfolioSlice(...a),
  ...createCapsSlice(...a),
  ...createStatsSlice(...a),
  ...createCustomHistorySlice(...a),
  ...createMetricsSlice(...a),
  ...createPortfolioAllocationsSlice(...a),
  ...createRealTimeSlice(...a),
  ...createDataCollectionSlice(...a),
  ...createJobsSlice(...a),
  ...createAPIKeysSlice(...a),
  ...createSecurityExchangeSlice(...a),
  ...createPermissionSlice(...a),
  ...createDocsSlice(...a),
  ...createIndexOptionSlice(...a),
  distributionFlow: { ...namespace('distributionFlow', createIndexDistributionFlowSlice)(...a) },
  indexParams: { ...namespace('indexParams', createIndexParamsSlice)(...a) },
  indexRunStatus: { ...namespace('indexRunStatus', createIndexRunStatusSlice)(...a) },
  calendarType: { ...namespace('calendarType', createCalendarTypeSlice)(...a) },
  identifier: { ...namespace('identifier', createIdentifierSlice)(...a) },
  qubits: { ...namespace('qubits', createQubitsSlice)(...a) },
  indexPages: { ...namespace('indexPages', createIndexPageSlice)(...a) },
  chartState: { ...namespace('chartState', createChartStateSlice)(...a) },
  optionAvailability: { ...namespace('optionAvailability', createOptionAvailabilitySlice)(...a) },
  namespaces: { ...namespace('namespaces', createNamespaceSlice)(...a) },
  indexAnalytics: { ...namespace('indexAnalytics', createIndexAnalyticsSlice)(...a) },
}));

export default useStore;
