import React, { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import useStore from 'store';

const delay = 3000;

interface Timer {
  [key: number]: NodeJS.Timeout;
}

const Notifications = () => {
  const [notifications, closeNotification, deleteNotification] = useStore(state => [
    state.notifications,
    state.closeNotification,
    state.deleteNotification,
  ]);

  const timers: Timer = {};
  useEffect(() => {
    notifications.forEach(notification => {
      if (!timers[notification.id] && notification.open) {
        timers[notification.id] = setTimeout(() => {
          closeNotification(notification.id);
          setTimeout(() => {
            deleteNotification(notification.id);
          }, 500);
        }, delay);
      }
    });
  }, [notifications]);

  const onClose = (id: number) => () => {
    closeNotification(id);
    setTimeout(() => {
      deleteNotification(id);
    }, 500);
  };

  return (
    <>
      <Snackbar open={!!notifications.length}>
        <div>
          {notifications.map(notification => (
            <Collapse in={Boolean(notification.open)} key={String(notification.id)}>
              <Alert onClose={onClose(notification.id)} severity={notification.type as AlertColor}>
                {notification.message}
              </Alert>
            </Collapse>
          ))}
        </div>
      </Snackbar>
    </>
  );
};

export default Notifications;
