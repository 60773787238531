import { getAPIUrl, getHeaders } from '../utils';
import { StateCreator } from 'zustand';
import {
  PriceHistory,
  PriceHistoryItem,
  PriceHistoryMetric,
  SecAPIResult,
  SecItem,
  Manifest,
  priceHistoryStore,
} from './store';
import { IndexItem, Related, DEFAULT_METRIC, RelatedItem } from '../Index/store';
import { IndexSlice, getByName } from '../Index/slice';
import { IndicesSlice } from '../Indices/slice';
import dayjs from 'dayjs';
import { SelectorOption } from 'hooks/chartSelector';
import {
  NotificationSlice,
  batchNotifications,
  NotificationItemType,
} from 'store/Notification/slice';
import getSettings from 'pages/IndexPage/hardcodedSettings';

const getIndexByName = getByName;

const API_URL = getAPIUrl();

interface MetricQueryItem {
  id: string;
  name: string;
  metric: string;
  type: string;
}
export interface PriceHistorySlice {
  priceHistory: PriceHistory;
  initPriceHistory: (index: IndexItem | IndexItem, extras?: SelectorOption[]) => void;
  getPriceHistory: (query: SelectorOption[]) => Promise<{ priceHistory: PriceHistory }>;
}

const createPriceHistorySlice: StateCreator<
  PriceHistorySlice & IndexSlice & IndicesSlice & NotificationSlice,
  [],
  [],
  PriceHistorySlice
> = (set, get) => ({
  priceHistory: priceHistoryStore,

  initPriceHistory: async (index: IndexItem, extras = []) => {
    const priceHistory = get().priceHistory;
    const related = (index?.related || []).filter(
      s => s.id && s.defaultDisplay && !priceHistory.results[s.id]
    );

    const webSettings = getSettings(index.name);
    const limitRange = webSettings.indexLevels?.limitRange && index.launchDate;
    const minDate = webSettings.indexLevels?.minDate;
    const maxDateRange =
      webSettings.indexLevels?.maxDateRange &&
      dayjs().subtract(webSettings.indexLevels?.maxDateRange, 'years').format('YYYY-MM-DD');
    const startDate = limitRange || minDate || maxDateRange;

    const dateQuery1 = startDate ? `&start_date=${startDate}` : '';
    const dateQuery2 = startDate ? `?start_date=${startDate}` : '';

    const metric = index.plotMetric || DEFAULT_METRIC;
    const firstQuery = !priceHistory.results[index.id]
      ? [
          `${API_URL}/security/index?names=${index.name}&metrics=${metric}&format=records${dateQuery1}`,
        ]
      : [];

    // include query string options in the request list
    const extraItems: RelatedItem[] = extras
      .filter(
        q =>
          !(
            priceHistory.results[q.value] &&
            priceHistory.results[q.value][q.metric] &&
            priceHistory.results[q.value][q.metric].length
          )
      )
      .map(e => ({
        defaultDisplay: true,
        name: e.label,
        id: e.value,
        metric: e.metric,
        type: 'index',
      }));

    const extrasUrls = extraItems.map(
      q => `${API_URL}/security/index?names=${q.name}&metrics=${q.metric}&format=records`
    );

    // this query creates the manifest secID -> indexId
    //adding multiple names in the first url will break the code
    const urls = [
      ...firstQuery,
      ...related.map(item =>
        item.type === 'security'
          ? `${API_URL}/security/equity_legacy/${item.id}/metrics/${item.metric}/data${dateQuery2}`
          : `${API_URL}/index/${item.id}${dateQuery2}`
      ),
      ...extrasUrls,
    ];

    if (!urls.length) {
      return { priceHistory };
    }
    set({ priceHistory: { ...priceHistory, status: 'loading' } });
    const headers = await getHeaders('GET');
    const promises = urls.map(url => {
      return fetch(url, headers);
    });

    const errors: NotificationItemType[] = [];
    let contents: Array<{ results: SecItem[] }> = [];

    const pending = [...related, ...extraItems];

    try {
      const responses: any = await Promise.all(promises);
      contents = await Promise.all(
        responses
          .map(async (response: any, i: number) => {
            const id = i === 0 ? index.id : pending[i - 1].id;

            if (!response.ok) {
              if (response.status >= 400) {
                errors.push({
                  type: 'error',
                  message: `Couldn't load ${id} price history. (${response.statusText})`,
                });
                return { results: [] };
              }
            } else {
              const res = await response.json();
              if (res.results && Array.isArray(res.results)) {
                return res;
              } else {
                const indexResponse = await fetch(
                  `${API_URL}/security/index?names=${res.name}&metrics=${
                    pending[i - 1].metric
                  }&format=records${dateQuery1}`,
                  headers
                );
                if (!indexResponse.ok) {
                  if (indexResponse.status >= 400) {
                    errors.push({
                      type: 'error',
                      message: `Couldn't load ${res.name} price history. (${indexResponse.statusText})`,
                    });
                    return { results: [] };
                  }
                } else {
                  return indexResponse.json();
                }
              }
            }
          })
          .filter((c: any) => !!c)
      );
    } catch (err: any) {
      console.log('err', err.message, err.name);
      set({ priceHistory: { ...priceHistory, status: 'error' } });
    }

    const items = [index, ...pending];
    const history = items.reduce<PriceHistory>((hs, item, i) => {
      const parsed = parseResult(hs.results, contents[i], item.id);
      const secId = isSecAPIResponse(contents[i]) ? contents[i].results[0].id : item.id;

      return {
        results: parsed,
        status: 'done',
        manifest: {
          ...hs.manifest,
          [secId]: item.id,
        },
      };
    }, priceHistory);

    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      priceHistory: {
        ...priceHistory,
        ...history,
      },
    });
    return {
      priceHistory: {
        ...priceHistory,
        ...history,
      },
    };
  },

  getPriceHistory: async (query: SelectorOption[]) => {
    const priceHistory = get().priceHistory;
    const newQuery = query.filter(
      q =>
        !(
          priceHistory.results[q.value] &&
          priceHistory.results[q.value][q.metric] &&
          priceHistory.results[q.value][q.metric].length
        )
    );

    if (!newQuery.length) {
      return { priceHistory };
    }
    set({ priceHistory: { ...priceHistory, status: 'loading' } });

    const urls = newQuery.map(q =>
      q.type === 'security'
        ? `${API_URL}/security/equity_legacy/${q.value}/metrics/${q.metric}/data`
        : `${API_URL}/security/index?names=${q.label}&metrics=${q.metric}&format=records`
    );
    const headers = await getHeaders('GET');
    const promises = urls.map(url => {
      return fetch(url, headers);
    });

    const errors: NotificationItemType[] = [];
    let contents: Array<{ results: SecItem[] }> = [];
    try {
      const responses: any = await Promise.all(promises);
      contents = await Promise.all(
        responses
          .map(async (response: any, i: number) => {
            const id = newQuery[i].value;

            if (!response.ok) {
              if (response.status >= 400) {
                errors.push({
                  type: 'error',
                  message: `Couldn't load ${id} price history. (${response.statusText})`,
                });
                return { results: [] };
              }
            } else {
              const res = await response.json();
              return res;
            }
          })
          .filter((c: any) => !!c)
      );
    } catch (err: any) {
      console.log('err', err.message, err.name);
      set({ priceHistory: { ...priceHistory, status: 'error' } });
    }

    const history = newQuery.reduce<PriceHistory>((hs, item, i) => {
      const parsed = parseResult(hs.results, contents[i], item.value);
      const secId = isSecAPIResponse(contents[i]) ? contents[i].results[0].id : item.value;

      return {
        results: parsed,
        status: 'done',
        manifest: {
          ...hs.manifest,
          [secId]: item.value,
        },
      };
    }, priceHistory);

    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      priceHistory: {
        ...priceHistory,
        ...history,
      },
    });
    return {
      priceHistory: {
        ...priceHistory,
        ...history,
      },
    };
  },

  getMoreMetrics: async (queries: MetricQueryItem[]) => {
    const priceHistory = get().priceHistory;
    const newQueries = queries.filter(q => !priceHistory.results[q.id][q.metric]);
    if (!newQueries.length) {
      return { priceHistory };
    }

    const indexQueries = newQueries.filter(q => q.type === 'index');
    const hasIndexQueries = Boolean(indexQueries.length);
    const secQueries = newQueries.filter(q => q.type !== 'index');
    const indexNames = indexQueries.map(q => q.name).join(',');
    const indexMetrics = indexQueries.map(q => q.metric).join(',');
    const headers = await getHeaders('GET');
    const urls = [
      ...(hasIndexQueries
        ? [
            `${API_URL}/security/index?names=${indexNames}&metrics=${
              indexMetrics || DEFAULT_METRIC
            }&format=records`,
          ]
        : []),
      ...secQueries.map(
        query => `${API_URL}/security/equity_legacy/${query.id}/metrics/${query.metric}/data`
      ),
    ];
    set({ priceHistory: { ...priceHistory, status: 'loading' } });
    const promises = urls.map(url => {
      return fetch(url, headers);
    });

    const errors: NotificationItemType[] = [];
    let contents: Array<{ results: SecItem[] }> = [];
    try {
      const responses: any = await Promise.all(promises);
      contents = await Promise.all(
        responses
          .map(async (response: any) => {
            if (!response.ok) {
              if (response.status >= 400) {
                errors.push({
                  type: 'error',
                  message: `Couldn't load  price history. (${response.statusText})`,
                });
                return { results: [] };
              }
            } else {
              const res = await response.json();
              if (res.results && Array.isArray(res.results)) {
                return res;
              }
            }
          })
          .filter((c: any) => !!c)
      );
    } catch (err: any) {
      console.log('err', err.message, err.name);
      set({ priceHistory: { ...priceHistory, status: 'error' } });
    }
    const history = contents.reduce<PriceHistory>((hs, content, i) => {
      const manifest =
        i < 1 * Number(hasIndexQueries)
          ? hs.manifest
          : secQueries[i - 1 * Number(hasIndexQueries)].id;
      const parsed = parseResult(hs.results, content, manifest);

      return {
        results: parsed,
        status: 'done',
        manifest: hs.manifest,
      };
    }, priceHistory);

    set({
      // ...(errors.length ? NotificationActions.batch(state)(errors) : {}),
      priceHistory: history,
    });
  },
  getRelatedMetrics: async (relatedList: Related[]) => {
    const priceHistory = get().priceHistory;
    const indexPromises = relatedList.map(r => getIndexByName(get)(r.indexName || r.name));
    const indices = (await Promise.all(indexPromises)).map(res => res.index && res.index.selected);

    const getId = (related: Related) =>
      indices.find(i => i?.name === (related.indexName || related.name))?.id || related.id;

    const queryList = relatedList.filter(related => {
      const relatedId = getId(related);
      return !(priceHistory.results[relatedId] && priceHistory.results[relatedId][related.metric]);
    });
    if (!queryList.length) {
      return { priceHistory };
    }
    const headers = await getHeaders('GET');
    set({ priceHistory: { ...priceHistory, status: 'loading' } });
    const promises: Promise<any>[] = queryList.map(related => {
      const relatedId = getId(related);
      const url =
        related.type === 'security'
          ? `${API_URL}/security/equity_legacy/${related.id}/metrics/${
              related.metric || DEFAULT_METRIC
            }/data`
          : `${API_URL}/security/index?names=${related.indexName || relatedId}&metrics=${
              related.metric || DEFAULT_METRIC
            }&format=records`;

      return fetch(url, headers);
    });

    const errors: NotificationItemType[] = [];
    let contents: Array<{ results: SecItem[] }> = [];
    try {
      const responses: any = await Promise.all(promises);
      contents = await Promise.all(
        responses
          .map(async (response: any) => {
            if (!response.ok) {
              if (response.status >= 400) {
                errors.push({
                  type: 'error',
                  message: `Couldn't load  price history. (${response.statusText})`,
                });
                return { results: [] };
              }
            } else {
              const res = await response.json();
              if (res.results && Array.isArray(res.results)) {
                return res;
              }
            }
          })
          .filter((c: any) => !!c)
      );
    } catch (err: any) {
      console.log('err', err.message, err.name);
      set({ priceHistory: { ...priceHistory, status: 'error' } });
    }

    const history = contents.reduce((newState, content, idx) => {
      const related = queryList[idx];
      const relatedId = getId(related);
      const parsed = parseResult(newState.results, content, relatedId);
      const secId = isSecAPIResponse(content) ? content.results[0].id : relatedId;

      return {
        results: parsed,
        status: 'done' as 'done' | 'error',
        manifest: {
          ...newState.manifest,
          [secId]: relatedId,
        },
      };
    }, priceHistory || {});

    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      priceHistory: history,
    });
  },
});

export { createPriceHistorySlice };
export default createPriceHistorySlice;

const parseResult = (
  init: PriceHistoryMetric,
  content: SecAPIResult,
  item: string | Manifest
): PriceHistoryMetric => {
  if (!content || !content.results || !content.results.length) {
    if (typeof item === 'string') {
      return {
        ...init,
        [item]: {},
      };
    }
    return init;
  }
  if (!isSecAPIResponse(content)) {
    return parseDataApiResponse(
      init,
      content.results as unknown as DataApiResponse[],
      item as string
    );
  }

  return parseSecApiResponse(init, content, item);
};

const parseSecApiResponse = (
  init: PriceHistoryMetric,
  content: SecAPIResult,
  manifest: string | Manifest
): PriceHistoryMetric => {
  return content.results.reduce<PriceHistoryMetric>((res, item) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { eff_ts, effTs, id, name, ...rest } = item;
    const customId = typeof manifest === 'object' ? manifest[id] : manifest;
    const metrics = Object.keys(rest);
    const ts = eff_ts || effTs;

    const data = metrics.reduce<{
      [metricName: string]: number[][];
    }>(
      (acc, metric) => {
        const current = acc[metric] || [];

        return {
          ...acc,
          [metric]: [...current, [dayjs.utc(ts).valueOf(), Number(rest[metric])]],
        };
      },

      res[customId] || {}
    );
    return {
      ...res,
      [customId]: {
        ...(res[customId] || {}),
        ...data,
      },
    };
  }, init);
};

const parseDataApiResponse = (
  init: PriceHistoryMetric,
  content: DataApiResponse[],
  itemId: string
): PriceHistoryMetric => {
  return {
    ...init,
    [itemId]: content.reduce<PriceHistoryItem>((acc, row) => {
      return {
        ...acc,
        ...row.metrics.reduce<PriceHistoryItem>((tmp, metric) => {
          const res = tmp[metric.id] || [];
          const element = [...res, [dayjs(row.ts).valueOf(), Number(metric.value)]];
          return {
            ...tmp,
            [metric.id]: element,
          };
        }, acc),
      };
    }, init[itemId] || {}),
  };
  /*
[
  {id: "1993-01-29T00:00:00"
  metrics: [
    {id: 'close', value: 43.9375}
  ]
  ts: "1993-01-29T00:00:00"}
  ...
]
*/
  // [secid][metricname][number][number]
};

interface DataApiResponse {
  id: string;
  metrics: Array<{ id: string; value: number }>;
  ts: string;
}

const isSecAPIResponse = (content: any) =>
  content?.results?.length && (content.results[0].effTs || content.results[0].eff_ts);
