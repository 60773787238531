import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { StateCreator } from 'zustand';
import { firestore } from '../../settings/firebase';
import { ApprovalStatus, DistributionStatus, FlowStatus } from './store';

export interface IndexDistributionFlowSlice {
  value: FlowStatus[];
  get: (name: string) => Promise<void>;
  subscribe: (email: string) => {
    unSubscribe: () => void;
  };
  subscribeAsAdmin: () => {
    unSubscribe: () => void;
  };
  save: (
    name: string,
    requestedBy: string,
    approvedBy: string,
    status: ApprovalStatus
  ) => Promise<{ ok: boolean } | { error: string }>;
}

export const createIndexDistributionFlowSlice: StateCreator<
  IndexDistributionFlowSlice,
  [],
  [],
  IndexDistributionFlowSlice
> = (setState, getState) => ({
  value: [],
  // Get the distribution flow item by indexName
  get: async name => {
    const requestRef = doc(firestore, 'indexDistributionFlow', name);
    const requestDoc = (await getDoc(requestRef)).data() as FlowStatus;

    requestDoc && setState({ value: [requestDoc] });
  },
  // Subscribe to changes in the distribution flow (this will update the UI in real time)
  subscribeAsAdmin: () => {
    const requestsRef = collection(firestore, 'indexDistributionFlow');

    const unSubscribe = onSnapshot(requestsRef, snapshot => {
      const results: DistributionStatus = {};
      // const value = getState().value;

      snapshot.forEach(doc => {
        const data = doc.data() as FlowStatus;
        results[data.name] = data;
      });
      setState({ value: [...Object.values(results)] });
    });

    // To stop listening to changes when the component unmounts
    return {
      unSubscribe,
    };
  },
  // Subscribe to changes in the distribution flow (this will update the UI in real time)
  subscribe: (userName: string) => {
    const requestsRef = collection(firestore, 'indexDistributionFlow');
    const queryRequestSnapshot = query(requestsRef, where('requestedBy', '==', userName));
    const queryApproveSnapshot = query(requestsRef, where('approvedBy', '==', userName));

    const unsubscribeRequest = onSnapshot(queryRequestSnapshot, snapshot => {
      const results: DistributionStatus = {};
      const value = getState().value;

      snapshot.forEach(doc => {
        const data = doc.data() as FlowStatus;
        results[data.name] = data;
      });
      setState({ value: [...value, ...Object.values(results)] });
    });

    const unsubscribeApprove = onSnapshot(queryApproveSnapshot, snapshot => {
      const results: DistributionStatus = {};
      const value = getState().value;

      snapshot.forEach(doc => {
        const data = doc.data() as FlowStatus;
        results[data.name] = data;
      });
      setState({ value: [...value, ...Object.values(results)] });
    });

    const unSubscribe = () => {
      unsubscribeRequest();
      unsubscribeApprove();
    };

    // To stop listening to changes when the component unmounts
    return {
      unSubscribe,
    };
  },
  // Create a new distribution flow item or update an existing one
  save: async (name: string, requestedBy: string, approvedBy: string, status = 'pending') => {
    try {
      const itemRef = doc(firestore, 'indexDistributionFlow', name);
      const itemDoc = await getDoc(itemRef);
      if (itemDoc.exists()) {
        // If it exists, update the existing document
        await updateDoc(itemRef, {
          status,
          requestedBy,
          approvedBy,
          modified: new Date(),
        });
      } else {
        // If it doesn't exist, create a new document
        await setDoc(itemRef, {
          name,
          status,
          requestedBy,
          approvedBy,
          modified: new Date(),
        });
      }
    } catch (error: any) {
      return { error: 'There was an error submitting request: ' + error.code };
    }
    return { ok: true };
  },
});

export default createIndexDistributionFlowSlice;
