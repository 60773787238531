interface Props {
  h?: number;
}

const renderLoader = ({ h = 100 }: Props) => (
  <div
    style={{ height: `${h}px`, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  >
    Loading...
  </div>
);

export default renderLoader;
