import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const { MODE } = import.meta.env;

Sentry.init({
  dsn: 'https://a1680c16e7d848d499e56e1fb05eec19@o4506720375996416.ingest.us.sentry.io/5501957',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  beforeSend(event) {
    // Include full path and error message in transaction
    const details = event.extra?.details as any;
    const error =
      details && details.errors && details.errors.length
        ? details.errors[0]
        : { message: '', code: '' };
    event.tags = event.tags || {};
    if (error.code) {
      event.tags['code'] = event.tags['code'] || error.code;
    }
    const url = event.request?.url ?? '';
    const path = url.split('merqube.com')[1] ?? '';
    const message = error.message ? `: ${error.message}` : '';
    event.transaction = path ? `${path}${message}` : `${event.transaction}${message}`;

    return event;
  },
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  enabled: MODE !== 'development',
  environment: MODE,
  maxBreadcrumbs: 50,
  normalizeDepth: 7,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
