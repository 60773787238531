import { useAuth } from '@merq/web-ui/dist/providers/userProvider';
import { useEffect } from 'react';
import useStore from 'store';

/**
 * A React hook that provides permission checks for a given set of categories.
 * @returns An object with properties to check if a given page has read, edit or admin access.
 */
export const useOauthPermissions = () => {
  const { user } = useAuth();
  const [perms, getPermissions] = useStore(state => [state.permissions, state.getPermissions]);

  useEffect(() => {
    if (user && user.email) {
      getPermissions(user.email);
    }
  }, [user]);

  // TODO: dissconect firebase permissions and use only the ones from the backend
  return perms;
};
