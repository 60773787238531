import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { dataCollectionStore, DataCollectionItem, DataCollectionStore } from './store';
import { UUID } from 'store/types';
import { StateCreator } from 'zustand';
import { NotificationSlice, batchNotifications } from 'store/Notification/slice';

const API_URL = getAPIUrl();

type InitResponse = { [collectionName: string]: DataCollectionItem } | null;

interface DataItem {
  name: string;
  timestamp: string;
  [metric: string]: any;
}

type DataResponse = {
  results: DataItem[];
};

export interface DataCollectionSlice {
  dataCollection: DataCollectionStore;
  getDataCollection: (
    uuid: UUID
  ) => Promise<{ [collectionName: string]: DataCollectionItem } | undefined | null>;
  getDataCollectionData: (uuid: UUID, collectionName: string) => Promise<any[] | undefined>;
}

export const createDataCollectionSlice: StateCreator<
  DataCollectionSlice & NotificationSlice,
  [],
  [],
  DataCollectionSlice
> = (set, get) => ({
  dataCollection: dataCollectionStore,
  getDataCollection: async uuid => {
    const dataCollection = get().dataCollection;

    if (dataCollection[uuid]) {
      return dataCollection[uuid].collections;
    }

    const head = await getHeaders('GET');
    const newHeader = {
      method: head.method,
      headers: {
        Authorization: head.headers.Authorization || '',
      },
    };
    try {
      const { errors, content } = await responseHandler<InitResponse>(
        fetch(`${API_URL}/index/${uuid}/data_collections`, newHeader),
        err => `Couldn't load Data Collection. (${err})`,
        true
      );

      // TODO: ask backend to set {"webpage": {..., "show_data_collection": true}} for all data collections
      const non404Errors = errors.filter(e => e.code !== 404);
      set({
        ...(non404Errors.length ? batchNotifications(get)(non404Errors) : {}),
        dataCollection: {
          ...dataCollection,
          [uuid]: {
            collections: content,
            data: {},
          },
        },
      });
      return content;
    } catch (e) {
      return undefined;
      // {
      //   // ...(errors.length ? NotificationActions.batch(state)(errors) : {}),
      //   // stats,
      // };
    }
  },
  getDataCollectionData: async (uuid, collectionName) => {
    const dataCollection = get().dataCollection;

    if (
      dataCollection[uuid] &&
      dataCollection[uuid].data &&
      dataCollection[uuid].data[collectionName] &&
      dataCollection[uuid].data[collectionName].length
    ) {
      return dataCollection[uuid].data[collectionName];
    }

    const head = await getHeaders('GET');
    const newHeader = {
      method: head.method,
      headers: {
        Authorization: head.headers.Authorization || '',
      },
    };
    try {
      const { errors, content } = await responseHandler<DataResponse>(
        fetch(`${API_URL}/index/${uuid}/data_collections/data?name=${collectionName}`, newHeader),
        err => `Couldn't load Data Collection. (${err})`,
        true
      );

      set({
        ...(errors.length ? batchNotifications(get)(errors) : {}),
        dataCollection: {
          ...dataCollection,
          [uuid]: {
            ...dataCollection[uuid],
            data: {
              ...dataCollection[uuid].data,
              [collectionName]: content.results || [],
            },
          },
        },
      });

      return content.results;
    } catch (e) {
      return undefined;
      // return {
      //   // ...(errors.length ? NotificationActions.batch(state)(errors) : {}),
      //   // stats,
      // };
    }
  },
});

export default createDataCollectionSlice;
