// eslint-disable-next-line @typescript-eslint/no-var-requires
const { v5: uuidv5 } = require('uuid');
const { VITE_UUID_SEED } = import.meta.env;

const getSessionId = (seed?: string) => {
  return seed
    ? `cust-${uuidv5(seed, VITE_UUID_SEED)}`
    : `anon-${uuidv5(String(Number(new Date())), VITE_UUID_SEED)}`;
};

export default getSessionId;
