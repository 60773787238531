import { UUID } from '../Index/store';
export interface RealTimePoint {
  effTs: string;
  id: UUID;
  name: string;
  [metricName: string]: any;
}

export interface RealTimeHistory {
  [indexId: UUID]: {
    [metric: string]: RealTimePoint[];
  };
}

const realTimeStore: RealTimeHistory = {};

export { realTimeStore };
