import React, { FC, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Routes, useLocation } from 'react-router-dom';
import { useLoader } from 'components/Layout/LoadingPage';
import * as Sentry from '@sentry/react';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const RouteTransition: FC<React.PropsWithChildren> = ({ children }) => {
  const loader = useLoader();
  const { pathname } = useLocation();

  const isVisited = loader.isVisited(pathname);

  useEffect(() => {
    if (!isVisited) {
      loader.register(pathname);
    }
    //fallback in case the transition doesn't work
    setTimeout(() => setOpacityToOne('route-transition'), isVisited ? 450 : 1350);
  }, []);

  const pageMotion = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { delay: !isVisited ? 0.9 : 0, duration: 0.4 } },
    exit: { opacity: 0, transition: { duration: 0.2 } },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
      className="route-transition"
      style={{ height: '100%' }}
    >
      {children}
    </motion.div>
  );
};

export const AnimatedRoutes: FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <SentryRoutes location={location} key={location.pathname}>
        {children}
      </SentryRoutes>
    </AnimatePresence>
  );
};

function setOpacityToOne(className: string) {
  // Get all elements with the specified class name
  const elements = document.getElementsByClassName(className);
  // Loop through all elements and set their opacity to 1
  for (let i = 0; i < elements.length; i++) {
    (elements[i] as any).style.opacity = '1';
  }
}
