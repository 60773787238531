import ArrowForwardIosSharpIcon from '@mui/icons-material/ExpandMoreRounded';
import { MenuItem } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem as MenuItemDef, RootItem } from '../../hooks/useMenu';

const BG_COLOR = '#212421';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderTop: `1px solid #212421D0`,
  '&:first-child': {
    borderTop: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '30px', color: 'white' }} />}
    {...props}
  />
))(({ theme }) => ({
  height: '60px',
  backgroundColor: BG_COLOR,
  color: 'white',
  paddingRight: theme.spacing(1.6),
  borderBottom: 0,

  //   flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface Props {
  items: Array<RootItem | MenuItemDef>;
  onClose: () => void;
}

const CustomizedAccordions: React.FC<Props> = ({ items, onClose }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const theme = useTheme();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClick = (callback?: () => void) => () => {
    callback && callback();
    onClose();
  };

  return (
    <div>
      {items.map((item, index) =>
        (item as RootItem).items ? (
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            key={`panel${index}`}
          >
            <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
              <Typography fontSize={'24px'} letterSpacing="2.4px" fontWeight={600}>
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: BG_COLOR, paddingTop: 0, paddingLeft: 4 }}>
              {((item as RootItem).items || []).map(item => (
                <MenuItem
                  component={Link}
                  to={item.path}
                  onClick={onClose}
                  key={item.path}
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: '24px',
                    letterSpacing: '2.4px',
                    fontWeight: 600,
                  }}
                >
                  {item.title}
                </MenuItem>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : (
          <MenuItem
            key={`item${index}`}
            component={Link}
            to={(item as MenuItemDef).path}
            onClick={handleClick((item as MenuItemDef).onClick)}
            sx={{
              color: 'white',
              fontSize: '24px',
              letterSpacing: '2.4px',
              fontWeight: 600,
              paddingLeft: 4,
              height: '60px',
              borderTop: `1px solid #ffffff20`,
            }}
          >
            {item.title}
          </MenuItem>
        )
      )}
    </div>
  );
};

export default CustomizedAccordions;
