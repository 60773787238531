import { useOauthPermissions } from 'hooks/usePermissions';
import useStore from 'store';
import { useAuth } from '@merq/web-ui/dist/providers/userProvider';
import AccountIcon from '@mui/icons-material/AccountCircleRounded';
import { useTranslation } from 'react-i18next';

export interface RootItem {
  title: string;
  items: MenuItem[];
  icon?: React.ReactNode;
}

export interface MenuItem {
  title: string;
  path: string;
  target?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  id?: string;
}

const useMenu = (onLogout: () => void) => {
  const permissions = useOauthPermissions().permissions.website;
  const indexPages = useStore(state => state.indexPages.value);
  const { user } = useAuth();
  const isMerqUser = (user?.email || '').endsWith('@merqube.com');
  const { t } = useTranslation();
  const defaultMenu: RootItem[] = [
    {
      title: 'Indices',
      items: [{ title: t('navbar.indexFinder'), path: '/indices' }],
    },
    {
      title: t('navbar.about'),
      items: [
        { title: t('navbar.ourStory'), path: '/about/our-story' },
        { title: t('navbar.ourTeam'), path: '/about/our-team' },
        { title: t('navbar.careers'), path: '/about/careers' },
      ],
    },
    {
      title: t('navbar.governance'),
      items: [
        { title: t('navbar.announcements'), path: '/governance/announcements' },
        { title: t('navbar.consultations'), path: '/governance/consultations' },
        { title: t('navbar.policies'), path: '/governance/policies' },
        { title: t('navbar.supplementalData'), path: '/governance/supplemental-data' },
      ],
    },
    {
      title: 'Qubits',
      items: [
        { title: t('navbar.coverage'), path: '/news/coverage' },
        { title: t('navbar.pressReleases'), path: '/news/press' },
        { title: t('navbar.insights'), path: '/news/blog' },
      ],
    },
  ];

  const contactItem: MenuItem = {
    title: t('navbar.contact'),
    path: '/contact',
  };

  const mainMenu = defaultMenu.map(item => {
    if (item.title === 'Indices') {
      return {
        ...item,
        items: [
          ...item.items,
          ...indexPages
            .filter(
              (p, pos, self) => p.displayInMenu && self.findIndex(i => i.slug === p.slug) === pos
            )
            .map(page => ({
              title: page.title,
              path: `/indices/partners/${page.slug}`,
            })),
        ],
      };
    }
    return item;
  });

  const adminMenu: RootItem[] = isAdmin(permissions)
    ? [
        {
          title: 'Admin',
          items: [
            canView(permissions.adminNews?.actions)
              ? { title: 'News', path: '/admin/news/coverage' }
              : { title: '', path: '' },
            canView(permissions.adminDocuments?.actions)
              ? { title: 'Documents', path: '/admin/docs' }
              : { title: '', path: '' },
            canView(permissions.adminIndices?.actions)
              ? { title: 'Indices', path: '/admin/indices' }
              : { title: '', path: '' },
            canView(permissions.adminIndices?.actions)
              ? { title: 'Partner Indices', path: '/admin/landing-pages' }
              : { title: '', path: '' },
            canView(permissions.adminTeam?.actions)
              ? { title: 'Team', path: '/admin/team' }
              : { title: '', path: '' },
          ].filter(item => item.title),
        },
      ]
    : [];

  const supportMenu: RootItem[] = [
    {
      title: t('navbar.support'),
      items: [
        {
          title: t('navbar.supportCenter'),
          path: 'https://support.merqube.com/',
          target: '_blank',
        },
        {
          title: t('navbar.docsAndGuides'),
          path: '/support/documents',
        },
      ],
    },
  ];

  const userMenu: RootItem[] = [
    {
      title: t('navbar.myAccount'),
      icon: <AccountIcon sx={{ fontSize: '20px' }} />,
      items: [
        isMerqUser
          ? { title: t('navbar.myAccount'), path: '/account/profile' }
          : { title: '', path: '' },
        canView(permissions.userIndicesDashboard?.actions)
          ? { title: t('navbar.myIndices'), path: '/account/my-indices', id: 'my-indices' }
          : { title: '', path: '' },
        canView(permissions.apikeys?.actions)
          ? { title: t('navbar.apiKeys'), path: '/account/api-keys' }
          : { title: '', path: '' },
        { title: t('navbar.logout'), path: '', onClick: onLogout, id: 'logout' },
      ].filter(item => item.title),
    },
  ];

  return { mainMenu, adminMenu, userMenu, supportMenu, contactItem };
};

export default useMenu;

const canView = (actions: string[] = []) =>
  actions.includes('VIEW') || actions.includes('ADMIN') || actions.includes('EDIT');

const isAdmin = (website: any) => {
  const adminPages = ['adminNews', 'adminDocuments', 'adminIndex', 'adminTeam'];
  return adminPages.filter(page => canView(website[page]?.actions)).length > 0;
};
