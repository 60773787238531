import { collection, Firestore, getDocs, query, where } from 'firebase/firestore';
import { StateCreator } from 'zustand';
import { firestore } from '../../settings/firebase';
import { LandingPage } from './store';

const getPublicQuery = (firestore: Firestore) => {
  return query(
    collection(firestore, 'landingPages'),
    where('isPublished', '==', true),
    where('access.allowedDomains', '==', []),
    where('access.allowedEmails', '==', [])
  );
};

const getDomainQuery = (firestore: Firestore, domain: string) => {
  return query(
    collection(firestore, 'landingPages'),
    where('isPublished', '==', true),
    where('access.allowedDomains', 'array-contains', '@' + domain)
  );
};
const getEmailQuery = (firestore: Firestore, email: string) => {
  return query(
    collection(firestore, 'landingPages'),
    where('isPublished', '==', true),
    where('access.allowedEmails', 'array-contains', email)
  );
};

export interface IndexPageSlice {
  value: LandingPage[];
  get: (email: string) => Promise<LandingPage[]>;
}

export const createIndexPageSlice: StateCreator<IndexPageSlice, [], [], IndexPageSlice> = (
  setState,
  getState
) => ({
  value: [],
  // Get the distribution flow item by indexName

  // Subscribe to changes in the distribution flow (this will update the UI in real time)
  get: async (email: string, force = false) => {
    const value = getState().value;

    if (value.length && !force) {
      return value;
    }

    const domain = (email || '@').split('@')[1];

    const response = await Promise.all([
      getDocs(getPublicQuery(firestore)),
      domain ? getDocs(getDomainQuery(firestore, domain)) : Promise.resolve({ docs: [] } as any),
      email ? getDocs(getEmailQuery(firestore, email)) : Promise.resolve({ docs: [] } as any),
    ]);

    const pages = [...response[0].docs, ...response[1].docs, ...response[2].docs].map(doc =>
      doc.data()
    ) as LandingPage[];
    setState({ value: pages });
    return pages;
  },
  // Create a new distribution flow item or update an existing one
});

export default createIndexPageSlice;
