import './instrument'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { createRoot } from 'react-dom/client';
import App from './app';
import { ErrorBoundary } from './components/ErrorBoundary';
import { providerConfig } from './settings/firebase';
// WARNING: moving import Providers to top causes weird error because javascript...
// https://stackoverflow.com/questions/74542488/react-material-ui-createtheme-default-is-not-a-function
import Providers from '@merq/web-ui/dist/providers';
import './i18n';

const container = document.getElementById('main');
const root = createRoot(container);
root.render(
  <ErrorBoundary catchErrors={['ChunkLoadError']} pageReload={true}>
    <Providers
      firebaseConfig={providerConfig}
      themeProps={{ mode: 'light' }}
      dateAdapter={AdapterDayjs}
    >
      <App />
    </Providers>
  </ErrorBoundary>
);
