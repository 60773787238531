/* eslint-disable @typescript-eslint/no-explicit-any */
import humps from 'humps';
import { StateCreator, StoreApi } from 'zustand';
import { NotificationItemType } from './Notification/slice';
import { MerqResponse } from './types';
import { v4 } from 'uuid';
import { refreshToken } from 'settings/firebase';

const getAPIUrl = () => {
  const { VITE_STAGING_API_URL, VITE_DEV_API_URL, VITE_API_URL } = import.meta.env;

  if (window.location.hostname === 'dev.merqube.com') {
    return VITE_DEV_API_URL || VITE_API_URL;
  } else if (window.location.hostname === 'staging.merqube.com') {
    return VITE_STAGING_API_URL || VITE_API_URL;
  } else {
    return VITE_API_URL;
  }
};

type Headers = {
  accept?: string;
  'Content-Type'?: string;
  Authorization?: string;
  'X-Request-ID'?: string;
};
export type MethodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

const getHeaders = async (method: MethodType) => {
  let token: string | undefined = '';
  let expiry = 0;
  try {
    token = localStorage.getItem('auth') || '';
    expiry = Number(localStorage.getItem('authExpiry') || 0);
  } catch (error) {
    console.log('localStorage error');
  }
  const now = new Date().getTime();
  if (expiry < now) {
    token = await refreshToken();
  }

  const userType = token ? 'auth' : 'anon';

  const sessionId = `web-${userType}-${v4()}`;

  const sessionHeader = { 'X-Request-ID': sessionId };

  const type = method === 'GET' ? 'accept' : 'Content-Type';
  return {
    method,
    headers: {
      [type]: 'application/json',
      ...sessionHeader,
      ...(token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {}),
    } as Headers,
  };
};

const responseHandler = async <T>(
  promise: Promise<Response>,
  formatError: (msg: string) => string,
  raw?: boolean | string[]
): Promise<MerqResponse<T>> => {
  const errors: NotificationItemType[] = [];
  let content: T = null as unknown as T;
  let code = 0;
  try {
    const response = await promise;
    code = response.status;
    if (!response.ok) {
      if (response.status >= 500) {
        const errorMessage = response.statusText || (await response.json()).error;
        errors.push({
          type: 'error',
          message: formatError(errorMessage),
          code: response.status,
        });
      } else if (response.status >= 400) {
        const content = await response.json();
        errors.push({
          type: 'error',
          code: response.status,
          message: formatError(content.error),
        });
      }
    } else {
      const jsonResponse = await response.json();
      if (Array.isArray(raw)) {
        content = customParser(jsonResponse, raw) as any;
      } else if (raw) {
        content = jsonResponse;
      } else {
        content = humps.camelizeKeys(jsonResponse) as any;
      }
    }
  } catch (err: any) {
    console.log('err', err.message, err.name);
  }
  return { errors, content, code, status: errors.length ? 'error' : 'success' };
};

export { getAPIUrl, getHeaders, responseHandler };

export type JSONResponse<T> = {
  data?: T;
  errors?: Array<{ message: string }>;
};

const customParser = (response: any, preserveKeys: string[]) => {
  const { results } =
    response.results && Array.isArray(response.results) ? response : { results: [response] };
  return {
    results: results.map((result: any) => {
      const keys = Object.keys(result);
      return keys.reduce((acc, key) => {
        const newKey = preserveKeys.includes(key) ? key : humps.camelize(key);
        const newValue = preserveKeys.includes(key) ? result[key] : humps.camelizeKeys(result[key]);
        return {
          ...acc,
          [newKey]: newValue,
        };
      }, {});
    }),
  };
};

function namespace<K extends string, T>(
  key: K,
  creator: StateCreator<T>
): (liftedSet: any, liftedGet: any, liftedApi: any) => T {
  return (liftedSet: any, liftedGet: any, liftedApi: any) => {
    const setState: StoreApi<T>['setState'] = (updates: any, replace?: boolean) => {
      liftedSet((liftedState: any) => {
        if (typeof updates === 'function') {
          updates = updates(liftedState[key]);
        }
        if (!replace) {
          updates = { ...liftedState[key], ...updates };
        }
        return {
          ...liftedState,
          [key]: updates,
        };
      }, replace);
    };
    const getState: StoreApi<T>['getState'] = () => {
      return liftedGet()[key];
    };
    const getInitialState: StoreApi<T>['getInitialState'] = () => {
      return liftedApi.getInitialState()[key];
    };
    const subscribe = ((listener: any, selector: any, equalityFn?: any) => {
      if (selector) {
        return liftedApi.subscribe(listener, (state: any) => selector(state[key]), equalityFn);
      } else {
        return liftedApi.subscribe(listener, (state: any) => state[key]);
      }
    }) as Subscribe<T>;
    const destroy = () => {
      // todo?
      // - remove state slice
      // - unsubscribe listeners
    };
    const api = { getInitialState, getState, setState, subscribe, destroy };
    return creator(setState, getState, api);
  };
}

export { namespace };

type Subscribe<T> = (listener: (state: T, prevState: T) => void) => () => void;
