import React from 'react';

const PageHasBeenForceRefreshed = 'page-has-been-force-refreshed';

const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(PageHasBeenForceRefreshed) || 'false'
  ) as boolean;

  if (!pageHasAlreadyBeenForceRefreshed) {
    window.localStorage.setItem(PageHasBeenForceRefreshed, 'true');
    return window.location.reload();
  } else {
    window.localStorage.setItem(PageHasBeenForceRefreshed, 'false');
  }
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
  pageReload?: boolean;
  errorPage?: React.ReactNode;
  catchErrors?: string[];
}
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: Readonly<ErrorBoundaryProps>) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error: any) {
    const { catchErrors, pageReload } = this.props;
    if (pageReload && (!catchErrors || !catchErrors.length || catchErrors.includes(error.name))) {
      retryPageLoading();
    }
    this.setState({ hasError: true });
  }
  render() {
    const { errorPage } = this.props;
    if (this.state.hasError) {
      return errorPage || <div />;
    }
    return this.props.children;
  }
}
