import { SubStore } from '../types';

export interface IndexOption {
  label: string;
  value: string;
  title: string;
}

export interface IndexQuery {
  query: string;
  results: IndexOption[];
}

export interface IndexOptionStore extends SubStore {
  cached: IndexQuery[];
  stage: 'all' | 'dev' | 'prod';
  status: 'init' | 'loading' | 'success' | 'error';
}

const indexOptionStore: IndexOptionStore = {
  cached: [],
  stage: 'prod',
  status: 'init',
  code: 0,
};

export { indexOptionStore };
