import { UUID } from '../types';
import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { DEFAULT_METRIC, IndexItem } from '../Index/store';
import { StatsStore, statsStore } from './store';
import { StateCreator } from 'zustand';
import { NotificationSlice, batchNotifications } from 'store/Notification/slice';

export interface IBenchmark {
  id: string;
  name: string;
  metric: string;
}

interface QueryItem {
  value: UUID;
  metric: string;
  label: string;
  title?: string;
  type: string;
}

const API_URL = getAPIUrl();

export interface StatsSlice {
  // stats vary by date and metric
  stats: StatsStore;
  getStats: (index: IndexItem, queryMetric?: string, isMain?: boolean) => void;
  getStatsList: (items: QueryItem[]) => void;
}

export const createStatsSlice: StateCreator<StatsSlice & NotificationSlice, [], [], StatsSlice> = (
  set,
  get
) => ({
  stats: statsStore,
  getStats: async (index: IndexItem, queryMetric?: string, isMain = true) => {
    const metric = queryMetric || index.plotMetric || DEFAULT_METRIC;
    const stats = get().stats;
    let { startDate, endDate } = !isMain ? stats : { startDate: '', endDate: '' };

    const statsUrl = `${API_URL}/index`;
    const mainUrl =
      statsUrl +
      `/${index.id}/stats?metric=${metric}` +
      (startDate && endDate ? `&start_date=${startDate}&end_date=${endDate}` : '');

    const headers = await getHeaders('GET');
    const { content: mainResult, errors } = await responseHandler<any>(
      fetch(mainUrl, headers),
      err => `Couldn't load Stats for ${index.name} (${err})`
    );

    const content = mainResult
      ? mainResult.results.find((r: any) => r.id.toLowerCase() === 'all')
      : null;
    const related = isMain ? (index.related || []).filter(d => d.defaultDisplay && d.id) : [];

    startDate = isMain && content ? content.startDate : startDate;
    endDate = isMain && content ? content.endDate : endDate;

    const range = startDate && endDate ? `&start_date=${startDate}&end_date=${endDate}` : '';
    const relatedUrls = related.map(r => {
      const relatedMetric = r.metric || metric || DEFAULT_METRIC;
      const url = `${API_URL}/${r.type === 'security' ? 'security/equity_legacy' : 'index'}/${
        r.id
      }/stats?metric=${relatedMetric}${range}`;

      return url;
    });

    // let relatedResults: any[] = [];
    let securityStats = { stats: {}, errors: [] };
    try {
      const results: any[] = await Promise.all(
        relatedUrls.map((url, idx) =>
          responseHandler<any>(
            fetch(url, headers),
            err => `Couldn't load Stats for ${related[idx].name} (${err})`
          )
        )
      );
      securityStats = results.reduce(
        (secStats, { content, errors }, idx) => {
          const current = related[idx];
          return {
            errors: [...secStats.errors, ...(errors || [])],
            stats: {
              ...secStats.stats,
              [current.id]: {
                ...(secStats.stats[current.id] || {}),
                [current.metric || DEFAULT_METRIC]: {
                  asOfDate: mainResult.asofdate,
                  range:
                    (content?.results || []).reduce(
                      (acc: any, r: any) => ({ ...acc, [r.id]: r }),
                      {}
                    ) || null,
                },
              },
            },
          };
        },
        {
          stats: {
            [index.id]: {
              ...(stats[index.id] || {}),
              [metric]: {
                asOfDate: mainResult.asofdate,
                range: mainResult.results.reduce((acc: any, r: any) => ({ ...acc, [r.id]: r }), {}),
              },
            },
          },
          errors: [],
        }
      );
    } catch (e) {
      return set({
        ...(errors.length ? batchNotifications(get)(errors) : {}),
      });
    }
    const allErrors = [...errors, ...securityStats.errors];

    set({
      ...(allErrors.length ? batchNotifications(get)(allErrors) : {}),
      stats: {
        ...stats,
        startDate,
        endDate,
        ...securityStats.stats,
      },
    });
  },

  getStatsList: async (items: QueryItem[]) => {
    const stats = get().stats;
    const queries = items.filter(item => !(stats[item.value] && stats[item.value][item.metric]));
    const { startDate, endDate } = stats;

    if (!queries.length) {
      return { stats };
    }

    const range = startDate && endDate ? `&start_date=${startDate}&end_date=${endDate}` : '';
    const urls = queries.map(r => {
      const relatedMetric = r.metric || DEFAULT_METRIC;
      const url = `${API_URL}/${r.type === 'security' ? 'security/equity_legacy' : 'index'}/${
        r.value
      }/stats?metric=${relatedMetric}${range}`;

      return url;
    });

    // let relatedResults: any[] = [];
    let securityStats = { stats: {}, errors: [] };
    try {
      const headers = await getHeaders('GET');
      const results: any[] = await Promise.all(
        urls.map((url, idx) =>
          responseHandler<any>(
            fetch(url, headers),
            err => `Couldn't load Stats for ${queries[idx].label} (${err})`
          )
        )
      );

      securityStats = results.reduce(
        (secStats, { content, errors }, idx) => {
          const current = queries[idx];
          return {
            errors: [...secStats.errors, ...(errors || [])],
            stats: {
              ...secStats.stats,
              [current.value]: {
                ...(secStats.stats[current.value] || {}),
                [current.metric || DEFAULT_METRIC]: {
                  asOfDate: content.asofdate,
                  range:
                    content.results.reduce((acc: any, r: any) => ({ ...acc, [r.id]: r }), {}) ||
                    null,
                },
              },
            },
          };
        },
        {
          stats,
          errors: [],
        }
      );
    } catch (e) {
      return {
        stats,
      };
    }
    const allErrors = [...securityStats.errors];

    set({
      ...(allErrors.length ? batchNotifications(get)(allErrors) : {}),
      stats: {
        startDate,
        endDate,
        ...securityStats.stats,
      },
    });
  },
});

export default createStatsSlice;
