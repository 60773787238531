export interface PortfolioItem {
  dates: string[];
  [key: string]: any;
}

export interface PortfolioStore {
  [indexName: string]: PortfolioItem;
}

const portfolioStore: PortfolioStore = {};

export { portfolioStore };
