import AuthRoute from 'components/AnimatedRouter/AuthRoute';
import { useOauthPermissions } from 'hooks/usePermissions';
import React, { Suspense, lazy } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { pageAction, pageName } from 'store/Permission/store';
import { AnimatedRoutes, RouteTransition } from 'components/AnimatedRouter/RouteTransition';
import Loading from '@merq/web-ui/dist/components/Loading';
import Layout from 'components/Layout';
import { adminRoutes, authRoutes, routes } from './routes';
import { useAuth } from '@merq/web-ui/dist/providers/userProvider';
import './styles/global.scss';
import LoaderProvider from 'components/Layout/LoadingPage';
import * as Sentry from '@sentry/react';
const NotFound = lazy(() => import('./pages/NotFound'));

const App = () => {
  const auth = useAuth();

  const email = auth?.user?.email || '';
  const fullName = auth?.user?.displayName || auth?.user?.firstName + ' ' + auth?.user?.lastName;

  Sentry.setUser({
    fullName,
    email,
  });

  return (
    <LoaderProvider>
      <Router>
        <Layout>
          <Suspense fallback={<Loading active={true} height={500} />}>
            <AnimatedRoutes>
              {Object.values(authRoutes).map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <RouteTransition>
                      <AuthRoute auth={auth} authLevel={route.level}>
                        <route.component />
                      </AuthRoute>
                    </RouteTransition>
                  }
                />
              ))}
              {Object.values(adminRoutes).map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <RouteTransition>
                      <AdminOrNotFound namespace={route.namespace} level={route.level}>
                        <route.component />
                      </AdminOrNotFound>
                    </RouteTransition>
                  }
                />
              ))}
              {Object.values(routes).map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <RouteTransition>
                      <route.component />
                    </RouteTransition>
                  }
                />
              ))}
            </AnimatedRoutes>
          </Suspense>
        </Layout>
      </Router>
    </LoaderProvider>
  );
};

export default App;

const AdminOrNotFound: React.FC<
  React.PropsWithChildren<{
    namespace: pageName;
    level?: pageAction;
  }>
> = ({ children, namespace, level = 'VIEW' }) => {
  const permissions = useOauthPermissions().permissions.website;
  const authorized =
    permissions &&
    namespace &&
    permissions[namespace] &&
    (permissions[namespace].actions.includes(level) ||
      permissions[namespace].actions.includes('ADMIN'));

  return authorized ? <>{children}</> : <NotFound />;
};
