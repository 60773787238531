import { MerqUser, useFirebase } from '@merq/web-ui/dist/providers/firebaseProvider';
import { useAuth } from '@merq/web-ui/dist/providers/userProvider';
import { signInWithRedirect, signInWithPopup, getAdditionalUserInfo } from 'firebase/auth';
import useStore from 'store';

const { VITE_SAML_ID, VITE_ENV } = import.meta.env;

import { SAMLAuthProvider } from 'firebase/auth';
const provider = new SAMLAuthProvider(VITE_SAML_ID);

const useSalesforceLogin = () => {
  const { setUser } = useAuth();
  const { auth, syncUserProfile } = useFirebase();

  const getIndices = useStore(state => state.getIndices);

  const onLogin = async (event?: any) => {
    event && event.preventDefault();

    try {
      if (VITE_ENV === 'development') {
        // redirect doesnt work on localhost
        const result = await signInWithPopup(auth, provider);
        const userInfo = result ? parseUserInfo(getAdditionalUserInfo(result)) : {};
        const user = await syncUserProfile(result.user, userInfo);

        if (user) {
          setUser({ user, status: 'authenticated' });
        }
        await getIndices('', true);
      } else {
        await signInWithRedirect(auth, provider);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { onLogin };
};

export default useSalesforceLogin;

const getFullName = ({ firstName, middleName, lastName, suffix }: any) =>
  [firstName, middleName, lastName, suffix].filter(Boolean).join(' ');

const parseUserInfo = (userInfo: any): Partial<MerqUser> => {
  const { profile } = userInfo || {};

  return {
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    displayName: getFullName(profile),
    email: profile?.email,
    company: profile?.companyName,
    salesforceId: profile?.userId,
    title: profile?.title,
  };
};
