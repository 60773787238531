import { MerqUser } from '@merq/web-ui/dist/providers/firebaseProvider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import profile from 'images/icons/profile.svg';
import { useTheme } from '@mui/material/styles';

interface Props {
  user: MerqUser;
}

const ProfileBox: React.FC<Props> = ({ user }) => {
  const theme = useTheme();
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.background.default
      : theme.palette.background.paper;
  const hasData = user.displayName || user.firstName || user.lastName || user.title || user.company;
  const displayName = user.displayName || [user.firstName, user.lastName].filter(Boolean).join(' ');

  return (
    <Box width="260px" sx={{ py: 1.25, px: 1.5 }}>
      <img
        src={profile}
        alt=""
        style={{
          width: '150px',
          height: '150px',
          borderRadius: '50%',
          paddingBottom: '0px',
          // TODO: remove opacity if the image is available
          opacity: theme.palette.mode === 'light' ? 1 : 0.5,
        }}
      />
      {hasData && (
        <Box sx={{ borderLeft: '7px solid #7aff8a', backgroundColor }} pl={1} mt={1}>
          {displayName && <Typography color="textPrimary">{displayName}</Typography>}
          {user.title && (
            <Typography
              color="textPrimary"
              sx={{
                fontSize: '12px',
                lineHeight: '14px',
                textTransform: 'uppercase',
                fontWeight: 700,
                opacity: 0.5,
                letterSpacing: 2,
              }}
            >
              {user.title}
            </Typography>
          )}
          {user.company && (
            <Typography
              color="textPrimary"
              sx={{
                fontSize: '12px',
                lineHeight: '14px',
                textTransform: 'uppercase',
                fontWeight: 700,
                opacity: 0.5,
                letterSpacing: 2,
              }}
            >
              {user.company}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProfileBox;
