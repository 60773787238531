import { StateCreator } from 'zustand';
import { NotificationType } from './store';
import { Notification } from './store';
import { GetterType } from 'store/types';

export interface NotificationItemType {
  type: NotificationType;
  message: string;
  code?: number;
}

export interface NotificationSlice {
  notifications: Notification[];
  pushNotification: (type: NotificationType, message: string) => void;
  batchNotifications: (items: NotificationItemType[]) => void;
  closeNotification: (id: number) => void;
  deleteNotification: (id: number) => void;
}

export const pushNotification =
  (get: GetterType<NotificationSlice>) => (type: NotificationType, message: string) => {
    const notifications = get().notifications;
    return {
      notifications: [
        ...notifications,
        {
          type,
          message,
          open: true,
          id: Math.floor(Math.random() * 1000000000),
        },
      ],
    };
  };

export const batchNotifications =
  (get: GetterType<NotificationSlice>) => (items: NotificationItemType[]) => {
    const notifications = get().notifications;
    return {
      notifications: [
        ...notifications,
        ...items.map(item => ({
          ...item,
          open: true,
          id: Math.floor(Math.random() * 1000000000),
        })),
      ],
    };
  };

export const createNotificationSlice: StateCreator<NotificationSlice, [], [], NotificationSlice> = (
  set,
  get
) => ({
  notifications: [],

  pushNotification: (...a) => set(pushNotification(get)(...a)),
  batchNotifications: (...a) => set(batchNotifications(get)(...a)),
  closeNotification: id => {
    const notifications = get().notifications;
    set({
      notifications: notifications.map(n => ({
        ...n,
        open: n.id !== id && n.open,
      })),
    });
  },
  deleteNotification: id => {
    const notifications = get().notifications;
    set({
      notifications: notifications.filter(n => n.id !== id),
    });
  },
});

export default createNotificationSlice;
