const options = {
  // SPY
  /*'LY6WH5-S': {
    US: {
      WEEKLY: [],
      WEEKLY_NON_FRIDAY: [
        {
          min_base_date: '2022-12-01',
          max_holding_period: 2,
          rebal_month: null,
        },
      ],
      WEEKLY_FRIDAY: [
        {
          min_base_date: '2010-06-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2012-12-01',
          max_holding_period: 4,
          rebal_month: null,
        },
        {
          min_base_date: '2014-03-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 2,
          rebal_month: null,
        },
        {
          min_base_date: '2010-12-01',
          max_holding_period: 3,
          rebal_month: null,
        },
        {
          min_base_date: '2020-09-01',
          max_holding_period: 4,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 9,
          rebal_month: 3,
        },
        {
          min_base_date: '2012-06-01',
          max_holding_period: 12,
          rebal_month: 3,
        },
        {
          min_base_date: '2014-12-01',
          max_holding_period: 18,
          rebal_month: 3,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 24,
          rebal_month: 12,
        },
      ],
    },
  },
  // QQQ
  'FXJMQV-S': {
    US: {
      WEEKLY: [],
      WEEKLY_NON_FRIDAY: [
        {
          min_base_date: '2022-12-01',
          max_holding_period: 2,
          rebal_month: null,
        },
      ],
      WEEKLY_FRIDAY: [
        {
          min_base_date: '2010-06-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2012-12-01',
          max_holding_period: 4,
          rebal_month: null,
        },
        {
          min_base_date: '2014-03-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 2,
          rebal_month: null,
        },
        {
          min_base_date: '2010-12-01',
          max_holding_period: 3,
          rebal_month: null,
        },
        {
          min_base_date: '2020-09-01',
          max_holding_period: 4,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 6,
          rebal_month: 3,
        },
        {
          min_base_date: '2010-12-01',
          max_holding_period: 9,
          rebal_month: 3,
        },
        {
          min_base_date: '2020-03-01',
          max_holding_period: 12,
          rebal_month: 3,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2012-01-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },
  // MSFT
  'DF3K29-S': {
    US: {
      WEEKLY_NON_FRIDAY: [],
      WEEKLY_FRIDAY: [],
      WEEKLY: [
        {
          min_base_date: '2010-06-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2013-12-01',
          max_holding_period: 5,
          rebal_month: null,
        },
        {
          min_base_date: '2014-03-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2020-09-01',
          max_holding_period: 3,
          rebal_month: null,
        },
        {
          min_base_date: '2021-06-01',
          max_holding_period: 4,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 6,
          rebal_month: 1,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },
  // AAPL
  'R85KLC-S': {
    US: {
      WEEKLY_NON_FRIDAY: [],
      WEEKLY_FRIDAY: [],
      WEEKLY: [
        {
          min_base_date: '2010-06-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2013-12-01',
          max_holding_period: 5,
          rebal_month: null,
        },
        {
          min_base_date: '2014-03-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2010-12-01',
          max_holding_period: 3,
          rebal_month: null,
        },
        {
          min_base_date: '2021-06-01',
          max_holding_period: 4,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 1,
          rebal_month: 3,
        },
        {
          min_base_date: '2018-06-01',
          max_holding_period: 4,
          rebal_month: 3,
        },
        {
          min_base_date: '2021-06-01',
          max_holding_period: 12,
          rebal_month: 3,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2006-12-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },
  // GE
  'P321KV-S': {
    US: {
      WEEKLY_NON_FRIDAY: [],
      WEEKLY_FRIDAY: [],
      WEEKLY: [
        {
          min_base_date: '2010-06-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2013-12-01',
          max_holding_period: 5,
          rebal_month: null,
        },
        {
          min_base_date: '2014-03-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2010-12-01',
          max_holding_period: 2,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 6,
          rebal_month: 3,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2006-12-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },
  // TSLA
  'WWDPYB-S': {
    US: {
      WEEKLY_NON_FRIDAY: [],
      WEEKLY_FRIDAY: [],
      WEEKLY: [
        {
          min_base_date: '2013-05-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2013-12-01',
          max_holding_period: 5,
          rebal_month: null,
        },
        {
          min_base_date: '2014-03-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2010-07-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2017-12-01',
          max_holding_period: 3,
          rebal_month: null,
        },
        {
          min_base_date: '2021-06-01',
          max_holding_period: 4,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2010-12-01',
          max_holding_period: 6,
          rebal_month: 3,
        },
        {
          min_base_date: '2020-03-01',
          max_holding_period: 9,
          rebal_month: 3,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2012-01-01',
          max_holding_period: 12,
          rebal_month: 1,
        },
        {
          min_base_date: '2013-01-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },
  // IWM
  'JMK312-S': {
    US: {
      WEEKLY_NON_FRIDAY: [],
      WEEKLY: [],
      WEEKLY_FRIDAY: [
        {
          min_base_date: '2010-06-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2012-12-01',
          max_holding_period: 4,
          rebal_month: null,
        },
        {
          min_base_date: '2014-03-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 2,
          rebal_month: null,
        },
        {
          min_base_date: '2010-12-01',
          max_holding_period: 3,
          rebal_month: null,
        },
        {
          min_base_date: '2020-09-01',
          max_holding_period: 4,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2015-09-01',
          max_holding_period: 6,
          rebal_month: 3,
        },
        {
          min_base_date: '2019-06-01',
          max_holding_period: 12,
          rebal_month: 3,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 12,
          rebal_month: 1,
        },
        {
          min_base_date: '2007-12-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },
  // NVDA
  'QDYJZC-S': {
    US: {
      WEEKLY_NON_FRIDAY: [],
      WEEKLY_FRIDAY: [],
      WEEKLY: [
        {
          min_base_date: '2010-06-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2013-12-01',
          max_holding_period: 5,
          rebal_month: null,
        },
        {
          min_base_date: '2014-03-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2013-04-01',
          max_holding_period: 2,
          rebal_month: null,
        },
        {
          min_base_date: '2021-05-01',
          max_holding_period: 4,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 6,
          rebal_month: 3,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2006-12-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },
  // AMZN
  'RMCVZ9-S': {
    US: {
      WEEKLY_NON_FRIDAY: [],
      WEEKLY_FRIDAY: [],
      WEEKLY: [
        {
          min_base_date: '2010-06-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2013-12-01',
          max_holding_period: 4,
          rebal_month: null,
        },
        {
          min_base_date: '2014-03-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2013-04-01',
          max_holding_period: 2,
          rebal_month: null,
        },
        {
          min_base_date: '2018-06-01',
          max_holding_period: 3,
          rebal_month: null,
        },
        {
          min_base_date: '2023-06-01',
          max_holding_period: 4,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 6,
          rebal_month: 1,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2006-12-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },
  // META
  'BHM9TN-S': {
    US: {
      WEEKLY_NON_FRIDAY: [],
      WEEKLY_FRIDAY: [],
      WEEKLY: [
        {
          min_base_date: '2012-06-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2013-12-01',
          max_holding_period: 4,
          rebal_month: null,
        },
        {
          min_base_date: '2014-03-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2012-06-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2013-03-01',
          max_holding_period: 2,
          rebal_month: null,
        },
        {
          min_base_date: '2023-06-01',
          max_holding_period: 4,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2012-06-01',
          max_holding_period: 6,
          rebal_month: 3,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2013-01-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },
  // GOOG and GOOGL
  'XF9TK6-S': {
    US: {
      WEEKLY_NON_FRIDAY: [],
      WEEKLY_FRIDAY: [],
      WEEKLY: [
        {
          min_base_date: '2010-09-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2013-12-01',
          max_holding_period: 4,
          rebal_month: null,
        },
        {
          min_base_date: '2014-03-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2013-03-01',
          max_holding_period: 2,
          rebal_month: null,
        },
        {
          min_base_date: '2020-08-01',
          max_holding_period: 3,
          rebal_month: null,
        },
        {
          min_base_date: '2023-09-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 6,
          rebal_month: 3,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2014-01-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },
  // TLT
  'MJ7Q9M-S': {
    US: {
      WEEKLY_NON_FRIDAY: [],
      WEEKLY_FRIDAY: [],
      WEEKLY: [
        {
          min_base_date: '2010-09-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2013-12-01',
          max_holding_period: 4,
          rebal_month: null,
        },
        {
          min_base_date: '2014-03-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 2,
          rebal_month: null,
        },
        {
          min_base_date: '2011-06-01',
          max_holding_period: 2,
          rebal_month: null,
        },
        {
          min_base_date: '2018-12-01',
          max_holding_period: 3,
          rebal_month: null,
        },
      ],
      MONTHS: [
        {
          min_base_date: '2021-01-01',
          max_holding_period: 4,
        },
        {
          min_base_date: '2022-03-01',
          max_holding_period: 9,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 6,
          rebal_month: 3,
        },
        {
          min_base_date: '2022-12-01',
          max_holding_period: 9,
          rebal_month: 3,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },
  // LQD
  'CX2L7X-S': {
    US: {
      WEEKLY_NON_FRIDAY: [],
      WEEKLY_FRIDAY: [],
      WEEKLY: [
        {
          min_base_date: '2020-02-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 2,
          rebal_month: null,
        },
        {
          min_base_date: '2017-09-01',
          max_holding_period: 2,
          rebal_month: null,
        },
        {
          min_base_date: '2022-09-01',
          max_holding_period: 9,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2006-06-01',
          max_holding_period: 6,
          rebal_month: 3,
        },
        {
          min_base_date: '2021-12-01',
          max_holding_period: 9,
          rebal_month: 3,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2017-01-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },
  // GLD
  'XD66JQ-S': {
    US: {
      WEEKLY_NON_FRIDAY: [],
      WEEKLY_FRIDAY: [],
      WEEKLY: [
        {
          min_base_date: '2010-07-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2010-12-01',
          max_holding_period: 4,
          rebal_month: null,
        },
        {
          min_base_date: '2014-03-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2008-06-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 6,
          rebal_month: 3,
        },
        {
          min_base_date: '2022-12-01',
          max_holding_period: 9,
          rebal_month: 3,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2009-01-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },*/
  // COIN
  'CV4R5T-S': {
    US: {
      WEEKLY_NON_FRIDAY: [],
      WEEKLY_FRIDAY: [],
      WEEKLY: [
        {
          min_base_date: '2021-05-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2021-05-01',
          max_holding_period: 2,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2021-04-01',
          max_holding_period: 6,
          rebal_month: 3,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2021-05-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },
  // MSTR
  'B700XP-S': {
    US: {
      WEEKLY_NON_FRIDAY: [],
      WEEKLY_FRIDAY: [],
      WEEKLY: [
        {
          min_base_date: '2021-04-01',
          max_holding_period: 6,
          rebal_month: null,
        },
      ],
      MONTHLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 1,
          rebal_month: null,
        },
        {
          min_base_date: '2023-11-01',
          max_holding_period: 2,
          rebal_month: null,
        },
      ],
      QUARTERLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 6,
          rebal_month: 1,
        },
      ],
      ANNUALLY: [
        {
          min_base_date: '2006-01-01',
          max_holding_period: 6,
          rebal_month: 1,
        },
        {
          min_base_date: '2007-01-01',
          max_holding_period: 24,
          rebal_month: 1,
        },
      ],
    },
  },
};

export default options;
