const settings = {
  noHeaderRoutes: [
    '/login',
    '/logout',
    '/register',
    '/password-reset',
    '/account/password-change',
    '/verify',
    '/api',
    '/news',
    '/about',
    '/contact',
    '/governance',
    '/admin/news/blog',
    '/indices',
    '/files',
    '/swagger',
    '/api/private',
    '/embedded',
    '/protected',
    '/events',
    '/account/new-index',
    '/governance/announcements/', // files for announcements page
    '/governance/consultation/', // files for consultation page
    '/governance/policies/', // files for policies page
    '/news/press/', // files for press releases page
    '/support/documents/', // files for support documents page
    '/news/blog/file/',
  ],

  noFooterRoutes: [
    '/login',
    '/logout',
    '/register',
    '/password-reset',
    '/account/password-change',
    '/verify',
    '/api',
    '/files',
    '/swagger',
    '/api/private',
    '/embedded',
    '/protected',
    '/events',
    '/account/new-index',
    '/governance/announcements/', // files for announcements page
    '/governance/consultation/', // files for consultation page
    '/governance/policies/', // files for policies page
    '/news/press/', // files for press releases page
    '/support/documents/', // files for support documents page
    '/news/blog/file/',
  ],

  noNavBarRoutes: [
    '/login',
    '/register',
    '/password-reset',
    '/account/password-change',
    '/verify',
    '/api',
    '/files',
    '/swagger',
    '/api/private',
    '/embedded',
    '/protected',
    '/events',
    '/governance/announcements/', // files for announcements page
    '/governance/consultation/', // files for consultation page
    '/governance/policies/', // files for policies page
    '/news/press/', // files for press releases page
    '/support/documents/', // files for support documents page
    '/news/blog/file/',
  ],
};

export default settings;

export const hasHeader = (pathname: string) =>
  pathname !== '/' &&
  settings.noHeaderRoutes.reduce(
    (isEmpty, route) => isEmpty && !pathname.startsWith(route),
    true
  ) &&
  !pathname.match(/^\/(\w[\w_-]*)*api([\w_-]*)\//);

export const hasFooter = (pathname: string) =>
  settings.noFooterRoutes.reduce((isEmpty, route) => isEmpty && !pathname.startsWith(route), true);

export const hasNavBar = (pathname: string) =>
  settings.noNavBarRoutes.reduce((isEmpty, route) => isEmpty && !pathname.startsWith(route), true);

export const isHome = (pathname: string) => pathname === '/';

export const isIndex = (pathname: string) => pathname.match(/^\/indices\/[A-Za-z0-9]+/);

export const isCustomThemePage = (pathname: string, isCustomIndexPage?: boolean) =>
  location.pathname.startsWith('/account') ||
  location.pathname.startsWith('/indices/') ||
  isCustomIndexPage;
