import { StateCreator } from 'zustand';
import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { UserPermissions, OauthPermissions, permissionsStore } from './store';
import { NotificationSlice, batchNotifications } from 'store/Notification/slice';
import humps from 'humps';
const API_URL = getAPIUrl();

export interface PermissionSlice {
  permissions: UserPermissions;
  getPermissions: (email: string) => void;
  getUserPermissions: (email: string) => Promise<OauthPermissions | undefined>;
  saveUserPermissions: (permissions: OauthPermissions) => Promise<any>;
}
const createPermissionSlice: StateCreator<
  PermissionSlice & NotificationSlice,
  [],
  [],
  PermissionSlice
> = (set, get) => ({
  permissions: permissionsStore,

  getPermissions: async email => {
    const permissions = get().permissions;
    if (permissions.permissions.version) {
      return { permissions };
    }
    const headers = await getHeaders('GET');
    const { content, errors } = await responseHandler<{ results: OauthPermissions[] }>(
      fetch(`${API_URL}/oauth_credential?name=${encodeURIComponent(email)}`, headers),
      err => `Couldn't load permissions for ${email}. (${err})`
    );

    const results = (content && content.results) || [];
    if (results.length) {
      const { merqubeWebsite, ...permissions } = results[0].permissions;
      const website = (merqubeWebsite?.pages || []).reduce((acc, page) => {
        return {
          ...acc,
          [humps.camelize(page.name)]: page,
        };
      }, {} as UserPermissions['permissions']['website']);
      set({
        ...(errors.length ? batchNotifications(get)(errors) : {}),
        permissions: {
          ...results[0],
          permissions: {
            ...permissions,
            website,
          },
        },
      });
    } else {
      set({
        ...(errors.length ? batchNotifications(get)(errors) : {}),
      });
    }
  },

  getUserPermissions: async email => {
    const headers = await getHeaders('GET');
    const { content, errors } = await responseHandler<{ results: OauthPermissions[] }>(
      fetch(`${API_URL}/oauth_credential?name=${encodeURIComponent(email)}`, headers),
      err => `Couldn't load permissions for ${email}. (${err})`
    );

    const results = (content && content.results) || [];
    if (results.length) {
      const { merqubeWebsite, ...permissions } = results[0].permissions;

      return {
        ...results[0],
        permissions: {
          ...permissions,
          merqubeWebsite: {
            pages: (merqubeWebsite?.pages || []).map(page => ({
              ...page,
              name: humps.camelize(page.name),
            })) as any,
          },
        },
      };
    } else if (errors?.length) {
      set({
        ...(errors.length ? batchNotifications(get)(errors) : {}),
      });
    }
  },

  saveUserPermissions: async permissions => {
    if (permissions.website?.theme) {
      console.log('deleting website');
      permissions.website.theme = {} as any;
    }
    const { id, ...rest } = permissions;
    const method = id ? 'PATCH' : 'POST';
    const body = JSON.stringify(humps.decamelizeKeys(id ? permissions : rest));

    const url = id ? `${API_URL}/oauth_credential/${id}` : `${API_URL}/oauth_credential`;
    const headers = await getHeaders(method);
    const { content, errors } = await responseHandler<{ results: any }>(
      fetch(url, { ...headers, body }),
      err => `Couldn't save permissions for ${permissions.name}. (${err})`
    );
    if (errors?.length) {
      set({
        ...(errors.length ? batchNotifications(get)(errors) : {}),
      });
    }
    return { content, errors };
  },
});

export { createPermissionSlice };
export default createPermissionSlice;
