import { UUID } from 'store/types';
// import { VITE_EXTERNAL_BASE_URL } from "@/utils/constants"
import { getHeaders, responseHandler } from 'store/utils';
import { StateCreator } from 'zustand';

const API_URL = import.meta.env.VITE_API_URL;
// const API_URL = VITE_EXTERNAL_BASE_URL

export interface CalendarTypes {
  FUT: string[];
  MIC: string[];
  // FX: string[] // throws error
  SM: string[];
  // MQI: string[] // MerQube Index Names
  OVERLAY: string[];
}

export interface CalendarTypeSlice {
  items: CalendarTypes;
  loaded: boolean;
  get: () => Promise<CalendarTypes>;
}

export const createCalendarTypeSlice: StateCreator<CalendarTypeSlice, [], [], CalendarTypeSlice> = (
  set,
  get
) => ({
  items: {
    FUT: [],
    MIC: [],
    SM: [],
    // MQI: [], // MQI will be filled with the index list
    OVERLAY: [],
  },
  loaded: false,
  get: async () => {
    const { items, loaded } = get();
    if (loaded) {
      return items;
    }

    let FUT: string[] = [];
    let MIC: string[] = [];
    let SM: string[] = [];
    // let MQI: string[] = []
    let OVERLAY: string[] = [];

    try {
      // fill MIC and SM
      const headers = await getHeaders('GET');
      const micResponse = await responseHandler<{
        results: SecurityItem[];
      }>(
        fetch(`${API_URL}/security/exchange`, headers),
        err => `Couldn't load Security Exchange. (${err})`
      );

      if (micResponse.errors && micResponse.errors.length) {
        console.error(micResponse.errors);
      }
      MIC = micResponse.content.results
        .filter(item => !item.name.startsWith('swaps_monitor'))
        .map(item => item.name);
      SM = micResponse.content.results
        .filter(item => item.name.startsWith('swaps_monitor'))
        .map(item => item.name.replace('swaps_monitor_', ''));

      const futResponse = await responseHandler<{
        results: SecurityItem[];
      }>(
        fetch(`${API_URL}/security/futures_root`, headers),
        err => `Couldn't load Security Futures Root. (${err})`
      );
      if (futResponse.errors && futResponse.errors.length) {
        console.error(futResponse.errors);
      }
      FUT = futResponse.content.results.map(item => item.name);

      const overlayResponse = await responseHandler<{
        results: SecurityItem[];
      }>(
        fetch(`${API_URL}/security/calendar_overlay`, headers),
        err => `Couldn't load Security Overlay. (${err})`
      );
      if (overlayResponse.errors && overlayResponse.errors.length) {
        console.error(overlayResponse.errors);
      }
      OVERLAY = overlayResponse.content.results.map(item => item.name);

      set({
        items: {
          FUT,
          MIC,
          SM,
          OVERLAY,
        },
        loaded: true,
      });

      return {
        FUT,
        MIC,
        SM,
        OVERLAY,
      };
    } catch (e) {
      console.error(e);
    }
    return items;
  },
});

export default createCalendarTypeSlice;

interface SecurityItem {
  eff_ts: string;
  id: UUID;
  name: string;
}
