import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { MetricsStore, MetricType, metricsStore } from './store';
import { StateCreator } from 'zustand';
import { NotificationSlice, batchNotifications } from 'store/Notification/slice';
const API_URL = getAPIUrl();

export interface MetricsSlice {
  metrics: MetricsStore;
  getMetrics: (type: MetricType, id: string) => void;
  getMetricsList: (list: Array<{ type: MetricType; id: string }>) => void;
  setSelectedMetrics: (selected: { [id: string]: string }) => void;
}

export const createMetricsSlice: StateCreator<
  MetricsSlice & NotificationSlice,
  [],
  [],
  MetricsSlice
> = (set, get) => ({
  metrics: metricsStore,
  getMetrics: async (type, id) => {
    const metrics = get().metrics;
    if (metrics[type][id]) {
      return { metrics };
    }
    const headers = await getHeaders('GET');
    const { content, errors } = await responseHandler<any>(
      fetch(
        `${API_URL}/security/${type === 'equity' ? 'equity_legacy' : type}/${id}/metrics`,
        headers
      ),
      err => `Couldn't load Indices list. (${err})`
    );

    set({
      ...(errors.length ? batchNotifications(get)(errors) : {}),
      metrics: {
        ...metrics,
        [type]: {
          ...metrics[type],
          [id]: content.results || [],
        },
      },
    });
  },
  getMetricsList: async (unFilteredList: Array<{ type: MetricType; id: string }>) => {
    const metrics = get().metrics;
    const list = unFilteredList.filter(m => !metrics[m.type][m.id]);

    if (!list.length) {
      return { metrics };
    }
    const headers = await getHeaders('GET');
    const promises = list.map(({ type, id }) =>
      responseHandler<any>(
        fetch(
          `${API_URL}/security/${type === 'equity' ? 'equity_legacy' : type}/${id}/metrics`,
          headers
        ),
        err => `Couldn't load Metric list. (${err})`
      )
    );

    const responses: any = await Promise.all(promises);

    const contents = responses.reduce(
      (acc: any, response: any, i: number) => {
        const { errors, content } = response;
        const results = (content || {}).results || [];
        return {
          metrics: {
            ...metrics,
            index: {
              ...acc.metrics.index,
              ...(list[i].type === 'index' ? { [list[i].id]: results } : {}),
            },
            equity: {
              ...acc.metrics.equity,
              ...(list[i].type !== 'index' ? { [list[i].id]: results } : {}),
            },
          },
          errors: [...acc.errors, ...errors],
        };
      },
      {
        errors: [],
        metrics,
      }
    );

    set({
      ...(contents.errors.length ? batchNotifications(get)(contents.errors) : {}),
      metrics: contents.metrics,
    });
  },

  setSelectedMetrics: (selected: { [id: string]: string }) => {
    const metrics = get().metrics;
    set({
      metrics: {
        ...metrics,
        selected: {
          ...metrics.selected,
          ...selected,
        },
      },
    });
  },
});

export default createMetricsSlice;
