// hardcoded settings for the UBCSRULB index
import { IndexItem } from 'store/Index/store';
import i18next from '../../i18n';

const indices = ['UBCSRULB'];
const hardCodedFrenchIndices = ['FR001400MVY0', 'FR001400Q2T1'];
const pathName = window.location.pathname;
const path = pathName.split('/').pop();

if (path && hardCodedFrenchIndices.includes(path)) {
  i18next.changeLanguage('fr');
} else {
  i18next.changeLanguage('en');
}
const language = i18next.language;
export const match = (name: string) => indices.includes(name);

export const getSettings = (name: string): Partial<Settings> => {
  switch (name) {
    case 'UBCSRULB':
      return ubsSettings;
    case 'FR001400MVY0':
    case 'FR001400Q2T1':
      return isinSettings;
    case 'MQDZB14P':
      return MQDZB14PSettings;
    default:
      return {};
  }
};

export default getSettings;

interface FieldSettings {
  hide?: boolean;
  label?: string;
}

interface IndexLevelSettings extends FieldSettings {
  hideChart?: boolean;
  hideMainSeries?: boolean; // hide the main series in the chart
  disabled?: boolean; // disable the chart comparator
  limitRange?: boolean; // limit the range of the chart to the index's launch date
  minDate?: string;
  maxDateRange?: number; // max date range in years
}

interface StatsSettings extends FieldSettings {
  hideCumulativeReturn?: boolean;
  hideAnnualizedReturn?: boolean;
  hideAnnualVolatility?: boolean;
  hideRiskAdjustedReturn?: boolean;
  hideSharpeRatio?: boolean;
  hideMaxDrawdown?: boolean;
}

interface DocumentsSettings extends FieldSettings {
  hideFactsheet?: boolean;
}

type ManifestSettings = Record<keyof IndexItem, FieldSettings>;

interface Settings extends ManifestSettings {
  indexLevels: IndexLevelSettings;
  stats: StatsSettings;
  documents: DocumentsSettings;
}

const ubsSettings: Partial<Settings> = {
  title: {
    hide: true,
    label: 'UBCSRULB',
  },
  description: {
    hide: true,
  },
  indexLevels: {
    hideChart: true,
  },
  rebalFreq: {
    hide: true,
  },
  documents: {
    hide: true,
  },
  stats: {
    hide: true,
  },
};

const isinSettings: Partial<Settings> = {
  documents: {
    hide: true,
  },
  description: {
    hide: true,
  },
  rebalFreq: {
    hide: true,
  },
  indexLevels: {
    label: language === 'en' ? 'Underlier Levels' : 'Niveau du panier',
  },
  stats: {
    hideAnnualizedReturn: true,
    hideAnnualVolatility: true,
    hideRiskAdjustedReturn: true,
    hideSharpeRatio: true,
    hideMaxDrawdown: true,
  },
};

const MQDZB14PSettings: Partial<Settings> = {
  indexLevels: {
    maxDateRange: 3,
  },
};
