import { UUID } from 'store/types';
import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import {
  IdentifierStore,
  Identifier,
  identifierStore,
  IdentifierTemplate,
  IdentifierType,
  StatusBlock,
} from './store';
import { StateCreator } from 'zustand';

const API_URL = getAPIUrl();

interface IdentifierQuery {
  provider: string;
  name: string;
  ticker?: string;
}

interface Result {
  identifier: IdentifierStore;
  errors: ResposeError[];
}

interface ResposeError {
  type: string;
  message: string;
  code?: number;
}

export interface IdentifierSlice {
  items: IdentifierStore;
  getList: (items: IdentifierQuery[]) => Promise<any>;
  create: (
    template: IdentifierTemplate,
    provider: IdentifierType
  ) => Promise<Identifier | { errors: ResposeError[] }>;
  delete: (provider: IdentifierType, id: UUID) => Promise<any>;
}

export const createIdentifierSlice: StateCreator<IdentifierSlice, [], [], IdentifierSlice> = (
  set,
  get
) => ({
  items: identifierStore,
  getList: async items => {
    const identifier = get().items;
    const queryItems = items.filter(
      i => !(identifier[i.provider] && identifier[i.provider][i.name])
    );

    let results: Result;
    try {
      const headers = await getHeaders('GET');

      const promises = queryItems.map(q =>
        responseHandler<any>(
          fetch(
            `${API_URL}/identifier/${q.provider.toLowerCase()}?name=${q.name || q.ticker}`,
            headers
          ),
          err => `Couldn't load Identifier. (${err})`,
          true
        )
      );
      const responses = await Promise.all(promises);

      results = responses.reduce<Result>(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (res, { content, errors }, idx) => {
          const { provider, name } = queryItems[idx];
          const item: Identifier = content.results[0];

          return {
            errors: [...res.errors, ...(errors || [])],
            identifier: {
              ...res.identifier,
              [provider]: {
                ...(res.identifier[provider] || {}),
                [name]: item,
              },
            },
          };
        },
        {
          identifier,
          errors: [],
        }
      );

      if (results?.errors?.length) {
        // TODO: handle errors
        console.error('Identifier load errors', results.errors);
      }
    } catch (e) {
      console.error('Identifier load errors', e);

      return {};
    }

    set({
      // ...(results.errors.length ? batchNotifications(get)(results.errors) : {}),
      items: results.identifier,
    });
  },

  create: async (template: IdentifierTemplate, provider: IdentifierType) => {
    const manifest = {
      display_name: DisplayName[provider],
      index_name: template.index_name,
      metric: template.metric,
      name: template.name,
      namespace: template.namespace,
      rounding_decimals: template.rounding_decimals,
      ticker: template.name,
    };

    try {
      const headers = getHeaders('POST');
      const response = await responseHandler<IdentifierCreateResponse>(
        fetch(`${API_URL}/identifier/${provider.toLowerCase()}`, {
          ...headers,
          body: JSON.stringify(manifest),
        }),
        err => `Couldn't create Identifier. (${err})`,
        true
      );

      if (response.errors && response.errors.length) {
        return { errors: response.errors };
      }

      if (response.content.id) {
        const identifier: Identifier = {
          ...response.content,
          ...manifest,
        };
        const items = get().items;
        set({
          items: {
            ...items,
            [provider]: {
              ...items[provider],
              [identifier.index_name]: identifier,
            },
          },
        });

        return identifier;
      }

      // errors: [],
    } catch (e) {
      console.log('identifier create error', e);
      return {
        errors: [{ type: 'error', message: 'There was an error creating the Identifier' }],
      };
    }
    return { errors: [] };
  },

  delete: async (provider: IdentifierType, id: UUID) => {
    try {
      const headers = await getHeaders('DELETE');
      await responseHandler(
        fetch(`${API_URL}/identifier/${provider.toLowerCase()}/${id}`, headers),
        err => `Couldn't delete Identifier. (${err})`,
        true
      );

      const items = get().items;
      const providerItems = items[provider];
      delete providerItems[id];
      set({
        items: {
          ...items,
          [provider]: providerItems,
        },
      });
    } catch (e) {
      console.log('identifier delete error', e);
    }
  },
});

export default createIdentifierSlice;

enum DisplayName {
  BLOOMBERG = 'Bloomberg Ticker',
  REFINITIV = 'Reuters RIC',
  MORNINGSTAR = 'Morningstar Code',
  FACTSET = 'Factset Ticker',
}

interface IdentifierCreateResponse {
  id: UUID;
  status: StatusBlock;
}
