import { StateCreator } from 'zustand';
import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import dayjs, { Dayjs } from 'dayjs';
import { UUID } from '../types';

export interface IndexRunStatus {
  status: 'PENDING_CREATION' | 'RUNNING' | 'SUCCEEDED' | 'FAILED';
  calculationStartTs: string;
  index_run_start_ts: string;
  calculation_eff_start_ts: string;
  calculation_eff_end_ts: string;
}
export type ApiState = 'INIT' | 'LOADING' | 'DONE' | 'ERROR';

const API_URL = getAPIUrl();

export interface IndexRunStatusSlice {
  value: {
    [indexId: UUID]: IndexRunStatus;
  };
  lastUpdated: Dayjs | null;
  state: ApiState;
  // gets the status of an index run
  get: (indexId: UUID) => Promise<IndexRunStatus>;
  // gets the status of multiple index runs
  getList: (indexIds: UUID[]) => Promise<{ [indexId: UUID]: IndexRunStatus }>;
  // subscribes to the status of an index run list (makes raequests given an interval)
  subscribe: (
    indexIds: UUID[],
    interval?: number
  ) => {
    unSubscribe: () => void;
  };
}
const createIndexRunStatusSlice: StateCreator<IndexRunStatusSlice, [], [], IndexRunStatusSlice> = (
  set,
  get
) => ({
  value: {},
  lastUpdated: null,
  state: 'INIT',
  get: async index => {
    const now = dayjs();
    const { value, lastUpdated, state } = get();

    if (
      state === 'LOADING' ||
      (value[index] &&
        value[index].status !== 'PENDING_CREATION' &&
        value[index].status !== 'RUNNING')
    ) {
      return value[index];
    }

    if (value[index] && lastUpdated && lastUpdated.isAfter(now.subtract(5, 'seconds'))) {
      return value[index];
    }
    const headers = await getHeaders('GET');
    const result = await responseHandler<IndexRunStatus>(
      fetch(`${API_URL}/index/${index}/run_state`, headers),
      err => `Couldn't load Index status. (${err})`
    );

    if (result.errors) {
      set({
        state: 'ERROR',
        value,
        lastUpdated: now,
      });

      return value[index];
    }

    set({
      value: { ...value, [index]: result.content },
      lastUpdated: now,
      state: 'DONE',
    });

    return result.content;
  },
  getList: async indices => {
    const now = dayjs();
    const { value, state } = get();

    if (state === 'LOADING') {
      return value;
    }

    const queryIds = indices.filter(index => {
      const indexStatus = value[index]?.status;
      return indexStatus !== 'SUCCEEDED' && indexStatus !== 'FAILED';
    });

    if (queryIds.length) {
      set({ state: 'LOADING' });
    }
    const headers = await getHeaders('GET');
    const promises = queryIds.map(index =>
      responseHandler<IndexRunStatus>(
        fetch(`${API_URL}/index/${index}/run_state`, headers),
        err => `Couldn't load Indices list. (${err})`
      )
    );

    const results = await Promise.all(promises);
    const runStatus = results.reduce(
      (status, r, i) => ({ ...status, [queryIds[i]]: r.content }),
      value
    );

    set({
      value: runStatus,
      lastUpdated: now,
      state: 'DONE',
    });

    return runStatus;
  },
  subscribe: (indices, interval = 5000) => {
    const { getList } = get();
    getList(indices);
    const intervalId = setInterval(() => getList(indices), interval);
    return {
      unSubscribe: () => clearInterval(intervalId),
    };
  },
});

export { createIndexRunStatusSlice };
export default createIndexRunStatusSlice;
