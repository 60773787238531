export interface SecItem {
  effTs: string;
  id: string;
  name: string;
  [metricName: string]: any;
}

export interface SecAPIResult {
  results: SecItem[];
}

export interface PriceHistoryMetric {
  [secId: string]: PriceHistoryItem;
}
export interface PriceHistoryItem {
  [metricName: string]: number[][];
}

type IndexorEquityId = string;
export interface Manifest {
  [secId: string]: IndexorEquityId;
}
export interface PriceHistory {
  manifest: Manifest;
  results: PriceHistoryMetric;
  status: 'init' | 'loading' | 'done' | 'error';
}

const priceHistoryStore: PriceHistory = {
  manifest: {},
  results: {},
  status: 'init',
};

export { priceHistoryStore };
