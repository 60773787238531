import { getAPIUrl, getHeaders, responseHandler } from '../utils';
import { StateCreator } from 'zustand';
const API_URL = getAPIUrl();
import defaultValues from './defaults';

export type Periodicity =
  | 'WEEKLY_NON_FRIDAY'
  | 'WEEKLY_FRIDAY'
  | 'WEEKLY'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'ANNUALLY';
export enum Month {
  JANUARY = 1,
  FEBRUARY = 2,
  MARCH = 3,
  APRIL = 4,
  MAY = 5,
  JUNE = 6,
  JULY = 7,
  AUGUST = 8,
  SEPTEMBER = 9,
  OCTOBER = 10,
  NOVEMBER = 11,
  DECEMBER = 12,
}

export interface OptionAvailabilityResponse {
  availableFromDate: string;
  countryCode: string;
  fsymId: string;
  holdingPeriod: string;
  periodicity: Periodicity;
  rebalanceMonth: Month | null;
  underlier: string;
}

export type OptionAvailabilityItem = {
  [periodicity in Periodicity]: Array<{
    min_base_date: string;
    max_holding_period: number;
    rebal_month: Month | null;
  }>;
};

export interface OptionAvailabilitySlice {
  items: {
    [fsym_id: string]: {
      [country_code: string]: OptionAvailabilityItem;
    };
  };
  get: (fsymId: string, countryCode: string) => Promise<OptionAvailabilityItem | null>;
  getList: (
    identifiers: { fsym_id: string; country_code: string }[]
  ) => Promise<OptionAvailabilityItem | null>;
  status: 'init' | 'loading' | 'done' | 'error';
}

export const createOptionAvailabilitySlice: StateCreator<
  OptionAvailabilitySlice,
  [],
  [],
  OptionAvailabilitySlice
> = (setState, getState) => ({
  items: defaultValues,
  status: 'init',
  // Get the distribution flow item by indexName
  get: async (fsymId, countryCode) => {
    const { items } = getState();

    if (items[fsymId] && items[fsymId][countryCode]) {
      return items[fsymId][countryCode];
    }

    setState({ status: 'loading' });

    try {
      const headers = await getHeaders('GET');
      const response = await responseHandler<{ results: OptionAvailabilityResponse[] }>(
        fetch(`${API_URL}/options/availability_dates/${fsymId}/${countryCode}`, headers),
        err => `Couldn't load SecurityExchange. (${err})`
      );

      const { content, errors } = response;
      if (errors.length) {
        setState({
          status: 'error',
        });
        return null;
        console.error('errors: ', errors);
      }
      const availability = parseOptions(content.results || []);

      setState({
        items: {
          ...items,
          [fsymId]: {
            ...items[fsymId],
            [countryCode]: availability,
          },
        },
        status: 'done',
      });

      return availability;
    } catch (e: any) {
      setState({
        status: 'error',
      });
    }
    return null;
  },

  getList: async (identifiers: { fsym_id: string; country_code: string }[]) => {
    const { items } = getState();
    const query = identifiers.filter(i => !items[i.fsym_id] || !items[i.fsym_id][i.country_code]);

    if (!query.length) {
      const rules = identifiers.map(i => items[i.fsym_id][i.country_code]);
      return combineRules(rules);
    }

    setState({ status: 'loading' });

    try {
      const headers = await getHeaders('GET');
      const promises = query.map(i =>
        responseHandler<{ results: OptionAvailabilityResponse[] }>(
          fetch(`${API_URL}/options/availability_dates/${i.fsym_id}/${i.country_code}`, headers),
          err => `Couldn't load SecurityExchange. (${err})`
        )
      );
      const responses = await Promise.all(promises);

      let newItems = {
        ...items,
      };

      for (let i = 0; i < query.length; i++) {
        const { content, errors } = responses[i];
        if (errors.length) {
          setState({
            status: 'error',
          });
          console.error('errors: ', errors);
          return null;
        }
        const availability = parseOptions(content.results || []);

        newItems = {
          ...newItems,
          [query[i].fsym_id]: {
            ...newItems[query[i].fsym_id],
            [query[i].country_code]: availability,
          },
        };
      }

      setState({
        items: newItems,
        status: 'done',
      });

      const rules = identifiers.map(i => newItems[i.fsym_id][i.country_code]);
      return combineRules(rules);
    } catch (e: any) {
      setState({
        status: 'error',
      });
    }
    return null;
  },
});

export default createOptionAvailabilitySlice;

const parseOptions = (response: OptionAvailabilityResponse[]): OptionAvailabilityItem => {
  const items: OptionAvailabilityItem = {
    WEEKLY: [],
    WEEKLY_NON_FRIDAY: [],
    WEEKLY_FRIDAY: [],
    MONTHLY: [],
    QUARTERLY: [],
    ANNUALLY: [],
  };

  response.forEach(item => {
    const period = items[item.periodicity];
    const maxHoldingPeriod = parseInt(item.holdingPeriod);
    const currentIndex = period.findIndex(
      p =>
        p.min_base_date === item.availableFromDate &&
        (!p.rebal_month || p.rebal_month === item.rebalanceMonth)
    );

    if (currentIndex > -1 && period[currentIndex].max_holding_period < maxHoldingPeriod) {
      // Update the max holding period if the new one is greater
      items[item.periodicity][currentIndex].max_holding_period = maxHoldingPeriod;
      items[item.periodicity][currentIndex].rebal_month = item.rebalanceMonth;
    } else if (currentIndex === -1) {
      // Add a new item if it doesn't exist
      items[item.periodicity].push({
        min_base_date: item.availableFromDate,
        max_holding_period: parseInt(item.holdingPeriod),
        rebal_month: item.rebalanceMonth,
      });
    }
  });

  return items;
};

const combineRules = (rules: OptionAvailabilityItem[]): OptionAvailabilityItem => {
  const [first, ...rest] = rules;
  const combined = rest.reduce((acc, rule) => {
    const periodicities = Object.keys(acc) as Periodicity[];

    return periodicities.reduce((acc2, periodicity) => {
      return {
        ...acc2,
        [periodicity]: getCombinedPeriodicity(acc[periodicity], rule[periodicity] || []),
      };
    }, {} as OptionAvailabilityItem);
  }, first);

  return combined;
};
interface PeriodItem {
  min_base_date: string;
  max_holding_period: number;
  rebal_month: Month | null;
}

const getCombinedPeriodicity = (period1: PeriodItem[], period2: PeriodItem[]): PeriodItem[] => {
  const swapPeriod1 = period1.sort((a, b) => a.max_holding_period - b.max_holding_period);
  const swapPeriod2 = period2.sort((a, b) => a.max_holding_period - b.max_holding_period);

  const response = [];

  for (let i = 0; i < swapPeriod1.length; i++) {
    const p1 = swapPeriod1[i];

    for (let j = 0; j < swapPeriod2.length; j++) {
      const p2 = swapPeriod2[j];

      if (p1.max_holding_period <= p2.max_holding_period) {
        const { max_holding_period, rebal_month } = p1;
        const min_base_date =
          p1.min_base_date > p2.min_base_date ? p1.min_base_date : p2.min_base_date;
        response.push({ min_base_date, max_holding_period, rebal_month });
        break;
      }
    }
  }

  for (let i = 0; i < swapPeriod2.length; i++) {
    const p2 = swapPeriod2[i];

    for (let j = 0; j < swapPeriod1.length; j++) {
      const p1 = swapPeriod1[j];

      if (p2.max_holding_period <= p1.max_holding_period) {
        const { max_holding_period, rebal_month } = p2;
        const min_base_date =
          p2.min_base_date > p1.min_base_date ? p2.min_base_date : p1.min_base_date;

        const index = response.findIndex(i => i.min_base_date === min_base_date);
        if (index > -1) {
          response[index].max_holding_period = Math.min(
            max_holding_period,
            response[index].max_holding_period
          );
          response[index].rebal_month = rebal_month;
        } else {
          response.push({ min_base_date, max_holding_period, rebal_month });
        }
        break;
      }
    }
  }

  return response;
};
